import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const initialState = {
    notifications: [],
    status: 'idle',
    error: null
}

export const fetchNotifications = createAsyncThunk('notifications/fetchNotifications', async () => {
    var url = `${process.env.REACT_APP_API_URL}/user_notifications/`;
    var response;
    try {
        response = await axios(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.access_token}`,
            },
        })
        return response.data;
    } catch (err) {
        return err;
    }
})

export const deleteNotification = createAsyncThunk('notifications/deleteNotification', async (id) => {
    var url = `${process.env.REACT_APP_API_URL}/user_notifications/${id}`;
    var response;
    try {
        response = await axios(url, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.access_token}`,
            },
        })
        return response.data;
    } catch (err) {
        return err;
    }
})

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
    },
    extraReducers(builder) {
        builder
            .addCase(fetchNotifications.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchNotifications.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched notifications to the array
                state.notifications = state.notifications.concat(action.payload)
            })
            .addCase(fetchNotifications.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(deleteNotification.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(deleteNotification.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched notifications to the array
                state.notifications = state.notifications.concat(action.payload)
            })
            .addCase(deleteNotification.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    },
})

export default notificationsSlice.reducer

export const selectAllNotifications = state => state.notifications.notifications