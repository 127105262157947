import React from 'react';
import '../css/Reporting.css';
import ReportingPivot from '../components/ReportingPivot';

export default function Reporting() {

  return (
    <>
      <h1>Reporting</h1>
        <div style={{ padding: "20px" }}>
          <ReportingPivot />
        </div>
    </>
  );
}