import HomeIcon from '@mui/icons-material/Home';
import AccountTreeIcon from '@mui/icons-material/AccountCircle';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssignmentIcon from '@mui/icons-material/Assignment';

export const sideNavBarData = [
    {
        id: 0,
        icon: <HomeIcon />,
        text: "Home",
        link: "/"
    },
    {
        id: 0,
        icon: <AssignmentIcon />,
        text: "Notifications",
        link: "/notifications/all"
    },
    {
        id: 0,
        icon: <AccountTreeIcon />,
        text: "Tasks",
        link: "/tasks"
    },
    {
        id: 1,
        icon: <AccountBalanceIcon />,
        text: "Governance",
        link: "/governance"
    },
    {
        id: 2,
        icon: <FlashOnIcon />,
        text: "Risk",
        link: "/risk"
    },
    {
        id: 3,
        icon: <VerifiedUserIcon />,
        text: "Compliance",
        link: "/compliance"
    },
    {
        id: 4,
        icon: <ShowChartIcon />,
        text: "Reporting",
        link: "/reporting"
    },
    {
        id: 5,
        icon: <SettingsIcon />,
        text: "System Administration",
        link: "/system-administration"
    },
    {
        id: 6,
        icon: <ManageAccountsIcon />,
        text: "Subscription",
        link: "/subscription"
    },
    {
        id: 7,
        icon: <AccessTimeIcon />,
        text: "Project Studio",
        link: "/project"
    },
]