import React, { useEffect, useState, useCallback } from "react";
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import SpinnerComponent from '../LoadingSpinner';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import CreateEditProjectControl from "../CreateEditProjectControl";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MultipleControlSelect from "../MultipleControlSelect";

//This class displays lists of project controls

export async function deleteData(projectId, controlid) {
    var url = `${process.env.REACT_APP_API_URL}/projects/${projectId}/project_control/${controlid}`;
    const response = axios(url, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export async function loadData (projectId) {
    //call data endpoint for data type to set rows and columns
    var url = `${process.env.REACT_APP_API_URL}/projects/project_controls/${projectId}?offset=0&limit=1500`;
    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

const columns = [
    { field: 'id', headerName: 'id', width: 120 },
];

const rows = [
    { id: "n/a" },
];

export default function ProjectControlList({
    projectId,
    projectControlData,
}) {

    const [isSystemAdmin, setIsSystemAdmin] = useState(null);

    //axios error message
    const [errMsg, setErrMsg] = useState("");

    const [rowsData, setRowsData] = useState();
    const [colsData, setColsData] = useState();

    const [editObj, setEditObj] = useState(null);

    const [loading, setLoading] = useState(false);

    const [openAddDialog, setOpenAddDialog] = useState(null);

    const [selected, setSelected] = useState([]);

    const [openDialog, setOpenDialog] = useState(null);
    const [openCreateProjectControlDialog, setOpenCreateProjectControlDialog] = useState(null);

    const [projectControlListData, setProjectControlListData] = useState(null)

    useEffect(() => {
        if (localStorage.getItem("system_role") === "4" ||
            localStorage.getItem("system_role") === "1" ||
            localStorage.getItem("system_role") === "2"
        ) {
            setIsSystemAdmin(true)
        } else {
            setIsSystemAdmin(false)
        }

    }, [setIsSystemAdmin])

    const onRowsSelectionHandler = (ids) => {
        setSelected([...ids]);
    };

    const handleClose = () => {
        setOpenAddDialog(false);
        setOpenDialog(false);
        setOpenCreateProjectControlDialog(false);
        setEditObj(null);
    }

    const isOpen = () => {
        //setEditObj(null);
        handleClose();
    }

    const addControlData = (data) => {
        /*var arrcopy = rowsData.filter(row => row.id !== data.id)
        var tmparray = [...arrcopy, data];
        setRowsData(tmparray);*/
        getData();
    }

    const refreshRows = async (rows) => {
        setRowsData(rows)
    }

    async function deleteSelectedRow() {
        if (editObj) {
            const response = await deleteData(projectId, editObj.id);
            if (response?.status !== 200) {
                console.log(response)
                setErrMsg(`There are risks or audit tests still referencing this control. So it cannot be deleted. `);
            }
            if (response?.status === 200) {
                getData()
            }
        }
        if (selected.length !== 0) {
            for (let x = 0; x < selected.length; x++) {
                const response = await deleteData(projectId, selected[x]);
                if (response?.status !== 200) {
                    setErrMsg(`There are risks or audit tests still referencing this control. So it cannot be deleted. `);
                }
                if (response?.status === 200) {
                    getData()
                }
            }
        }
    };

    function cutString(s, n) {
        var cut = s.indexOf(' ', n);
        if (cut === -1) return s;
        return s.substring(0, cut)
    }

    const getData = useCallback(async () => {
        setLoading(true)
        var response;
        if (!projectControlData?.data) {
            response = await loadData(projectId);
        } else {
            response = projectControlData
        }
        
        setProjectControlListData(response)
        if (response) {
            //console.log(response)

            var filteredRowDataPrControls = [];
            response?.data?.items?.forEach((key) => {
                var control_family = "N/A";
                var control_status = "N/A";
                var control_phase = "N/A";
                var control_class = "N/A"
                if (key.control_family) {
                    control_family = key.control_family.name
                }
                if (key.control_status) {
                    control_status = key.control_status.name
                }
                if (key.control_phase) {
                    control_phase = key.control_phase.name
                }
                if (key.control_class) {
                    control_class = key.control_class.name
                }
                filteredRowDataPrControls.push({
                    id: key.id,
                    control: key.control,
                    name: key.control.name,
                    description: cutString(key.control.description, 80) + '...',
                    mitigation_percentage: key.mitigation_percentage,
                    exception: key.exception,
                    action: key.control.id,
                    control_family: control_family,
                    control_phase: control_phase,
                    control_status: control_status,
                    control_class: control_class,
                    control_class_id: key.control_class_id,
                    control_family_id: key.control_family_id,
                    control_phase_id: key.control_phase_id,
                    control_status_id: key.control_status_id,
                });
            })

            setRowsData([...filteredRowDataPrControls]);
            var colsValsPrC = [
                {
                    field: 'name',
                    headerName: 'Name',
                    width: 200,
                    renderCell: (params) => (
                        <Link href={`/projects/${projectId}/controls/${params.row.id}`}>{params.value}</Link>
                    )
                },
                {
                    field: 'description',
                    headerName: 'Description',
                    width: 200,
                    cellClass: "normalLineHeight"
                },
                {
                    field: 'mitigation_percentage',
                    headerName: 'Mitigation',
                    width: 100,
                    cellClass: "normalLineHeight"
                },
                {
                    field: 'control_status',
                    headerName: 'Status',
                    width: 100,
                    cellClass: "normalLineHeight"
                },
                {
                    field: 'control_phase',
                    headerName: 'Phase',
                    width: 100,
                    cellClass: "normalLineHeight"
                },
                {
                    field: 'control_family',
                    headerName: 'Family',
                    width: 100,
                    cellClass: "normalLineHeight"
                },
                {
                    field: 'control_class',
                    headerName: 'Class',
                    width: 100,
                    cellClass: "normalLineHeight"
                },
                {
                    field: 'exception',
                    headerName: 'Exception',
                    width: 100,
                    renderCell: (params) => {
                        return params.value === null ? (
                            <FormGroup>
                                <FormControlLabel disabled control={<Checkbox />} />
                            </FormGroup>
                        ) : (
                            <FormGroup>
                                <FormControlLabel disabled control={<Checkbox defaultChecked />} />
                            </FormGroup>
                        );
                    }
                },
                {
                    field: 'action',
                    headerName: 'Action',
                    width: 200,
                    renderCell: (params) => (
                        <>
                            <Button startIcon={<EditIcon />} onClick={() => { setEditObj(params.row); setOpenAddDialog(true) }} />
                            <Button startIcon={<DeleteIcon />} onClick={() => { setEditObj(params.row); setOpenDialog(true) }} />
                        </>
                    )

                },
            ];
            setColsData([...colsValsPrC]);
        }
        setLoading(false)
    }, [projectId, projectControlData])

    useEffect(() => {
        if (!projectControlListData) {
            getData();
        }

    }, [projectControlListData, getData, projectId]);

    useEffect(() => {

    }, [projectControlData])

    useEffect(() => {
        console.log('projectControlListData', projectControlListData)
    }, [projectControlListData])

    return (
        <div style={{ marginTop: '0px' }}>
            <>
                {errMsg ? (
                    <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
                        <Alert severity="error">{errMsg}</Alert>
                    </Stack>
                ) : (
                    <></>
                )}
                {isSystemAdmin &&
                    <div className="btns-top-list">
                        <Button
                            variant="contained"
                            className="secondary btn"
                            startIcon={<DeleteIcon />}
                            disabled={!selected.length}
                            onClick={() =>
                                selected.length ? setOpenDialog("delete") : null
                            }
                        >
                            Delete
                        </Button>
                        <Button
                            variant="outlined"
                            startIcon={<AddCircleIcon />}
                            onClick={() => { setOpenCreateProjectControlDialog(true); }}
                            className="addBtn"
                        >Add</Button>
                    </div>
                }

                <div style={{ height: '600px', width: '100%' }} className="project-list">
                    <DataGrid
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        rows={rowsData ? rowsData : rows}
                        columns={colsData ? colsData : columns}
                        rowsPerPageOptions={[10]}
                        pageSize={10}
                        checkboxSelection
                        loading={loading}
                        components={{
                            LoadingOverlay: SpinnerComponent,
                        }}
                        onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                        getRowHeight={() => 'auto'}
                    />
                </div>
                <Dialog
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "100%",
                                maxWidth: "800px",  // Set your width here
                            },
                        },
                    }}
                    open={openCreateProjectControlDialog}
                    onClose={handleClose}
                    aria-labelledby="create-data"
                    aria-describedby="alert-dialog-create-data"
                >
                    <DialogActions className="create-edit-dialog">
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                    <DialogTitle id="alert-dialog-title">
                        {editObj ? `Edit Project Control` : `Add Project Controls to Project`}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <MultipleControlSelect isOpen={isOpen} projectId={projectId} rowsReturnData={refreshRows} />
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    //fullScreen
                    open={openAddDialog}
                    onClose={handleClose}
                    aria-labelledby="add-project-data"
                    aria-describedby="alert-dialog-add-project-data"
                >
                    <DialogActions className="create-edit-dialog">
                        <Button onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                    <DialogTitle id="alert-dialog-title">
                        {editObj ? `Edit Project Control` : `Add Project Control`}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <CreateEditProjectControl isOpen={isOpen} editDataObj={editObj} controlData={addControlData} projectId={projectId} />
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={openDialog}
                    onClose={handleClose}
                    aria-labelledby="delete-confirm"
                    aria-describedby="alert-dialog-delete-confirm"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Delete?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this? This will also delete any associated risks, audit tests, tasks, evaluations, assessments and documents.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="create-edit-dialog">
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={() => { handleClose(); deleteSelectedRow(); }} autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        </div>
    )

}