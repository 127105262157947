import React, { useEffect, useContext, useRef } from 'react';
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from "@mui/icons-material";
import { sideNavBarData } from "../lib/SideNavBarData";
import Link from '@mui/material/Link';
import styles from '../css/sidenav.module.css'
import { MenuContext } from '../modules/MenuContext';

export default function SideNavBar() {
    const context = useContext(MenuContext);
    const toggleHamburger = useRef();

    useEffect(() => {
        if (localStorage.getItem("menu_open") === "true") {
            context.setMenuIsOpen(() => true);
        }
        if (localStorage.getItem("menu_open") === "false") {
            context.setMenuIsOpen(() => false);
        }
    }, [context])


    // when a click occurs on a button or link that should close the menu, close it
    function handleClickSideEffect() {
        if (context.menuIsOpen) {
            toggleHamburger.current.click();
            context.setMenuIsOpen(() => false);
            localStorage.setItem("menu_open", false)
        }
        if (!context.menuIsOpen) {
            toggleHamburger.current.click();
            context.setMenuIsOpen(() => true);
            localStorage.setItem("menu_open", true)
        }
    }

    return (
        <>
            {context?.menuIsOpen === true &&
                <div className={styles.sidenav}>
                    <button
                        aria-label="toggle"
                        name={"toggle"}
                        className={styles.menuBtn}
                        onClick={handleClickSideEffect}
                        id="toggle-hamburger"
                        ref={toggleHamburger}>
                        <KeyboardDoubleArrowLeft />
                    </button>
                    {sideNavBarData.map(item => {
                        return <div className={`${item.id} sideitem`}>
                            <Link
                                key={item.id}
                                href={item.link}
                                className={styles.sideitem}>
                                {item.icon}
                                <span className={styles.linkText}>{item.text}</span>
                            </Link>
                        </div>
                    })}
                </div>
            }
            {context?.menuIsOpen === false &&
                <div className={styles.sidenavClosed}>
                    <button
                        aria-label="toggle"
                        name={"toggle"}
                        className={styles.menuBtn}
                        onClick={handleClickSideEffect}
                        id="toggle-hamburger"
                        ref={toggleHamburger}>
                        <KeyboardDoubleArrowRight />
                    </button>
                    {sideNavBarData.map(item => {
                        return <div className={`${item.id} sideitem`}>
                            <Link
                                key={item.id}
                                href={item.link}
                                className={styles.sideitem}>
                                {item.icon}
                                <span className={styles.linkText}>{item.text}</span>
                            </Link>
                        </div>
                    })}
                </div>
            }

        </>

    )


}