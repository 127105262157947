import React, { useEffect, useState, useCallback, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import { MenuItem, Select } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


//This class is for the create / edit control form on a project

export async function getKeywords() {
  const url = `${process.env.REACT_APP_API_URL}/keyword/`
  const response = axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export async function getControlDataDropDowns (getType, projectId) {
  var url;
  if (getType === 'controls') {
    url = `${process.env.REACT_APP_API_URL}/projects/${projectId}/addable_control/`;
  }
  if (getType === 'control_classes') {
    url = `${process.env.REACT_APP_API_URL}/control_classes/?sort_by=-created_date&offset=0&limit=30`;
  }
  if (getType === 'control_families') {
    url = `${process.env.REACT_APP_API_URL}/control_families/?sort_by=-created_date&offset=0&limit=30`;
  }
  if (getType === 'control_phases') {
    url = `${process.env.REACT_APP_API_URL}/control_phases/?sort_by=-created_date&offset=0&limit=30`;
  }
  if (getType === 'control_statuses') {
    url = `${process.env.REACT_APP_API_URL}/control_statuses/?sort_by=-created_date&offset=0&limit=30`;
  }
  const response = axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {

    return result;
  }).catch(error => { return error.message; })
  return response
}

export async function postFormData(
  data, 
  projectId, 
  controlId, 
  keywordSelections, 
  assessmentConfirmed, 
  editDataObj, 
  controlFamilyId, 
  controlPhaseId, 
  controlStatusId, 
  controlClassId) {
  //look at keywords
  if (!/^[a-z,]*$/.test(data?.newKeywords)) {
    return `Sorry your keywords contain values other than lowercase characters and commas`
  } else {
    //clean up duplicate vals
    var cleanedKeywords = []
    var enteredKeywords = data.newKeywords.split(',')
    for (let x = 0; x < enteredKeywords.length; x++) {
      let match = false
      for (let y = 0; y < keywordSelections.length; y++) {
        if (enteredKeywords[x] === keywordSelections[y].name) {
          match = true
        }
      }
      if (match === false) {
        cleanedKeywords.push(enteredKeywords[x])
      }
    }
    for (let p = 0; p < keywordSelections.length; p++) {
      cleanedKeywords.push(keywordSelections[p].name)
    }
    var cleanedWords = cleanedKeywords.join(',')
    var url;
    var method;
    if (!editDataObj) {
      method = "POST";
    }
    /* project controls */
    url = `${process.env.REACT_APP_API_URL}/project_controls/${projectId}/${controlId}?mitigation_percentage=${data?.mitigationPercentage}&control_family_id=${controlFamilyId}&control_phase_id=${controlPhaseId}&control_status_id=${controlStatusId}&control_class_id=${controlClassId}&keywords=${cleanedWords}&assessment_confirmed=${assessmentConfirmed}`

    //if a data object is being edited
    if (editDataObj) {
      method = "PUT";
      url = `${process.env.REACT_APP_API_URL}/project_controls/${projectId}/${controlId}?mitigation_percentage=${data?.mitigationPercentage}&control_family_id=${controlFamilyId}&control_phase_id=${controlPhaseId}&control_status_id=${controlStatusId}&control_class_id=${controlClassId}&keywords=${cleanedWords}&assessment_confirmed=${assessmentConfirmed}`;
    }
    const response = axios(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.access_token}`,
      },
      //data: body,
    }).then(result => {
      return result;
    }).catch(error => { return error.message; })
    return response;
  }
}


export default function CreateEditProjectControl({
  editDataObj,
  isOpen,
  projectId,
  controlData,
}) {
  //console.log('editDataObj', editDataObj)
  //axios error message
  const [errMsg, setErrMsg] = useState("");
  useEffect(() => {
  }, [errMsg])

  //used with select fields in form
  const [controlId, setControlId] = useState(parseInt(editDataObj?.control?.id));
  //const [projectControlId, setProjectControlId] = useState(parseInt(editDataObj?.id))
  const [controlClassId, setControlClassId] = useState(editDataObj?.control_class_id);
  const [controlFamilyId, setControlFamilyId] = useState(editDataObj?.control_family_id);
  const [controlPhaseId, setControlPhaseId] = useState(editDataObj?.control_phase_id);
  const [controlStatusId, setControlStatusId] = useState(editDataObj?.control_status_id);
  //dropdown values 
  const [controls, setControls] = useState([]);
  const [controlClasses, setControlClasses] = useState([]);
  const [controlFamilies, setControlFamilies] = useState([]);
  const [controlPhases, setControlPhases] = useState([]);
  const [controlStatuses, setControlStatuses] = useState([]);

  const [assessmentConfirmed, setAssessmentConfirmed] = useState('yes');

  const handleChange = (event) => {
    setAssessmentConfirmed(event.target.value);
  };

  //default option when no value selected in drop down
  const selectOption = useMemo(() => { return { value: "0", label: "Please select..." } }, [])
  const [keywords, setKeywords] = useState([{ id: "0", name: "Please select..." }]);;

  const [keywordSelections, setKeywordSelections] = useState([]);

  const [keywordId, setKeywordId] = useState();
  const keywordSelectOption = useMemo(() => { return { id: "0", name: "Please select..." } }, [])

  useEffect(() => {
    if (editDataObj?.keywords) {
      let options = []
      for (let i = 0; i < editDataObj?.keywords.length; i++) {
        options.push({ id: editDataObj?.keywords[i].id, name: editDataObj?.keywords[i].keyword.name })
      }
      setKeywordSelections(options)
    }
  }, [editDataObj])

  const removeKeyword = (key) => {
    var tmpfilter = keywordSelections.filter(keyword => keyword.id !== key.id)
    setKeywordSelections(tmpfilter);
    var keyword = keywordSelections.filter(word => word.id === key.id)
    var name = keyword[0].name
    setKeywords(keywords => [...keywords, { name: name, id: key.id }]);
  }

  const handleChangeKeywordSelect = (event) => {
    setKeywordId(event.target.value);
    var keyword = keywords.filter(word => word.id === event.target.value)
    var name = keyword[0].name
    setKeywordSelections(keywordSelections => [...keywordSelections, { name: name, id: event.target.value }]);
    var tmpfilterSelect = keywords.filter(keyword => keyword.id !== event.target.value)
    setKeywords(tmpfilterSelect)
  }

  const { control, handleSubmit } = useForm({
    defaultValues: {
      mitigationPercentage: editDataObj?.mitigation_percentage,
      controlSelect: {},
      controlFamilySelect: {},
      controlPhaseSelect: {},
      controlStatusSelect: {},
      controlClassSelect: {},
      newKeywords: "",
      keywordSelect: {},
    }
  });

  // handle change events on dropdowns
  const handleChangeControlSelect = (event) => {
    setControlId(event.target.value);
  }
  const handleChangeControlClassSelect = (event) => {
    setControlClassId(event.target.value);
  }
  const handleChangeControlFamilySelect = (event) => {
    setControlFamilyId(event.target.value);
  }
  const handleChangeControlPhaseSelect = (event) => {
    setControlPhaseId(event.target.value);
  }
  const handleChangeControlStatusSelect = (event) => {
    setControlStatusId(event.target.value);
  }

  /* form submit */
  const onSubmit = async (data) => {
    const response = await postFormData(data, projectId, controlId, keywordSelections, assessmentConfirmed, editDataObj, controlFamilyId, controlPhaseId, controlStatusId, controlClassId);
    if (response.status !== 200) {
      setErrMsg(`${response}`)
    }
    var curProjControl = response.data
    controlData(curProjControl);
    isOpen(false);
  }

  const getDropDownOptions = useCallback(async () => {
    var allKeyOptions = [];
    const jsonDataKey = await getKeywords();
    const tmpoptions = jsonDataKey.data.map((word) => ({
      id: word.id,
      name: word.name,
    }));
    allKeyOptions = [keywordSelectOption, ...tmpoptions];
    setKeywords(allKeyOptions);
    const jsonData = await getControlDataDropDowns('controls', projectId);
    const controlOptions = jsonData.data.map((control) => ({
      value: control.id,
      label: control.name,
    }));
    if (controlOptions.length < 1 && !editDataObj) {
      setErrMsg("There are no more addable controls available. Please add a new one.");
    }
    var allOptions = [];
    allOptions = [selectOption, ...controlOptions];
    if (editDataObj) {
      var selectedControl = { value: controlId, label: editDataObj.control.name }
      allOptions = [selectedControl, ...controlOptions];
    }
    setControls(allOptions);
    const jsonDataClasses = await getControlDataDropDowns('control_classes', projectId);
    const controlClassOptions = jsonDataClasses.data.items.map((control) => ({
      value: control.id,
      label: control.name,
    }));
    var allClassOptions = []
    allClassOptions = [selectOption, ...controlClassOptions];
    setControlClasses(allClassOptions);
    const jsonDataFamilies = await getControlDataDropDowns('control_families', projectId);
    const controlFamiliesOptions = jsonDataFamilies.data.items.map((control) => ({
      value: control.id,
      label: control.name,
    }));
    var allControlFamiliesOptions = [selectOption, ...controlFamiliesOptions];
    setControlFamilies(allControlFamiliesOptions);
    const jsonDataPhases = await getControlDataDropDowns('control_phases', projectId);
    const controlPhasesOptions = jsonDataPhases.data.items.map((control) => ({
      value: control.id,
      label: control.name,
    }));
    var allControlPhasesOptions = [selectOption, ...controlPhasesOptions];
    setControlPhases(allControlPhasesOptions);
    const jsonDataStatuses = await getControlDataDropDowns('control_statuses', projectId);
    const controlStatusesOptions = jsonDataStatuses.data.items.map((control) => ({
      value: control.id,
      label: control.name,
    }));
    var allControlStatusesOptions = [selectOption, ...controlStatusesOptions];
    setControlStatuses(allControlStatusesOptions);
  }, [controlId, editDataObj, projectId, selectOption, keywordSelectOption])

  useEffect(() => {
    getDropDownOptions();
  }, [getDropDownOptions]);

  return (
    <div className="create edit project control">
      {errMsg ? (
        <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
          <Alert severity="error">{errMsg}</Alert>
        </Stack>
      ) : (
        <></>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-field control">
          <label>Control</label>
          <Controller
            name="controlSelect"
            control={control}
            defaultValue={0}
            render={({ field }) =>
              <Select
                {...field}
                onChange={handleChangeControlSelect}
                defaultValue={0}
                value={controlId ? controlId : 0}
                inputProps={{ "data-testid": "control-select" }}
              >
                {
                  controls?.map((control) => {
                    return <MenuItem
                      value={control.value}
                      key={control.value}>
                      {control.label}
                    </MenuItem>
                  })
                }
              </Select>
            }
          />
        </div>
        <div className="form-field control">
          <label>Control Class</label>
          <Controller
            name="controlClassSelect"
            control={control}
            defaultValue={0}
            render={({ field }) =>
              <Select
                {...field}
                onChange={handleChangeControlClassSelect}
                value={controlClassId ? controlClassId : 0}
                defaultValue={0}
                inputProps={{ "data-testid": "control-class" }}
              >
                {
                  controlClasses?.map((control) => {
                    return <MenuItem
                      value={control.value}
                      key={control.value}>
                      {control.label}
                    </MenuItem>
                  })
                }
              </Select>
            }
          />
        </div>
        <div className="form-field control">
          <label>Control Family</label>
          <Controller
            name="controlFamilySelect"
            control={control}
            defaultValue={0}
            render={({ field }) =>
              <Select
                {...field}
                onChange={handleChangeControlFamilySelect}
                value={controlFamilyId ? controlFamilyId : 0}
                defaultValue={0}
                inputProps={{ "data-testid": "control-family" }}
              >
                {
                  controlFamilies?.map((control) => {
                    return <MenuItem
                      value={control.value}
                      key={control.value}>
                      {control.label}
                    </MenuItem>
                  })
                }
              </Select>
            }
          />
        </div>
        <div className="form-field control">
          <label>Control Phase</label>
          <Controller
            name="controlPhaseSelect"
            control={control}
            defaultValue={0}
            render={({ field }) =>
              <Select
                {...field}
                onChange={handleChangeControlPhaseSelect}
                value={controlPhaseId ? controlPhaseId : 0}
                defaultValue={0}
                inputProps={{ "data-testid": "control-phase" }}
              >
                {
                  controlPhases?.map((control) => {
                    return <MenuItem
                      value={control.value}
                      key={control.value}>
                      {control.label}
                    </MenuItem>
                  })
                }
              </Select>
            }
          />
        </div>
        <div className="form-field control">
          <label>Control Status</label>
          <Controller
            name="controlStatusSelect"
            control={control}
            defaultValue={0}
            render={({ field }) =>
              <Select
                {...field}
                onChange={handleChangeControlStatusSelect}
                value={controlStatusId ? controlStatusId : 0}
                defaultValue={0}
                inputProps={{ "data-testid": "control-status" }}
              >
                {
                  controlStatuses?.map((control) => {
                    return <MenuItem
                      value={control.value}
                      key={control.value}>
                      {control.label}
                    </MenuItem>
                  })
                }
              </Select>
            }
          />
        </div>
        <div className="form-field mitigation">
          <Controller
            name="mitigationPercentage"
            control={control}
            render={({ field }) => <TextField required label="Mitigation Percentage" {...field} fullWidth />}
          />
        </div>
        <div className="form-field keywords">
          <div className="form-field control">
            <label>Existing Keywords</label>
            <Controller
              name="keywordSelect"
              control={control}
              defaultValue={0}
              render={({ field }) =>
                <Select
                  {...field}
                  value={keywordId ? keywordId : 0}
                  defaultValue={0}
                  onChange={handleChangeKeywordSelect}
                  inputProps={{ "data-testid": "keyword" }}
                >
                  {
                    keywords?.map((word) => {
                      return <MenuItem
                        value={word.id}
                        key={word.id}>
                        {word.name}
                      </MenuItem>
                    })
                  }
                </Select>
              }
            />
          </div>
          <ul>
            {(keywordSelections && (
              keywordSelections.map((word) => {
                return <li>
                  {word.name}
                  <IconButton aria-label="delete">
                    <DeleteIcon onClick={() => { removeKeyword(word); }} />
                  </IconButton>
                </li>
              })
            )
            )}
          </ul>
          <div className="form-field newkeywords">
            <Controller
              name="newKeywords"
              control={control}
              render={({ field }) =>
                <TextField
                  {...field}
                  label="Add new keywords"
                  //fullWidth
                  helperText="Enter your keyword in all lowercase with a comma and no spaces between each one e.g. legal,technical"
                />

              }
            />
          </div>
        </div>
        <FormControl>
          <FormLabel id="demo-controlled-radio-buttons-group">CAP / PO&AM (Plan of Action & Milestone) / Compliant</FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={assessmentConfirmed}
            onChange={handleChange}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
        <div>
          {
            (editDataObj && <Button type="submit" variant="contained" >Submit</Button>) ||
            (!editDataObj && <Button type="submit" variant="contained" >Create</Button>)
          }
        </div>
      </form>
    </div>
  )
}