import React, { useState, useEffect, lazy, Suspense } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link as UpperLink } from '@mui/material';
import '../css/Subscription.css';
import { Box } from "@mui/material";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import SpinnerComponent from '../components/LoadingSpinner';
const BillingInformation = lazy(() => import('../components/BillingInformation'));
const SubscriptionPlan = lazy(() => import('../components/SubscriptionPlan'));
const SubscriptionUsersList = lazy(() => import('../components/lists/SubscriptionUsersList'));
const InvoiceList = lazy(() => import('../components/lists/InvoiceList'));

export default function Subscription({ days, paused, canceled }) {
  const [value, setValue] = useState(0);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [isSystemAdmin, setIsSystemAdmin] = useState(false);
  const [isBillingAdmin, setIsBillingAdmin] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("system_role") === "4") {
      setIsSystemAdmin(true)
    }
  }, [setIsSystemAdmin])

  useEffect(() => {
    if (localStorage.getItem("system_role") === "5") {
      setIsBillingAdmin(true)
    }
  }, [setIsBillingAdmin])

  useEffect(() => {
  }, [isSystemAdmin])

  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };
  const [payment, setPayment] = useState(false);
  const onChange = (e, value) => {
    setValue(value);
  };

  useEffect(() => {

  }, [days])

  useEffect(() => {

  }, [payment])

  const paymentmethod = () => {
    setPayment(true);
  }

  return (
    <>{isSystemAdmin || isBillingAdmin ? <div className="tab-detail subscription">
      <Breadcrumbs aria-label="breadcrumb">
        <Breadcrumbs aria-label="breadcrumb">
          <UpperLink underline="hover" color="inherit" href="/system-administration">
            System Administration
          </UpperLink>
          <Typography color="text.primary">Subscription</Typography>
        </Breadcrumbs>
      </Breadcrumbs>
      <h1>Subscription and Users</h1>
      <p className="description">Manage your subscription and invite users to collaborate</p>
      {payment ? <Suspense fallback={<SpinnerComponent />}><SubscriptionPlan /></Suspense> : <Typography color="text.primary">Please set a payment method</Typography>}
      <Tabs value={currentTabIndex} onChange={handleTabChange}>
        <Tab label='Billing' />
        <Tab label='Invoices' />
      </Tabs>
      {/* TAB 1 Contents */}
      {currentTabIndex === 0 && (
        <Box sx={{ p: 3 }}>
          <Typography variant='h5'>Billing</Typography>
          <Suspense fallback={<SpinnerComponent />}><BillingInformation paymentmethod={paymentmethod} /></Suspense>
        </Box>
      )}

      {/* TAB 2 Contents */}
      {currentTabIndex === 1 && (
        <Box sx={{ p: 3 }}>
          <Typography variant='h5'>Invoices</Typography>
          <Suspense fallback={<SpinnerComponent />}><InvoiceList /></Suspense>
        </Box>
      )}
      {(!days && canceled && paused && isSystemAdmin && <>
        <Tabs value={value} onChange={onChange}>
          <Tab label="Users" />
          <Tab label="Invitations" />
        </Tabs>
        {/* TAB 1 Contents */}
        {value === 0 && (
          <Box sx={{ p: 3 }}>
            <Suspense fallback={<SpinnerComponent />}><SubscriptionUsersList /></Suspense>
          </Box>
        )}

        {/* TAB 2 Contents */}
        {value === 1 && (
          <Box sx={{ p: 3 }}>
            <Typography variant='h5'>Invites</Typography>
            <Suspense fallback={<SpinnerComponent />}><SubscriptionUsersList invitations={true} /></Suspense>
          </Box>
        )}
      </>
      )}


    </div> : <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
      <Alert severity="warning">You don't have access to subscription administration.</Alert>
    </Stack>}
    </>
  );
}