import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Details from './Details';
import FrameworkVersionList from '../components/lists/FrameworkVersionList';
//import { Link } from '@mui/material';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

export default function Framework({id}) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }} className='frameworkDetails'>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
        <LinkTab label="Framework" href={`/frameworks/${id}`}/>
        <LinkTab label="Versions" href={`/frameworks/${id}/framework_versions`} />
      </Tabs>
    </Box>
    <TabPanel value={value} index={0}>
      <Details dataType="frameworks" id={id} />
    </TabPanel>
    <TabPanel value={value} index={1}>
      <FrameworkVersionList frameworkId={id} />
    </TabPanel>
  </Box>
  );
}