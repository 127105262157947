import React, { useCallback, useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import SpinnerComponent from '../LoadingSpinner';
import '../../css/AWSReportCardList.css'

//This class displays lists of aws controls

const columns = [
    { field: 'id', headerName: 'id', width: 120 },
];

const rows = [
    { id: "n/a" },
];

export default function AWSControlList({
    //projectId,
    awsControlData,
}) {

    const [rowsData, setRowsData] = useState(null);
    const [colsData, setColsData] = useState(null);

    const getData = useCallback(() => {
        if (awsControlData) {
            var filteredRowDataAwsControls = [];
            awsControlData?.forEach((key) => {
                filteredRowDataAwsControls.push({
                    id: key.aws_control_id,
                    aws_id: key.aws_control.aws_id,
                    aws_title: key.aws_control.aws_title,
                    aws_control_status: key.aws_control.aws_control_status,
                    aws_severity: key.aws_control.aws_severity,
                    aws_failed_checks: key.aws_control.aws_failed_checks,
                    aws_unknown_checks: key.aws_control.aws_unknown_checks,
                    aws_not_available_checks: key.aws_control.aws_not_available_checks,
                    aws_passed_checks: key.aws_control.aws_passed_checks,
                    aws_related_requirements: key.aws_control.aws_related_requirements,
                    aws_custom_parameters: key.aws_control.aws_custom_parameters,
                    created: key.aws_control.created,
                });
            })
            setRowsData([...filteredRowDataAwsControls]);
            var colsVals = [
                {
                    field: 'aws_id',
                    headerName: 'ID',
                    width: 100,
                    cellClass: "normalLineHeight"
                },
                {
                    field: 'aws_title',
                    headerName: 'Title',
                    width: 200,
                    cellClass: "normalLineHeight"
                },
                {
                    field: 'aws_control_status',
                    headerName: 'Status',
                    width: 100,
                    renderCell: (params) => (
                        <div className={params.value}>{params.value}</div>
                    )
                },
                {
                    field: 'aws_severity',
                    headerName: 'Severity',
                    width: 100,
                    renderCell: (params) => (
                        <><div className={params.value}>&#9632;</div><div className={params.value}>{params.value}</div></>
                    )
                },
                {
                    field: 'aws_failed_checks',
                    headerName: 'Failed Checks',
                    width: 100,
                    renderCell: (params) => (
                        <div className="failed">{params.value}</div>
                    )
                },
                {
                    field: 'aws_unknown_checks',
                    headerName: 'Unknown Checks',
                    width: 100,
                    renderCell: (params) => (
                        <div className="unknown">{params.value}</div>
                    )
                },
                {
                    field: 'aws_not_available_checks',
                    headerName: 'N/A Checks',
                    width: 100,
                    renderCell: (params) => (
                        <div className="notavailable">{params.value}</div>
                    )
                },
                {
                    field: 'aws_passed_checks',
                    headerName: 'Passed Checks',
                    width: 100,
                    renderCell: (params) => (
                        <div className="passed">{params.value}</div>
                    )
                },
                {
                    field: 'aws_related_requirements',
                    headerName: 'Requirements',
                    width: 200,
                    cellClass: "normalLineHeight"
                },
                {
                    field: 'aws_custom_parameters',
                    headerName: 'Custom Params',
                    width: 200,
                    cellClass: "normalLineHeight"
                },
                {
                    field: 'created',
                    headerName: 'Created',
                    width: 100,
                    cellClass: "normalLineHeight"
                },
            ];
            setColsData([...colsVals]);
        }
    }, [awsControlData])

    useEffect(() => {
        if (!rowsData) {
            getData();
        }
    }, [getData, rowsData]);


    return (
        <div style={{ marginTop: '0px' }} className="awsControlList">
            <>
                <div className="aws_control list" style={{}}>
                    <DataGrid
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        rows={rowsData ? rowsData : rows}
                        columns={colsData ? colsData : columns}
                        rowsPerPageOptions={[10]}
                        pageSize={10}
                        //checkboxSelection
                        //loading={loading}
                        components={{
                            LoadingOverlay: SpinnerComponent,
                        }}
                        //onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                        getRowHeight={() => 'auto'}
                    />
                </div>
            </>
        </div>
    )

}