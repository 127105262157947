import React from 'react';

const HelpMessages = () => {

  return <>
    <div
      className="react-chatbot-kit-chat-bot-message">
      <span>What do you need help with today? Type a word or a sentence to get started.</span>
    </div>

  </>
}

export default HelpMessages;