import React from "react";
import '../css/Register.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
//import Button from '@mui/material/Button';
import '../css/RegisterPlanInvoice.css';
//import TextField from "@mui/material/TextField"
//import axios from 'axios';


export default function RegisterPlanInvoice() {

    return (
        <div className="register-plan">
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableBody>
                        <TableRow
                            key='project'
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="left" className='title'>FedRisk Plan</TableCell>
                            <TableCell align="left" className='title'>Number of Users</TableCell>
                            <TableCell align="left" className='title'>Subscription Type</TableCell>
                            <TableCell align="left" className='title'>Tax</TableCell>
                            {/*<TableCell align="left" className='title'><Button>Change Plans</Button></TableCell>*/}
                        </TableRow>
                        <TableRow
                            key='framework'
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="left">{localStorage.getItem("plan")}</TableCell>
                            <TableCell align="left">{localStorage.getItem("heads")}</TableCell>
                            <TableCell align="left">{localStorage.getItem("plan")}ly</TableCell>
                            <TableCell align="left">$0</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>

    )
}