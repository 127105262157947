import ReactSearchBox from "react-search-box";
import SearchIcon from '@mui/icons-material/Search';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import jwt_decode from "jwt-decode";

export async function getData (type) {
  //call data endpoint for data type to set rows and columns
  var url = `${process.env.REACT_APP_API_URL}/${type}/`;
  if (type === "audit_tests") {
    url = `${process.env.REACT_APP_API_URL}/${type}/?sort_by=-created_date&limit=100&offset=0`
  }
  if (type === "projects") {
    url = `${process.env.REACT_APP_API_URL}/projects/get_user_projects/${localStorage.getItem("userid")}`
  }
  if (type === "project_groups") {
    url = `${process.env.REACT_APP_API_URL}/${type}/?sort_by=name&limit=100&offset=0`
  }
  if (type === "risks") {
    url = `${process.env.REACT_APP_API_URL}/${type}/?sort_by=-created_date&limit=100&offset=0`
  }
  if (type === "tasks") {
    url = `${process.env.REACT_APP_API_URL}/${type}/?offset=0&limit=100&sort_by=name`
  }
  try {
    const response = await axios(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.access_token}`,
      },
    })
    return response;
  } catch (err) {
    if (!err?.response) {
      //setErrMsg("No Server Response");
    } else if (err.response?.status) {
      const status = String(err.response?.status)
      if (status === "422") {
        return `Validation error.`
      } else {
        return `There was a ${err.response?.status} error`
      }
    } else {
      return "There was an error"
    }
  }
}

export default function HeaderSearch() {

  const [data, setData] = useState([]);

  const navigate = useNavigate();

  const getAllData = useCallback(async () => {
    var allData = []
    const jsonDataKeywords = await getData("keyword");
    if (jsonDataKeywords?.data) {
      jsonDataKeywords?.data.map(keyword => {
        allData.push(
          {
            key: keyword.name,
            value: keyword.name,
            path: `/keywords/${keyword.id}`,
          }
        )
        return keyword
      })
    }
    const jsonDataProjects = await getData("projects");
    if (jsonDataProjects?.data) {
      jsonDataProjects?.data.forEach(project => {
        allData.push(
          {
            key: project.name,
            value: project.name,
            path: `/projects/${project.id}`,
          }
        )
      })
    }
    // const jsonDataAuditTests = await getData("audit_tests");
    // if (jsonDataAuditTests.data) {
    //   jsonDataAuditTests.data.items.forEach(at => {
    //     allData.push(
    //       {
    //         key: at.name,
    //         value: at.name,
    //         path: `/projects/${at.project.id}/audit_tests/${at.id}`,
    //       }
    //     )
    //   })
    // }
    // const jsonDataRisks = await getData("risks");
    // if (jsonDataRisks.data) {
    //   jsonDataRisks.data.items.forEach(risk => {
    //     allData.push(
    //       {
    //         key: risk.name,
    //         value: `${risk.name}`,
    //         path: `/projects/${risk.project.id}/risks/${risk.id}`,
    //       }
    //     )
    //   })
    // }
    // const jsonDataTasks = await getData("tasks");
    // if (jsonDataTasks.data) {
    //   jsonDataTasks.data.items.forEach(task => {
    //     allData.push(
    //       {
    //         key: task.name,
    //         value: task.name,
    //         path: `/projects/${task.project.id}/tasks/${task.id}`,
    //       }
    //     )
    //   })
    // }
    // const jsonDataProjEvals = await getData("project_evaluations");
    // if (jsonDataProjEvals.data) {
    //   jsonDataProjEvals.data.map(pe => {
    //     allData.push(
    //       {
    //         key: pe.name,
    //         value: pe.name,
    //         path: `/projects/${pe.project.id}/project_evaluations/${pe.id}`,
    //       }
    //     )
    //   })
    // }
    setData(allData);
  }, [])

  const goToPath = (path) => {
    navigate(`${path}`)
    window.location.reload(false);
  }

  useEffect(() => {
    if (localStorage.access_token) {
      var decodetoken = jwt_decode(localStorage.access_token);
      var expTime = decodetoken.exp * 1000;
      var curTime = Math.floor(Date.now());
      if (expTime < curTime) {
        localStorage.clear();
      }
      if (expTime > curTime) {
        getAllData();
      }
    }
  }, [getAllData]);


  return (
    <ReactSearchBox
      placeholder='Search...'
      data={data}
      onSelect={(record) => goToPath(record.item.path)}
      onFocus={() => {
        //console.log("This function is called when is focussed");
      }}
      onChange={(value) => console.log(value)}
      autoFocus
      leftIcon={<SearchIcon />}
      iconBoxSize="48px"
      inputBackgroundColor="#007EB3"
      inputFontColor="#ffffff"
      dropDownHoverColor="#007EB3"
    />
  )
}