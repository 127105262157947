import * as React from "react";
import "../css/App.css";

function Loader() {
  return (
    <div className="loaderContainer"
    >
      <div className="loader"
      >
        <svg
          width="50"
          height="50"
          viewBox="0 0 16 16"
          color="#457BB8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.05022 3.05026 C 2.65969 3.44078 2.65969 4.07394 3.05022 4.46447 C 3.44074 4.85499 4.07391 4.85499 4.46443 4.46447 L 3.05022 3.05026Z M 4.46443 4.46447 C 5.16369 3.76521 6.05461 3.289 7.02451 3.09608 L 6.63433 1.13451 C 5.27647 1.4046 4.02918 2.07129 3.05022 3.05026 L 4.46443 4.46447Z M 7.02451 3.09608 C 7.99442 2.90315 8.99975 3.00217 9.91338 3.3806 L 10.6787 1.53285C9.39967 1.00303 7.9922 0.864409 6.63433 1.13451 L 7.02451 3.09608ZM9.91338 3.3806C10.827 3.75904 11.6079 4.39991 12.1573 5.22215L13.8203 4.11101C13.0511 2.95987 11.9578 2.06266 10.6787 1.53285L9.91338 3.3806ZM12.1573 5.22215C12.7067 6.0444 13 7.01109 13 8L15 8C15 6.61553 14.5894 5.26215 13.8203 4.11101L12.1573 5.22215Z"
            fill="url(#paint0_linear_11825_47664)"
          ></path>
          <path
            d="M3 8C3 7.44772 2.55228 7 2 7C1.44772 7 1 7.44772 1 8L3 8ZM1 8C1 8.91925 1.18106 9.82951 1.53284 10.6788L3.3806 9.91342C3.12933 9.30679 3 8.65661 3 8L1 8ZM1.53284 10.6788C1.88463 11.5281 2.40024 12.2997 3.05025 12.9497L4.46447 11.5355C4.00017 11.0712 3.63188 10.52 3.3806 9.91342L1.53284 10.6788ZM3.05025 12.9497C3.70026 13.5998 4.47194 14.1154 5.32122 14.4672L6.08658 12.6194C5.47996 12.3681 4.92876 11.9998 4.46447 11.5355L3.05025 12.9497ZM5.32122 14.4672C6.1705 14.8189 7.08075 15 8 15L8 13C7.34339 13 6.69321 12.8707 6.08658 12.6194L5.32122 14.4672ZM8 15C8.91925 15 9.82951 14.8189 10.6788 14.4672L9.91342 12.6194C9.30679 12.8707 8.65661 13 8 13L8 15ZM10.6788 14.4672C11.5281 14.1154 12.2997 13.5998 12.9497 12.9497L11.5355 11.5355C11.0712 11.9998 10.52 12.3681 9.91342 12.6194L10.6788 14.4672ZM12.9497 12.9497C13.5998 12.2997 14.1154 11.5281 14.4672 10.6788L12.6194 9.91342C12.3681 10.52 11.9998 11.0712 11.5355 11.5355L12.9497 12.9497ZM14.4672 10.6788C14.8189 9.8295 15 8.91925 15 8L13 8C13 8.65661 12.8707 9.30679 12.6194 9.91342L14.4672 10.6788Z"
            fill="url(#paint1_linear_11825_47664)"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_11825_47664"
              x1="14"
              y1="8"
              x2="2"
              y2="8"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="currentColor" stopOpacity="0.5"></stop>
              <stop
                offset="1"
                stopColor="currentColor"
                stopOpacity="0"
              ></stop>
            </linearGradient>
            <linearGradient
              id="paint1_linear_11825_47664"
              x1="2"
              y1="8"
              x2="14"
              y2="8"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="currentColor"></stop>
              <stop
                offset="1"
                stopColor="currentColor"
                stopOpacity="0.5"
              ></stop>
            </linearGradient>
          </defs>
          <animateTransform
            from="0 0 0"
            to="360 0 0"
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1300ms"
          ></animateTransform>
        </svg>
      </div>
    </div>
  );
}

export default Loader;
