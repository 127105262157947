import React, { useEffect, useState, useCallback, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import { MenuItem, Select } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

//This class is used for manipulating project related data e.g. risks, audit tests, users etc.

export async function getKeywords() {
  const url = `${process.env.REACT_APP_API_URL}/keyword/`
  const response = axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export async function getDropdownData(type) {
  var url;
  if (type === 'users') {
    url = `${process.env.REACT_APP_API_URL}/users/get_users_tenant`
  }
  if (type === 'roles') {
    url = `${process.env.REACT_APP_API_URL}/roles/?offset=0&limit=10&order=-created_date`
  }
  try {
    const response = axios(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.access_token}`,
      },
    })
    return response;
  } catch (err) {
    if (!err?.response) {
      return "No Server Response";
    } else if (err.response?.status) {
      const status = String(err.response?.status)
      if (status === "422") {
        return `Validation error.`
      } else {
        return `There was a ${err.response?.status} error`
      }
    } else {
      return "There was an error"
    }
  }
}

export async function postFormData(data, keywordSelections, editDataObj, projectId, userId, roleId, dataType) {
  var cleanedWords = ""
  //look at keywords
  if (!/^[a-z,]*$/.test(data?.newKeywords)) {
    return `Sorry your keywords contain values other than lowercase characters and commas`
  } else {
    //clean up duplicate vals
    var cleanedKeywords = []
    var enteredKeywords = data.newKeywords.split(',')
    for (let x = 0; x < enteredKeywords.length; x++) {
      let match = false
      for (let y = 0; y < keywordSelections.length; y++) {
        if (enteredKeywords[x] === keywordSelections[y].name) {
          match = true
        }
      }
      if (match === false) {
        cleanedKeywords.push(enteredKeywords[x])
      }
    }
    for (let p = 0; p < keywordSelections.length; p++) {
      cleanedKeywords.push(keywordSelections[p].name)
    }
    cleanedWords = cleanedKeywords.join(',')
  }
  var url;
  var body;
  var method = "POST";
  var postHeaders = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.access_token}`,
  }
  /* project wbs */
  if (dataType === "wbs" && !editDataObj) {
    url = `${process.env.REACT_APP_API_URL}/wbs?keywords=${cleanedWords}`
    body = JSON.stringify({
      project_id: projectId.toString(),
      name: data.name,
      description: data.description,
      user_id: localStorage.getItem("userid"),
    })
  }
  if (dataType === "wbs" && editDataObj) {
    url = `${process.env.REACT_APP_API_URL}/wbs/${editDataObj.id}?keywords=${cleanedWords}`
    body = JSON.stringify({
      id: editDataObj.id,
      project_id: projectId.toString(),
      name: data.name,
      description: data.description,
      user_id: localStorage.getItem("userid"),
    })
    method = "PUT"
  }
  /* project evaluations */
  if (dataType === "project_evaluations") {
    url = `${process.env.REACT_APP_API_URL}/project_evaluations?keywords=${cleanedWords}`
    body = JSON.stringify({
      project_id: projectId,
      name: data.name,
      description: data.description,
      comments: data.comments,
      keywords: data.keywords,
    })
  }
  /* project users */
  if (dataType === "users" && !editDataObj) {
    url = `${process.env.REACT_APP_API_URL}/projects/${projectId}/add_a_user/${userId}/${roleId}`
    method = "PUT"
  }
  if (dataType === "users" && editDataObj) {
    url = `${process.env.REACT_APP_API_URL}/projects/${projectId}/change-role/`
    method = "PUT"
    body = {
      "user_id": userId,
      "role_id": roleId
    }
  }

  //if a data object is being edited
  if (editDataObj && dataType !== "users" && dataType !== "wbs") {
    method = "PUT";
    url = `${process.env.REACT_APP_API_URL}/${dataType}/${editDataObj.id}`;
  }
  //if a data object is being edited
  if (editDataObj && dataType === "project_evaluations") {
    method = "PUT";
    url = `${process.env.REACT_APP_API_URL}/${dataType}/${editDataObj.id}?keywords=${cleanedWords}`;
  }
  const response = axios(url, {
    method: method,
    headers: postHeaders,
    data: body,
  }).then(result => {
    if (dataType === `users`) {
      window.location.reload(false);
    }
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export default function AddProjectData({ dataType, editDataObj, projectId, isOpen, controlData }) {
  //axios error message
  const [errMsg, setErrMsg] = useState("");
  useEffect(() => {
  }, [errMsg])

  useEffect(() => {
  }, [editDataObj])

  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [userId, setUserId] = useState(editDataObj?.id);
  const [roleId, setRoleId] = useState();

  //default option when no value selected in drop down
  const selectOption = useMemo(() => { return { value: "0", label: "Please select..." } }, [])

  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: editDataObj?.name,
      description: editDataObj?.description,
      userSelect: {},
      roleSelect: {},
      newKeywords: "",
      keywordSelect: {},
    }
  });

  const [keywords, setKeywords] = useState([{ id: "0", name: "Please select..." }]);;

  const [keywordSelections, setKeywordSelections] = useState([]);

  const [keywordId, setKeywordId] = useState();
  const keywordSelectOption = useMemo(() => { return { id: "0", name: "Please select..." } }, [])

  useEffect(() => {
    if (editDataObj?.keywords) {
      let options = []
      for (let i = 0; i < editDataObj?.keywords.length; i++) {
        options.push({ id: editDataObj?.keywords[i].id, name: editDataObj?.keywords[i].keyword.name })
      }
      setKeywordSelections(options)
    }
  }, [editDataObj])

  const removeKeyword = (key) => {
    var tmpfilter = keywordSelections.filter(keyword => keyword.id !== key.id)
    setKeywordSelections(tmpfilter);
    var keyword = keywordSelections.filter(word => word.id === key.id)
    var name = keyword[0].name
    setKeywords(keywords => [...keywords, { name: name, id: key.id }]);
  }

  const handleChangeKeywordSelect = (event) => {
    setKeywordId(event.target.value);
    var keyword = keywords.filter(word => word.id === event.target.value)
    var name = keyword[0].name
    setKeywordSelections(keywordSelections => [...keywordSelections, { name: name, id: event.target.value }]);
    var tmpfilterSelect = keywords.filter(keyword => keyword.id !== event.target.value)
    setKeywords(tmpfilterSelect)
  }


  /* form submit */
  const onSubmit = async (data) => {
    const response = await postFormData(data, keywordSelections, editDataObj, projectId, userId, roleId, dataType);
    if (!response?.data) {
      setErrMsg(response)
    } else {
      if (dataType === "users") {
        var datastore = { id: 0, email: "", first_name: "", last_name: "", role: "" };
        datastore.id = response.data.users[0].user.id;
        datastore.email = response.data.users[0].user.email;
        datastore.first_name = response.data.users[0].user.first_name;
        datastore.last_name = response.data.users[0].user.last_name;
        datastore.role = response.data.users[0].role.name;
        controlData(datastore);
      }
      if (dataType === "project_evaluations") {
        var datastorepe = {};
        datastorepe.id = response.data.id;
        datastorepe.name = response.data.name;
        datastorepe.description = response.data.description;
        controlData(datastorepe);
      }
      if (dataType === "wbs") {
        controlData(response.data);
      }
    }
    isOpen(false);
  }

  const getDropDownOptions = useCallback(async () => {
    //if (dataType === "project_evaluations") {
    const jsonDataKey = await getKeywords();
    if (jsonDataKey?.data) {
      const tmpoptions = jsonDataKey.data.map((word) => ({
        id: word.id,
        name: word.name,
      }));
      var allOptions = [keywordSelectOption, ...tmpoptions];
      setKeywords(allOptions);
    }

    //}
    const jsonData = await getDropdownData('users');
    if (jsonData?.data) {
      const tmpoptions = jsonData.data.map((user) => ({
        value: user.id,
        label: user.email,
      }));
      setUsers([selectOption, ...tmpoptions]);
    } else {
      setErrMsg(jsonData)
    }
    const jsonDataRoles = await getDropdownData('roles');
    if (jsonDataRoles?.data) {
      const tmpoptionsroles = jsonDataRoles.data.items.map((role) => ({
        value: role.id,
        label: role.name,
      }));
      setRoles([selectOption, ...tmpoptionsroles]);
      if (editDataObj?.role) {
        tmpoptionsroles.map((item) => {
          if (item.label === editDataObj?.role) {
            setRoleId(item.value)
          }
          return item
        })
      }
    } else {
      setErrMsg(jsonDataRoles)
    }
    
  }, [selectOption, editDataObj?.role, keywordSelectOption])

  // handle change events on dropdowns
  const handleChangeUserSelect = (event) => {
    setUserId(event.target.value);
  }
  const handleChangeRoleSelect = (event) => {
    setRoleId(event.target.value);
  }

  useEffect(() => {
    if (dataType === "users" || dataType === "project_evaluations" || dataType === "wbs") {
      getDropDownOptions();
    }
  }, [getDropDownOptions, dataType]);

  useEffect(() => {
  }, [editDataObj]);

  return (
    <div className={dataType+`create edit project data`}>
      {errMsg ? (
        <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
          <Alert severity="error">{errMsg}</Alert>
        </Stack>
      ) : (
        <></>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        {(dataType !== "users" &&
          <div className="form-field name">
            <Controller
              name="name"
              control={control}
              render={({ field }) => <TextField required label="Name" {...field} fullWidth />}
            />
          </div>
        )}
        {(dataType !== "users" &&
          <div className="form-field description">
            <Controller
              name="description"
              control={control}
              render={({ field }) =>
                <TextField
                  {...field}
                  multiline
                  rows={4}
                  label="Description"
                  fullWidth
                />
              }
            />
          </div>
        )}

        {
          (dataType === "project_evaluations" &&
            <div className="form-field comments">
              <Controller
                name="comments"
                control={control}
                render={({ field }) =>
                  <TextField
                    {...field}
                    multiline
                    rows={2}
                    label="Comments"
                    fullWidth
                  />
                }
              />
            </div>
          )
        }
        {(dataType !== "users" && dataType !== "documents" &&
          <div className="form-field keywords">
            <div className="form-field control">
              <label>Existing Keywords</label>
              <Controller
                name="keywordSelect"
                control={control}
                defaultValue={0}
                render={({ field }) =>
                  <Select
                    {...field}
                    inputProps={{ "data-testid": "keyword" }}
                    value={keywordId ? keywordId : 0}
                    defaultValue={0}
                    onChange={handleChangeKeywordSelect}
                  >
                    {
                      keywords?.map((word) => {
                        return <MenuItem
                          value={word.id}
                          key={word.id}>
                          {word.name}
                        </MenuItem>
                      })
                    }
                  </Select>
                }
              />
            </div>
            <ul>
              {(keywordSelections && (
                keywordSelections.map((word) => {
                  return <li>
                    {word.name}
                    <IconButton aria-label="delete">
                      <DeleteIcon onClick={() => { removeKeyword(word); }} />
                    </IconButton>
                  </li>
                })
              )
              )}
            </ul>
            <div className="form-field newkeywords">
              <Controller
                name="newKeywords"
                control={control}
                render={({ field }) =>
                  <TextField
                    {...field}
                    label="Add new keywords"
                    //fullWidth
                    helperText="Enter your keyword in all lowercase with a comma and no spaces between each one e.g. legal,technical"
                  />

                }
              />
            </div>
          </div>
        )
        }

        {(dataType === "users" &&
          <>
            <div className="form-field user">
              <label>User</label>
              <Controller
                name="userSelect"
                control={control}
                defaultValue={0}
                render={({ field }) =>
                  <Select
                    {...field}
                    onChange={handleChangeUserSelect}
                    defaultValue={0}
                    inputProps={{ "data-testid": "user" }}
                    value={userId ? userId : 0}
                  >
                    {
                      users?.map((user) => {
                        return <MenuItem
                          value={user.value}
                          key={user.value}>
                          {user.label}
                        </MenuItem>
                      })
                    }
                  </Select>
                }
              />
            </div>
            <div className="form-field control">
              <label>Role</label>
              <Controller
                name="projectRoleSelect"
                control={control}
                defaultValue={0}
                render={({ field }) =>
                  <Select
                    {...field}
                    value={roleId ? roleId : 0}
                    defaultValue={0}
                    onChange={handleChangeRoleSelect}
                    inputProps={{ "data-testid": "role" }}
                  >
                    {
                      roles?.map((role) => {
                        return <MenuItem
                          value={role.value}
                          key={role.value}>
                          {role.label}
                        </MenuItem>
                      })
                    }
                  </Select>
                }
              />
            </div>
          </>
        )}
        <div>
          {
            (editDataObj && <Button type="submit" variant="contained" >Submit</Button>) ||
            (!editDataObj && <Button type="submit" variant="contained" >Create</Button>)
          }
        </div>
      </form>
    </div>
  )
}