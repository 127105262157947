import React, { useState, useEffect, useCallback } from 'react';
import { Box } from "@mui/material";
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import axios from 'axios';

export default function FeaturesManagement() {
    const [featureSettings, setFeatureSettings] = useState()
    const [awsSecurityHubChecked, setAwsSecurityHubChecked] = useState(false)
    const [featureId, setFeatureId] = useState(1)

    // Load existing feature settings
    const getFeatureSettings = useCallback(async () => {
        var url = `${process.env.REACT_APP_API_URL}/features`;
        const response = axios(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.access_token}`,
            },
        }).then(result => {
            return result;
        }).catch(error => { return error.message; })
        return response;
    }, [])

    // Update feature
    const updateFeatureSettings = useCallback(async (value) => {
        var url = `${process.env.REACT_APP_API_URL}/features/${featureId}`;
        const response = axios(url, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.access_token}`,
            },
            data: {
                "name": "aws_security_hub_imports",
                "is_active": value
            },
        }).then(result => {
            return result;
        }).catch(error => { return error.message; })
        return response;
    }, [featureId])



    const loadSettings = useCallback(async () => {
        const response = await getFeatureSettings()
        if (response?.data) {
            setFeatureSettings(response?.data)
        } else {
            setFeatureSettings([])
        }
    }, [getFeatureSettings])

    const enableDisableFeature = async () => {
        setFeatureId(1);
        if (awsSecurityHubChecked === false) {
            setAwsSecurityHubChecked(true)
            updateFeatureSettings(true)
        }
        if (awsSecurityHubChecked === true) {
            setAwsSecurityHubChecked(false)
            updateFeatureSettings(false)
        }
    }

    useEffect(() => {
        if (!featureSettings) {
            loadSettings()
        }
    }, [loadSettings, featureSettings])

    useEffect(() => {
        if (featureSettings) {
            //load into UI
            featureSettings.map((item) => {
                setAwsSecurityHubChecked(item.is_active)
                return item
            })
        }
    }, [featureSettings])

    return (
        <>
            <Box sx={{ p: 3 }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableCell>Feature</TableCell>
                        <TableCell>Enabled</TableCell>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>AWS Security Hub Import</TableCell>
                            <TableCell><Switch onChange={() => { enableDisableFeature(); }} checked={awsSecurityHubChecked} /></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Box>
        </>
    );
}