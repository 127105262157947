export enum ConstraintTypes {
	// As Soon As Possible (ASAP)
	ASAP = "asap",
	// As Late As Possible (ALAP)
	ALAP = "alap",
	// Start No Earlier Than (SNET)
	SNET = "snet",
	// Start No Later Than (SNLT)
	SNLT = "snlt",
	// Finish No Earlier Than (FNET)
	FNET = "fnet",
	// Finish No Later Than (FNLT)
	FNLT = "fnlt",
	// Must Start On (MSO)
	MSO = "mso",
	// Must Finish On (MFO)
	MFO = "mfo"
}