import React, { useState, useEffect } from 'react';
import SystemAdministrationCards from '../components/SystemAdministrationCards';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

export default function SystemAdministration() {
  const [isSystemAdmin, setIsSystemAdmin] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("system_role") === "4") {
      setIsSystemAdmin(true)
    }
  }, [setIsSystemAdmin])

  useEffect(() => {
  }, [isSystemAdmin])

  return (
    <>
      {isSystemAdmin ? <><h1>System Administration</h1><SystemAdministrationCards /></> : <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
        <Alert severity="warning">You don't have access to system administration.</Alert>
      </Stack>}
    </>
  );
}