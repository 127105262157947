// in config.js
import { createChatBotMessage } from 'react-chatbot-kit';
import HelpMessages from './HelpMessages';

const botName = 'FedRisk Virtual Assistant';

const config = {
  initialMessages: [createChatBotMessage(`Hi! I'm your ${botName}`, 
  {
    widget: "helpMessages"
  })],
  botName: botName,
  customStyles: {
    botMessageBox: {
      backgroundColor: '#1976d2',
    },
    chatButton: {
      backgroundColor: '#1976d2',
    },
  },
  widgets: [
    {
      widgetName: 'helpMessages',
      widgetFunc: (props) => <HelpMessages {...props} />,
      mapStateToProps: ["helpMessages"],
    },
  ],
  state: {
    helpMessages: [],
  }
};

export default config;