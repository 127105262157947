import React from "react";
import '../css/Register.css';
import Box from '@mui/material/Box';

import Logo from '../assets/fedrisk-full-logo.png';
import SubscriptionPlans from "./SubscriptionPlans";


export default function RegisterSelectPlan() {
 
    return (

        <div className="register-wrapper select plan">
            
            <Box
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '100%' },
                }}
            >
                <img src={Logo} alt="Logo" width="481px" />
                <h1>Decide on the right plan for your team</h1>
                <SubscriptionPlans registering={true}/>
                <div className="login-here">Already have an account? <a href="/">Log In</a></div>
            </Box>
        </div>
    )
}