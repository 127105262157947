import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import "../../css/List.css";
import SpinnerComponent from '../LoadingSpinner';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import CreateEditProjectTask from "../CreateEditProjectTask";
import AddCircleIcon from '@mui/icons-material/AddCircle';

//This class displays all lists in the app e.g. frameworks, projects etc.

const columns = [
    { field: 'id', headerName: 'id', width: 120 },
];

const rows = [
    { id: "n/a" },
];

export async function deleteData(id) {
    var body;
    var url = `${process.env.REACT_APP_API_URL}/tasks/${id}`;

    const response = axios(url, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
        data: body,
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export default function WBSTaskList({ taskData
}) {
    //console.log('taskData', taskData)
    //axios error message
    const [errMsg, setErrMsg] = useState("");

    const [rowsData, setRowsData] = useState(null);
    const [colsData, setColsData] = useState(null);

    const [editObj, setEditObj] = useState(null);

    //const [projectDataType, setProjectDataType] = useState();

    const [loading, setLoading] = useState(false);

    const [openAddDialog, setOpenAddDialog] = useState(null);

    const [selected, setSelected] = useState([]);

    const [openDialog, setOpenDialog] = useState(null);

    const onRowsSelectionHandler = (ids) => {
        setSelected([...ids]);
    };

    const handleClose = () => {
        setOpenAddDialog(false);
        setOpenDialog(false);
        setEditObj(null);
    }

    const isOpen = () => {
        setEditObj(null);
        handleClose();
    }

    const addControlData = (data) => {
        var arrcopy = rowsData.filter(row => row.id !== data.id)
        var tmparray = [...arrcopy, data];
        setRowsData(tmparray);
    }
    async function deleteSelectedRow() {
        for (let x = 0; x < selected.length; x++) {
            setLoading(true)
            const response = await deleteData(selected[x]);
            if (response?.status !== 200) {
                setErrMsg(`${response}`);
                setLoading(false)
            }
            if (response?.status === 200) {
                setLoading(false)
                let filteredArray = rowsData?.filter(item => item.id !== selected)
                setRowsData(filteredArray);
                window.location.reload(false);
            }

        }
    };

    async function removeTask(task) {
        var url = `${process.env.REACT_APP_API_URL}/tasks/${task.id}`;
        var method = `DELETE`;
        try {
            const response = axios(url, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.access_token}`,
                }
            })
            let arrCopy = rowsData.filter(row => row.id !== task.id)
            setRowsData([...arrCopy])
            return response;
        } catch (err) {
            setErrMsg(`There was an error. This task could not be removed.`)
        }
    }

    async function getData() {
        if (taskData) {
            var filteredRowDataTasks = [];
            taskData.forEach((key) => {
                filteredRowDataTasks.push({
                    id: key.id,
                    name: key.name,
                    project_id: key.project_id,
                    description: key.description,
                    percent_complete: key.percent_complete,
                    due_date: key.due_date,
                    priority: key.priority,
                    status: key.status,
                    category: key.category,
                    assigned_to: key.assigned,
                });
            })
            setRowsData([...filteredRowDataTasks]);
            var colsValsPrC = [
                {
                    field: 'name',
                    headerName: 'Name',
                    width: 200,
                    renderCell: (params) => (
                        <Link href={`/projects/${params.row?.project_id}/tasks/${params.row.id}`}>{params.value}</Link>
                    )
                },
                {
                    field: 'description',
                    headerName: 'Description',
                    width: 200,
                    cellClass: "normalLineHeight"
                },
                {
                    field: 'priority',
                    headerName: 'Priority',
                    width: 120,
                    renderCell: (params) => (
                        <div className={params.value}>{params.value}</div>
                    )
                },
                {
                    field: 'percent_complete',
                    headerName: 'Completed',
                    width: 100,
                    renderCell: (params) => (
                        <div className={params.value}>{params.value}%</div>
                    )
                },
                {
                    field: 'due_date',
                    headerName: 'Due date',
                    width: 100,
                    cellClass: "normalLineHeight"
                },
                {
                    field: 'id',
                    headerName: 'Action',
                    width: 200,
                    renderCell: (params) => (
                        <>
                            <Button startIcon={<EditIcon />} onClick={() => { setEditObj(params.row); setOpenAddDialog(true); }} />
                            <Button startIcon={<DeleteIcon />} onClick={() => { removeTask(params.row); }} />
                        </>
                    )

                },
            ];
            setColsData([...colsValsPrC]);
        }
    }

    useEffect(() => {
        if (rowsData === null) {
            getData();
        }
    });

    return (
        <div style={{ height: '75vh', width: '75vw', marginTop: '20px' }} className="wbs task list">
            <>
                {errMsg ? (
                    <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
                        <Alert severity="error">{errMsg}</Alert>
                    </Stack>
                ) : (
                    <></>
                )}
                <Button
                    variant="contained"
                    className="secondary btn"
                    startIcon={<DeleteIcon />}
                    disabled={!selected.length}
                    onClick={() =>
                        selected.length ? setOpenDialog("delete") : null
                    }
                >
                    Delete
                </Button>
                <Button
                    variant="outlined"
                    startIcon={<AddCircleIcon />}
                    onClick={() => { setOpenAddDialog(true); }}
                >
                    Add
                </Button>
                <DataGrid
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                    }
                    rows={rowsData ? rowsData : rows}
                    columns={colsData ? colsData : columns}
                    rowsPerPageOptions={[10]}
                    pageSize={10}
                    checkboxSelection
                    loading={loading}
                    components={{
                        LoadingOverlay: SpinnerComponent,
                    }}
                    onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                />
                <Dialog
                    //fullScreen
                    open={openAddDialog}
                    onClose={handleClose}
                    aria-labelledby="add-project-data"
                    aria-describedby="alert-dialog-add-project-data"
                >
                    <DialogActions className="create-edit-dialog">
                        <Button onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                    <DialogTitle id="alert-dialog-title">
                        {editObj ? `Edit Task` : `Add Task`}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <CreateEditProjectTask isWBSTaskList={true} isOpen={isOpen} controlData={addControlData} editDataObj={editObj} projId={editObj?.project_id} />
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={openDialog}
                    onClose={handleClose}
                    aria-labelledby="delete-confirm"
                    aria-describedby="alert-dialog-delete-confirm"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Delete?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this? This will also delete any associated risks, audit tests, evaluations, assessments and documents.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="create-edit-dialog">
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={() => { handleClose(); deleteSelectedRow(); }} autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        </div>
    )

}