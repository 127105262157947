import { styled, alpha } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const StyledTextField = styled(TextField)(({ theme, isvalid }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: isvalid === 'true' ? alpha(theme.palette.success.light, 0.1) : 'transparent',
    transition: theme.transitions.create(['background-color', 'border-color'], {
      duration: theme.transitions.duration.short,
    }),

    '&.Mui-focused fieldset': {
      borderColor: isvalid === 'true' ? theme.palette.success.dark : 
                   isvalid === 'false' ? theme.palette.error.dark : 
                   theme.palette.primary.main,
    },

    '& fieldset': {
      borderColor: isvalid === 'true' ? theme.palette.success.main : 
                   isvalid === 'false' ? theme.palette.error.main : 
                   theme.palette.grey[400],
    },
    '&:hover fieldset': {
      borderColor: isvalid === 'true' ? theme.palette.success.dark : 
                   isvalid === 'false' ? theme.palette.error.dark : 
                   theme.palette.grey[600],
    },
  },
  '& .MuiInputBase-input': {
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.short,
    }),
  },
  // label styling
  '& .MuiInputLabel-root': {
    color: isvalid === 'true' ? theme.palette.success.main : 
           isvalid === 'false' ? theme.palette.error.main : 
            theme.palette.grey[600],
  },

  // Style for when the label is shrunk
  '& .MuiInputLabel-shrink': {
    color: isvalid === 'true' ? theme.palette.success.main : 
           isvalid === 'false' ? theme.palette.error.main : 
           theme.palette.primary.main,
  },

  // Style for when the label is hovered
  '&:hover .MuiInputLabel-outlined.MuiInputLabel-shrink': {
    color: isvalid === 'true' ? theme.palette.success.dark : 
           isvalid === 'false' ? theme.palette.error.dark : 
           theme.palette.grey[600],
          },
}))

export default StyledTextField;