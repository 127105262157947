import React, { useEffect, useState, useCallback } from "react";
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import SpinnerComponent from '../LoadingSpinner';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import CreateEditProjectAuditTest from "../CreateEditProjectAuditTest";
import AddCircleIcon from '@mui/icons-material/AddCircle';

//This class displays lists of audit tests

export async function deleteData(audit) {
    var url = `${process.env.REACT_APP_API_URL}/audit_tests/${audit}`;

    const response = axios(url, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export async function loadData(projectId) {
    //call data endpoint for data type to set rows and columns
    var url = `${process.env.REACT_APP_API_URL}/projects/audit_tests/${projectId}`;
    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

const columns = [
    { field: 'id', headerName: 'id', width: 120 },
];

const rows = [
    { id: "n/a" },
];

export default function AuditTestList({
    projectId,
    auditListData,
}) {
    //axios error message
    const [errMsg, setErrMsg] = useState("");

    const [rowsData, setRowsData] = useState([]);
    const [colsData, setColsData] = useState(null);

    const [editObj, setEditObj] = useState(null);

    const [loading, setLoading] = useState(false);

    const [openAddDialog, setOpenAddDialog] = useState(null);

    const [selected, setSelected] = useState([]);

    const [openDialog, setOpenDialog] = useState(null);

    const [auditTestListData, setAuditTestListData] = useState(null);

    const [isSystemAdmin, setIsSystemAdmin] = useState(null);

    useEffect(() => {
        if (localStorage.getItem("system_role") === "4" ||
            localStorage.getItem("system_role") === "1" ||
            localStorage.getItem("system_role") === "2"
        ) {
            setIsSystemAdmin(true)
        } else {
            setIsSystemAdmin(false)
        }

    }, [setIsSystemAdmin])

    const onRowsSelectionHandler = (ids) => {
        setSelected([...ids]);
    };

    const handleClose = () => {
        setOpenAddDialog(false);
        setOpenDialog(false);
        setEditObj(null);
    }

    const isOpen = () => {
        //setEditObj(null);
        handleClose();
    }

    const addControlData = (data) => {
        var arrcopy = rowsData.filter(row => row.id !== data.id)
        var tmparray = [...arrcopy, data];
        setRowsData(tmparray);
    }

    async function deleteSelectedRow() {
        if (editObj) {
            const response = await deleteData(editObj.id);
            if (response?.status !== 200) {
                setErrMsg(`${response}`);
            }
            if (response?.status === 200) {
                let filteredArray = rowsData?.filter(item => item.id !== editObj.id)
                setRowsData(filteredArray);
            }
        }
        if (selected.length !== 0 && !editObj) {
            for (let x = 0; x < selected.length; x++) {
                const response = await deleteData(selected[x]);
                if (response?.status !== 200) {
                    setErrMsg(`${response}`);
                }
                if (response?.status === 200) {
                    let filteredArray = rowsData?.filter(item => item.id !== selected[x])
                    setRowsData(filteredArray);
                }
            }
        }

    };

    const getData = useCallback(async () => {
        setLoading(true)
        var response;
        if (!auditListData) {
            response = await loadData(projectId);
        }
        else {
            response = auditListData
        }
        setAuditTestListData(response)

        if (response) {
            var filteredRowDataAuditTests = [];
            response?.data?.forEach((key) => {
                filteredRowDataAuditTests.push({
                    id: key.id,
                    name: key.name,
                    description: key.description,
                    keywords: key.keywords,
                    approximate_time_to_complete: key.approximate_time_to_complete,
                    objective: key.objective,
                    expected_results: key.expected_results,
                    external_reference_id: key.external_reference_id,
                    status: key.status,
                    due_date: key.due_date,
                    stakeholders: key.stakeholders,
                    tester: key.tester,
                    test_frequency: key.test_frequency,
                    last_test_date: key.last_test_date,
                    project_control: key.project_control,
                });
            })

            setRowsData([...filteredRowDataAuditTests]);
            var colsVals = [
                {
                    field: 'name',
                    headerName: 'Name',
                    width: 200,
                    renderCell: (params) => (
                        <Link href={`/projects/${projectId}/audit_tests/${params.row.id}`}>{params.value}</Link>
                    )
                },
                {
                    field: 'objective',
                    headerName: 'Objective',
                    width: 200,
                    cellClass: "normalLineHeight"
                },
                {
                    field: 'expected_results',
                    headerName: 'Expected Results',
                    width: 100,
                    cellClass: "normalLineHeight"
                },
                {
                    field: 'status',
                    headerName: 'Status',
                    width: 100,
                    cellClass: "normalLineHeight"
                },
                {
                    field: 'due_date',
                    headerName: 'Due Date',
                    width: 100,
                    cellClass: "normalLineHeight"
                },
                {
                    field: 'action',
                    headerName: 'Action',
                    width: 200,
                    renderCell: (params) => (
                        <>
                            <Button startIcon={<EditIcon />} onClick={() => { setEditObj(params.row); setOpenAddDialog(true) }} />
                            <Button startIcon={<DeleteIcon />} onClick={() => { setEditObj(params.row); setOpenDialog(true) }} />
                        </>
                    )

                },
            ];
            setColsData([...colsVals]);
        }
        setLoading(false)
    }, [projectId, auditListData])

    useEffect(() => {
        if (auditTestListData === null) {
            getData();
        }
    }, [getData, auditTestListData]);

    useEffect(() => {
        //console.log(rowsData)
    }, [rowsData])


    useEffect(() => {
        //console.log(colsData)
    }, [colsData])

    useEffect(() => {
        console.log('auditListData', auditListData)
    }, [auditListData])

    return (
        <div style={{ marginTop: '0px' }}>
            <>
                {errMsg ? (
                    <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
                        <Alert severity="error">{errMsg}</Alert>
                    </Stack>
                ) : (
                    <></>
                )}
                {isSystemAdmin && <div className="btns-top-list">
                    <Button
                        variant="contained"
                        className="delete secondary btn"
                        startIcon={<DeleteIcon />}
                        disabled={!selected.length}
                        onClick={() =>
                            selected.length ? setOpenDialog("delete") : null
                        }
                    >
                        Delete
                    </Button>
                    <Button
                        variant="outlined"
                        startIcon={<AddCircleIcon />}
                        onClick={() => { setOpenAddDialog(true); }}
                        className="addBtn"
                    >Add</Button>
                </div>}

                <div style={{ height: '600px', width: '100%' }}>
                    <DataGrid
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        rows={rowsData ? rowsData : rows}
                        columns={colsData ? colsData : columns}
                        rowsPerPageOptions={[10]}
                        pageSize={10}
                        checkboxSelection
                        loading={loading}
                        components={{
                            LoadingOverlay: SpinnerComponent,
                        }}
                        onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                        getRowHeight={() => 'auto'}
                    />
                </div>
                <Dialog
                    //fullScreen
                    open={openAddDialog ? openAddDialog : false}
                    onClose={handleClose}
                    aria-labelledby="add-project-data"
                    aria-describedby="alert-dialog-add-project-data"
                >
                    <DialogActions className="create-edit-dialog">
                        <Button onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                    <DialogTitle id="alert-dialog-title">
                        {editObj ? `Edit Audit Test` : `Add Audit Test`}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <CreateEditProjectAuditTest isOpen={isOpen} editDataObj={editObj} controlData={addControlData} projectId={projectId} />
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={openDialog ? openDialog : false}
                    onClose={handleClose}
                    aria-labelledby="delete-confirm"
                    aria-describedby="alert-dialog-delete-confirm"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Delete?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this? This will also delete any associated risks, tasks and documents.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="create-edit-dialog">
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={() => { handleClose(); deleteSelectedRow(); }} autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        </div>
    )

}