import React, { useEffect, useState, useCallback, useMemo } from "react";
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import "../css/RiskList.css";
import SpinnerComponent from './LoadingSpinner';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import '../css/RiskList.css'
import { useForm, Controller } from "react-hook-form";
import { MenuItem, Select } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//This class allows multiple controls to be added to a framework version or a project

export async function getFrameworkData() {
    var url = `${process.env.REACT_APP_API_URL}/frameworks/?is_enabled=true&offset=0&limit=100`
    const response = await axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export async function getFrameworkVersionData(frameworkId) {
    var url = `${process.env.REACT_APP_API_URL}/framework_versions/?framework_id=${frameworkId}&sort_by=name&offset=0&limit=100`
    const response = await axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export async function addProjectControls (selected, projectId){
    var url = `${process.env.REACT_APP_API_URL}/projects/add_controls/${projectId}`
    var controls = []
    for (var x = 0; x < selected.length; x++) {
        const currentControl = {
            "project_id": parseInt(projectId),
            "control_id": parseInt(selected[x])
        }
        controls.push(currentControl)
    }
    const response = await axios(url, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
        data: {
            "controls": controls   
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export async function addFrameworkVersionControls(selected, frameworkVersId) {
    var url = `${process.env.REACT_APP_API_URL}/controls/add_controls/${frameworkVersId}`
    var controls = []
    for (var x = 0; x < selected.length; x++) {
        const currentControl = {
            "control_id": parseInt(selected[x]),
            "framework_version_id": parseInt(frameworkVersId)
        }
        controls.push(currentControl)
    }
    const response = await axios(url, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
        data: {
            "controls": controls   
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

const columns = [
    { field: 'id', headerName: 'id', width: 120 },
];

const rows = [
    { id: "n/a" },
];

export default function MultipleControlSelect({
    projectId,
    isOpen,
    rowsReturnData,
    frameworkVersId
}) {
    // useEffect(() => {
    // }, [controlListData])
    //axios error message
    const [errMsg, setErrMsg] = useState("");

    const [rowsData, setRowsData] = useState([]);
    const [colsData, setColsData] = useState(null);

    //const [loading, setLoading] = useState(false);

    const [selected, setSelected] = useState([]);

    const [frameworks, setFrameworks] = useState([]);
    const [frameworkVersions, setFrameworkVersions] = useState([]);

    const [frameworkId, setFrameworkId] = useState();
    const [frameworkVersionId, setFrameworkVersionId] = useState();

    const [openDialog, setOpenDialog] = useState(null);

    const onRowsSelectionHandler = (ids) => {
        setSelected([...ids]);
    };

    useEffect(() => {
    }, [selected])

    const selectOption = useMemo(() => { return { value: "0", label: "Please select..." } }, [])

    const handleClose = () => {
        setOpenDialog(false);
        //setEditObj(null);
    }

    const getSelected = () => {
        return selected;
    }


    // const addControlData = (data) => {
    //     var arrcopy = rowsData.filter(row => row.id !== data.id)
    //     var tmparray = [...arrcopy, data];
    //     setRowsData(tmparray);
    //     getData();
    // }

    const { control, handleSubmit } = useForm({
        defaultValues: {
            framework: {},
            frameworkVersion: {},
        }
    });

    const getDropDownOptions = useCallback(async () => {
        const jsonData = await getFrameworkData();
        if (jsonData === 'Request failed with status code 401') {
            localStorage.clear();
            window.location.reload(false);
        }
        if (jsonData?.data?.items){
            const frameworkOptions = jsonData.data.items.map((framework) => ({
                value: framework.id,
                label: framework.name,
            }));
            setFrameworks([selectOption, ...frameworkOptions])
        }

    }, [selectOption])

    const addSelectedControls = useCallback(async (selected) => {
        if (selected.length > 0) {
            var jsonData ;
            if (projectId) {
                jsonData = await addProjectControls(selected, projectId);
            }
            if (frameworkVersId) {
                jsonData = await addFrameworkVersionControls(selected, frameworkVersId);
            }
            if (jsonData === 'Request failed with status code 401') {
                localStorage.clear();
                window.location.reload(false);
            }
            var controls = []
            if (projectId) {
                jsonData.data.forEach((item) => {
                    var curItem = {
                        id: item.id,
                        name: item.control.name,
                        description: item.control.description,
                        exception: item.exception,
                        mitigation_percentage: item.mitigation_percentage,
                        control_class_id: item.control_class_id,
                        control_family_id: item.control_family_id,
                        control_phase_id: item.control_phase_id,
                        control_status_id: item.control_status_id,
                        assessment:item.assessment,
                    }
                    controls.push(curItem)
                })
            }
            if (frameworkVersId) {
                console.log(jsonData)
                jsonData.data.forEach((item) => {
                    var curItem = {
                        id: item.id,
                        name: item.name,
                        description: item.description,
                    }
                    controls.push(curItem)
                })
            }

            rowsReturnData(controls)
            isOpen(false)
        } else {
            alert(`You haven't selected any controls to add`)
        }

    }, [isOpen, rowsReturnData, frameworkVersId, projectId])

    function cutString(s, n) {
        var cut = s.indexOf(' ', n);
        if (cut === -1) return s;
        return s.substring(0, cut)
    }

    const loadData = useCallback(async(frameworkVersionId) => {
        //call data endpoint for data type to set rows and columns
        var resp;
        var url;
        if (projectId) {
            url = `${process.env.REACT_APP_API_URL}/projects/project_controls/${projectId}/framework_versions/${frameworkVersionId}`;
        }
        if (frameworkVersId) {
            url = `${process.env.REACT_APP_API_URL}/framework_versions/${frameworkVersionId}`
        }

        return fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.access_token}`,
            },
        }).then(response => {
            resp = response;
            return response.json();
        }).then(json => {
            return {
                response: resp,
                json: json,
                error: !resp.ok
            };
        });
    }, [projectId, frameworkVersId])

    const getData = useCallback(async(frameworkVersionId) => {
        const controlListData = await loadData(frameworkVersionId);
        if (controlListData?.error) {
            setErrMsg(`An error occurred`)
        }
        if (controlListData?.json) {
            var filteredRowDataControls = [];
            if (projectId) {
                controlListData?.json?.forEach((key) => {
                    filteredRowDataControls.push({
                        id: key.id,
                        name: key.name,
                        description: cutString(key.description, 80) + '...',
                        used: key.used,
                    });
                })
    
                setRowsData([...filteredRowDataControls]);
            }
            if (frameworkVersId) {
                controlListData?.json?.controls?.forEach((key) => {
                    filteredRowDataControls.push({
                        id: key.id,
                        name: key.name,
                        description: cutString(key.description, 80) + '...',
                        used: false,
                    });
                })
    
                setRowsData([...filteredRowDataControls]);
            }

            var colsVals = [
                {
                    field: 'name',
                    headerName: 'Name',
                    width: 200,
                    renderCell: (params) => (
                        <Link href={`/frameworks/${frameworkId}/controls/${params.row.id}`}>{params.value}</Link>
                    )
                },
                {
                    field: 'description',
                    headerName: 'Description',
                    width: 400,
                    cellClass: "normalLineHeight"
                },
                {
                    field: 'used',
                    headerName: 'Already in use',
                    width: 200,
                    renderCell: (params) => (
                        <>{params?.row?.used ? <><Checkbox defaultChecked disabled /></> : <></>}</>
                    )
                },
            ];
            setColsData([...colsVals]);
        }
    }, [frameworkId, loadData, frameworkVersId, projectId])

    useEffect(() => {
        getDropDownOptions();
    }, [getDropDownOptions]);


    // useEffect(() => {
    //     if (projectControlListData && rowsData.length === 0) {
    //         getData();
    //     }
    //     if (projectControlListData.length === 0) {
    //         setRowsData([])
    //         setColsData([])
    //     }
    // }, [projectControlListData]);

    useEffect(() => {
        //console.log(frameworkVersionId)
    }, [frameworkVersionId])

    const handleChangeFrameworkSelect = useCallback(async (event) => {
        setFrameworkId(event.target.value)
        //getframework version dropdowns
        const jsonData = await getFrameworkVersionData(event.target.value)
        //getData(event.target.value);
        if (jsonData === 'Request failed with status code 401') {
            localStorage.clear();
            window.location.reload(false);
        }
        const frameworkOptions = jsonData.data.items.map((framework) => ({
            value: framework.id,
            label: framework.version_prefix+" "+framework.framework.name+" "+framework.version_suffix,
        }));
        
        if (projectId) {
            setFrameworkVersions([selectOption, ...frameworkOptions])
        }
        if (frameworkVersId) {
            //filter to remove current framework version
            const filteredOptions = frameworkOptions.filter((item) => item.value !== frameworkVersId)
            setFrameworkVersions([selectOption, ...filteredOptions])
        }
    }, [selectOption, frameworkVersId, projectId]);

    const handleChangeFrameworkVersionSelect = useCallback(async (event) => {
        setFrameworkVersionId(event.target.value)
        getData(event.target.value)
    }, [getData]);

    return (
        <div style={{ marginTop: '0px' }} className="multiple control select project">
            <form onSubmit={handleSubmit()}>
                <div className="form-field framework">
                    <label>Select a Framework</label>
                    <Controller
                        name="framework"
                        control={control}
                        defaultValue={"0"}
                        render={({ field }) =>
                            <Select
                                {...field}
                                onChange={handleChangeFrameworkSelect}
                                value={frameworkId ? frameworkId : "0"}
                                defaultValue={"0"}
                                inputProps={{ "data-testid": "framework" }}
                            >
                                {
                                    frameworks?.map((framework) => {
                                        return <MenuItem
                                            value={framework.value}
                                            key={framework.value}>
                                            {framework.label}
                                        </MenuItem>
                                    })
                                }
                            </Select>
                        }
                    />
                </div>
                <div className="form-field version">
                    <label>Select a Version</label>
                    <Controller
                        name="frameworkVersion"
                        control={control}
                        defaultValue={"0"}
                        render={({ field }) =>
                            <Select
                                {...field}
                                onChange={handleChangeFrameworkVersionSelect}
                                defaultValue={"0"}
                                value={frameworkVersionId ? frameworkVersionId : "0"}
                            >
                                {
                                    frameworkVersions?.map((framework) => {
                                        return <MenuItem
                                            value={framework.value}
                                            key={framework.value}>
                                            {framework.label}
                                        </MenuItem>
                                    })
                                }
                            </Select>
                        }
                    />
                </div>
            </form>
            <>
                {errMsg ? (
                    <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
                        <Alert severity="error">{errMsg}</Alert>
                    </Stack>
                ) : (
                    <></>
                )}
                <Button
                    startIcon={<AddIcon />}
                    onClick={() => { setOpenDialog(true); }}
                >Add selected controls</Button>
                <div className="list" style={{ height: '900px', width: '100%' }}>
                    <DataGrid
                        rows={rowsData ? rowsData : rows}
                        columns={colsData ? colsData : columns}
                        //pageSize={10}
                        rowsPerPageOptions={[10]}
                        pageSize={10}
                        isRowSelectable={(params) => params.row.used === false}
                        checkboxSelection
                        //loading={loading}
                        components={{
                            LoadingOverlay: SpinnerComponent,
                        }}
                        onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                        getRowHeight={() => 'auto'}
                    />
                </div>
            </>
            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="delete-confirm"
                aria-describedby="alert-dialog-add-confirm"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Add?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to add these controls?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="create-edit-dialog">
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => { addSelectedControls(getSelected());handleClose(); }} autoFocus>
                        Add Controls
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )

}