import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import '../css/Login.css';
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField"
import Box from '@mui/material/Box';
import Loader from './Loader';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Logo from '../assets/fedrisk-full-logo.png';

export async function loginUser(email, password) {
  /*setValidEmail(EMAIL_REGEX.test(email))
  setValidPwd(PWD_REGEX.test(password))
  if (!validEmail || !validPwd) {
    setErrMsg("Invalid Entry");
    return;
  }*/
    var method = "POST";
    var url = `${process.env.REACT_APP_API_URL}/users/get-auth-token`
    const response = axios(url, {
        method: method,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify({
          email: email,
          password: password,
        })
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export default function Login({ setToken }) {
  //const EMAIL_REGEX = useMemo(() => { return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ }, [])
  //const PWD_REGEX = useMemo(() => { return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/ }, [])

  const emailRef = useRef();
  //const [validEmail, setValidEmail] = useState(false);
  //const [validPwd, setValidPwd] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    emailRef.current.focus();
  }, []);


  useEffect(() => {
  }, [errMsg])

  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    const response = await loginUser(email, password);
    if (response?.data?.access_token) {
      setToken(response.data.access_token);
      localStorage.setItem("access_token", response.data.access_token);
      localStorage.setItem("userid", response.data.user.id);
      localStorage.setItem("email", response.data.user.email);
      localStorage.setItem("tenant_id", response.data.user.tenant.id);
      localStorage.setItem("system_role", response.data.user.system_role);
      localStorage.setItem("system_role_name", response.data.user.system_role_name);
      localStorage.setItem("is_superuser", response.data.user.is_superuser);
      localStorage.setItem("is_active", response.data.user.status);
      localStorage.setItem("menu_open", true)
      setEmail("");
      setPassword("");
    } else {
      if (response === 'Request failed with status code 401') {
        setErrMsg('Incorrect password or email')
      } else {
        setErrMsg(response)
      }
    }
    setLoading(false);
  }

  if (loading) {
    return (
      <Loader />
    )
  }

  return (
    <div className="login-wrapper">
      {errMsg ? (
        <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
          <Alert severity="error">{errMsg}</Alert>
        </Stack>
      ) : (
        <></>
      )}
      <Box
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
      >
        <img src={Logo} alt="Logo" width="481px" />
        <h1>Sign In to Fedrisk</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <TextField type="email" ref={emailRef} required id="email" value={email} label="Email" onChange={e => setEmail(e.target.value)}
              style={{
                width: 500
              }}
              inputProps={{ maxLength: 50 }}
            />
          </div>
          <div>
            <TextField type="password" required label="Password" id="password" value={password} onChange={e => setPassword(e.target.value)}
              style={{
                width: 500
              }}
              inputProps={{ maxLength: 255 }}
            />
          </div>
          <div>
            <div className="forgot-password"><a href="/forgot-password">Forgot Password?</a></div>
            <Button className="sign-in" type="submit" variant="contained">Sign In</Button>
          </div>
          <div className="register-here">Don't have an account? <a href="/register">Register Here</a></div>
        </form>
      </Box>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};