import React, { useEffect, useState, useCallback, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import { MenuItem, Select } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DatePicker from "react-multi-date-picker";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import axios from 'axios';

// Facilitates project control exceptions creation and editing

export async function getKeywords() {
    const url = `${process.env.REACT_APP_API_URL}/keyword/`
    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export async function getUsers() {
    const url = `${process.env.REACT_APP_API_URL}/users/get_users_tenant`
    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export default function CreateEditProjectControlException({ editDataObj, isOpen, controlData }) {
    console.log('editDataObj', editDataObj)
    //default option when no value selected in drop down
    const selectOption = useMemo(() => { return { value: "0", label: "Please select..." } }, [])
    const userSelectOption = useMemo(() => { return { id: 0, email: "Please select..." } }, [])
    const [stakeholderId, setStakeholderId] = useState(editDataObj?.stakeholder?.id);
    const [stakeholders, setStakeholders] = useState([]);
    const [frequencyId, setFrequencyId] = useState(editDataObj?.exception?.review_frequency);
    const [statusId, setStatusId] = useState(editDataObj?.exception?.review_status);
    const [ownerId, setOwnerId] = useState(editDataObj?.exception?.owner?.id);
    //used with keywords
    const [users, setUsers] = useState([]);

    const [keywords, setKeywords] = useState([{ id: "0", name: "Please select..." }]);;

    const [keywordSelections, setKeywordSelections] = useState([]);

    const [newKeywords, setNewKeywords] = useState("")

    const [keywordId, setKeywordId] = useState();
    const keywordSelectOption = useMemo(() => { return { id: "0", name: "Please select..." } }, [])

    const [errMsg, setErrMsg] = useState("");

    useEffect(() => {
        if (editDataObj?.keywords) {
            let options = []
            for (let i = 0; i < editDataObj?.keywords.length; i++) {
                options.push({ id: editDataObj?.keywords[i].id, name: editDataObj?.keywords[i].keyword.name })
            }
            setKeywordSelections(options)
        }
    }, [editDataObj])

    const { control, handleSubmit } = useForm({
        defaultValues: {
            stakeholderSelect: {},
            ownerSelect: {},
            frequencySelect: {},
            statusSelect: {},
            justification: editDataObj?.exception?.justification,
            description: editDataObj?.exception?.description,
            reviewDate: editDataObj?.exception?.next_review_date,
            keywordSelect: {},
        }
    });

    const removeKeyword = (key) => {
        var tmpfilter = keywordSelections.filter(keyword => keyword.id !== key.id)
        setKeywordSelections(tmpfilter);
        var keyword = keywordSelections.filter(word => word.id === key.id)
        var name = keyword[0].name
        setKeywords(keywords => [...keywords, { name: name, id: key.id }]);
    }

    const handleChangeKeywordSelect = (event) => {
        setKeywordId(event.target.value);
        var keyword = keywords.filter(word => word.id === event.target.value)
        var name = keyword[0].name
        setKeywordSelections(keywordSelections => [...keywordSelections, { name: name, id: event.target.value }]);
        var tmpfilterSelect = keywords.filter(keyword => keyword.id !== event.target.value)
        setKeywords(tmpfilterSelect)
    }

    const handleChangeNewKeywords = (event) => {
        setNewKeywords(event.target.value)
    }

    const frequency = [
        selectOption,
        { value: "Daily", label: "Daily" },
        { value: "Weekly", label: "Weekly" },
        { value: "Monthly", label: "Monthly" },
        { value: "Quarterly", label: "Quarterly" },
    ]
    const status = [
        selectOption,
        { value: "Not Started", label: "Not Started" },
        { value: "In Progress", label: "In Progress" },
        { value: "Completed", label: "Completed" },
    ]

    const handleChangeFrequencySelect = (event) => {
        setFrequencyId(event.target.value);
    }

    const handleChangeStatusSelect = (event) => {
        setStatusId(event.target.value);
    }

    const handleChangeStakeholderSelect = (event) => {
        setStakeholderId(event.target.value);
        var useremail = users.filter(user => user.id === event.target.value)
        var email = useremail[0].email
        setStakeholders(stakeholders => [...stakeholders, { email: email, id: event.target.value }]);
    }

    const removeStakeholder = (stake) => {
        var tmpfilter = stakeholders.filter(stakeholder => stake.id !== stakeholder.id)
        setStakeholders(tmpfilter);
    }

    const handleChangeOwnerSelect = (event) => {
        setOwnerId(event.target.value);
    }

    const convertToSendDate = (date) => {
        return `${new Date(date).toISOString().split("T")[0]}`;
    };

    async function postFormData(data) {
        if (!/^[a-z,]*$/.test(newKeywords)) {
            setErrMsg(`Sorry your keywords contain values other than lowercase characters and commas`)
        } else {
            //clean up duplicate vals
            var cleanedKeywords = []
            var enteredKeywords = newKeywords.split(',')
            for (let x = 0; x < enteredKeywords.length; x++) {
                let match = false
                for (let y = 0; y < keywordSelections.length; y++) {
                    if (enteredKeywords[x] === keywordSelections[y].name) {
                        match = true
                    }
                }
                if (match === false) {
                    cleanedKeywords.push(enteredKeywords[x])
                }
            }
            for (let p = 0; p < keywordSelections.length; p++) {
                cleanedKeywords.push(keywordSelections[p].name)
            }
            var cleanedWords = cleanedKeywords.join(',')
            var url;
            var body;
            var method = "POST";
            var stakeholderidarr = [];
            for (let i = 0; i < stakeholders.length; i++) {
                stakeholderidarr.push(stakeholders[i].id)
            }
            /*if (editDataObj?.stakeholders) {
              for (let i= 0; i < editDataObj?.stakeholders?.length; i ++) {
                stakeholderidarr.push(parseInt(editDataObj.stakeholders[i].id))
              }
            }*/
            var removenullids = []
            for (let p = 0; p < stakeholderidarr.length; p++) {
                if (stakeholderidarr[p] !== null) {
                    removenullids.push(parseInt(stakeholderidarr[p]))
                }
            }
            //format timestamps to date string
            let reviewdate = convertToSendDate(data.reviewDate)
            url = `${process.env.REACT_APP_API_URL}/exceptions?keywords=${cleanedWords}`
            if (!data?.description || data?.description === "") {
                data.description = "N/A"
            }
            if (!data?.justification || data?.justification === "") {
                data.justification = "N/A"
            }
            body = {
                description: data?.description,
                justification: data?.justification,
                keywords: data?.keywords,
                next_review_date: reviewdate,
                owner_id: ownerId,
                stakeholder_ids: removenullids,
                review_frequency: frequencyId,
                review_status: statusId,
                project_control_id: editDataObj?.id,
            }
            console.log(body)
            //if a data object is being edited
            if (editDataObj?.exception) {
                method = "PUT";
                url = `${process.env.REACT_APP_API_URL}/exceptions/${editDataObj?.exception?.id}?keywords=${cleanedWords}`;
            }

            const response = axios(url, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.access_token}`,
                },
                data: body,
            }).then(result => {
                return result;
            }).catch(error => { return error.message; })
            return response;
        }
    }

    /* form submit */
    const onSubmit = async (data) => {
        //var datastore = data;
        const response = await postFormData(data);
        //datastore.id = response.data.id;
        controlData(response.data);
        isOpen(false);
    }

    const getDropDownOptions = useCallback(async () => {
        const jsonDataKey = await getKeywords();
        if (jsonDataKey?.data != null) {
            const tmpoptionskeys = jsonDataKey.data.map((word) => ({
                id: word.id,
                name: word.name,
            }));
            var allOptions = [keywordSelectOption, ...tmpoptionskeys];
        }
        setKeywords(allOptions);
        const jsonDataUsers = await getUsers();
        if (jsonDataUsers?.data != null) {
            const tmpoptions = jsonDataUsers.data.map((user) => ({
                id: user.id,
                email: user.email,
            }));
            var allUserOptions = [userSelectOption, ...tmpoptions];
            setUsers(allUserOptions);
        }
    }, [userSelectOption, keywordSelectOption])


    useEffect(() => {
        getDropDownOptions();
    }, [getDropDownOptions]);

    useEffect(() => {
        if (editDataObj?.exception?.stakeholders) {
            setStakeholders(editDataObj?.exception?.stakeholders)
        }
    }, [editDataObj])

    useEffect(() => {

    }, [stakeholderId, stakeholders]);

    return (
        <div className="create edit exception">
            {errMsg ? (
                <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
                    <Alert severity="error">{errMsg}</Alert>
                </Stack>
            ) : (
                <></>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-field description">
                    <Controller
                        name="description"
                        control={control}
                        render={({ field }) =>
                            <TextField
                                {...field}
                                multiline
                                rows={4}
                                label="Description"
                                fullWidth
                            />
                        }
                    />
                </div>
                <div className="form-field justification">
                    <Controller
                        name="justification"
                        control={control}
                        render={({ field }) =>
                            <TextField
                                {...field}
                                multiline
                                rows={4}
                                label="Justification"
                                fullWidth
                            />
                        }
                    />
                </div>
                <div className="form-field keywords">
                    <div className="form-field control">
                        <label>Existing Keywords</label>
                        <Controller
                            name="keywordSelect"
                            control={control}
                            defaultValue={0}
                            render={({ field }) =>
                                <Select
                                    {...field}
                                    value={keywordId ? keywordId : 0}
                                    defaultValue={0}
                                    onChange={handleChangeKeywordSelect}
                                >
                                    {
                                        keywords?.map((word) => {
                                            return <MenuItem
                                                value={word.id}
                                                key={word.id}>
                                                {word.name}
                                            </MenuItem>
                                        })
                                    }
                                </Select>
                            }
                        />
                    </div>
                    <ul>
                        {(keywordSelections && (
                            keywordSelections.map((word) => {
                                return <li>
                                    {word.name}
                                    <IconButton aria-label="delete">
                                        <DeleteIcon onClick={() => { removeKeyword(word); }} />
                                    </IconButton>
                                </li>
                            })
                        )
                        )}
                    </ul>
                    <div className="form-field newkeywords">
                        <TextField
                            label="Add new keywords"
                            onChange={handleChangeNewKeywords}
                            value={newKeywords}
                            //fullWidth
                            helperText="Enter your keyword in all lowercase with a comma and no spaces between each one e.g. legal,technical"
                        />
                    </div>
                </div>
                <div className="form-field control">
                    <label>Frequency</label>
                    <Controller
                        name="frequencySelect"
                        control={control}
                        defaultValue={0}
                        render={({ field }) =>
                            <Select
                                {...field}
                                value={frequencyId ? frequencyId : 0}
                                defaultValue={0}
                                onChange={handleChangeFrequencySelect}
                            >
                                {
                                    frequency?.map((f) => {
                                        return <MenuItem
                                            value={f.value}
                                            key={f.value}>
                                            {f.label}
                                        </MenuItem>
                                    })
                                }
                            </Select>
                        }
                    />
                </div>
                <div className="form-field control">
                    <label>Status</label>
                    <Controller
                        name="statusSelect"
                        control={control}
                        defaultValue={0}
                        render={({ field }) =>
                            <Select
                                {...field}
                                value={statusId ? statusId : 0}
                                defaultValue={0}
                                onChange={handleChangeStatusSelect}
                            >
                                {
                                    status?.map((s) => {
                                        return <MenuItem
                                            value={s.value}
                                            key={s.value}>
                                            {s.label}
                                        </MenuItem>
                                    })
                                }
                            </Select>
                        }
                    />
                </div>
                <div className="form-field control">
                    <label>Stakeholders</label>
                    <Controller
                        name="stakeholdersSelect"
                        control={control}
                        defaultValue={0}
                        render={({ field }) =>
                            <Select
                                {...field}
                                value={stakeholderId ? stakeholderId : 0}
                                defaultValue={0}
                                onChange={handleChangeStakeholderSelect}
                            >
                                {
                                    users?.map((user) => {
                                        return <MenuItem
                                            value={user.id}
                                            key={user.id}>
                                            {user.email}
                                        </MenuItem>
                                    })
                                }
                            </Select>
                        }
                    />
                </div>
                <ul>
                    {(stakeholders && (
                        stakeholders.map((stakeholder) => {
                            return <li>
                                {stakeholder.email}
                                <IconButton aria-label="delete">
                                    <DeleteIcon onClick={() => { removeStakeholder(stakeholder); }} />
                                </IconButton>
                            </li>
                        })
                    )
                    )}
                </ul>
                <div className="form-field control">
                    <label>Owner</label>
                    <Controller
                        name="ownerSelect"
                        control={control}
                        defaultValue={0}
                        render={({ field }) =>
                            <Select
                                {...field}
                                onChange={handleChangeOwnerSelect}
                                defaultValue={0}
                                value={ownerId ? ownerId : 0}
                            >
                                {
                                    users?.map((user) => {
                                        return <MenuItem
                                            value={user.id}
                                            key={user.id}>
                                            {user.email}
                                        </MenuItem>
                                    })
                                }
                            </Select>
                        }
                    />
                </div>
                <div className="form-field date">
                    <label>Review Date</label>
                    <Controller
                        control={control}
                        name="reviewDate"
                        rules={{ required: true }} //optional
                        render={({
                            field: { onChange, name, value },
                            //fieldState: { invalid, isDirty }, //optional
                            formState: { errors }, //optional, but necessary if you want to show an error message
                        }) => (
                            <>
                                <DatePicker
                                    value={value || ""}
                                    onChange={(date) => {
                                        onChange(date?.isValid ? date : "");
                                    }}
                                />
                                {errors && errors[name] && errors[name].type === "required" && (
                                    //if you want to show an error message
                                    <span>The review date is required!</span>
                                )}
                            </>
                        )}
                    />
                </div>
                <div>
                    {
                        <Button type="submit" variant="contained" >Submit</Button>
                    }
                </div>
            </form>
        </div>
    )
}