import React, { useEffect, useState } from "react";
import '../css/Register.css';
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField"
import Box from '@mui/material/Box';
import Loader from './Loader';
import axios from 'axios';
import Logo from '../assets/fedrisk-full-logo.png';


export default function ForgotPasswordConfirm() {

    const [email, setEmail] = useState("");
    const [confirmcode, setConfirmCode] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
    }, [email]);

    const handleSubmit = async e => {
        setLoading(true);
        e.preventDefault();
        const response = await forgotPassword();

        if (response !== 'Request failed with status code 404') {
            setLoading(false);
        }

    }
    async function forgotPassword() {
        var method = "POST";
        var url = `${process.env.REACT_APP_API_URL}/users/confirm_reset_password`
        var body = JSON.stringify(
            {
                email: email,
                confirmation_code: confirmcode,
                password: password,
            })
        const response = axios(url, {
            method: method,
            headers: {
                "Content-Type": "application/json",
            },
            data: body,
        }).then(result => {
            alert(`Your password has been reset`)
            window.location.replace("/")
            return result
        }).catch(error => { return error.message; })
        return response
    }

    if (loading) {
        return (
            <Loader />
        )
    }

    return (

        <div className="register-wrapper confirm">
            <Box
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '100%' },
                }}
            >
                <img src={Logo} alt="Logo" width="481px" />
                <h1>Forgot Password</h1>

                <form onSubmit={handleSubmit}>
                    <div>
                        <TextField type="email" required id="email" value={email} label="Email" onChange={e => setEmail(e.target.value)}
                            style={{
                                width: 500
                            }} />
                    </div>
                    <div>
                        <TextField type="text" required id="confirmcode" value={confirmcode} label="Confirmation Code" onChange={e => setConfirmCode(e.target.value)}
                            style={{
                                width: 500
                            }} />
                    </div>
                    <div>
                        <TextField type="password" required id="password" value={password} label="New Password" onChange={e => setPassword(e.target.value)}
                            style={{
                                width: 500
                            }} />
                    </div>
                    <div>
                        <Button className="sign-in" type="submit" variant="contained">Reset Password</Button>
                    </div>
                    <div>

                    </div>
                </form>
                <div className="login-here">Already have an account? <a href="/">Log In</a></div>
            </Box>
        </div>
    )
}