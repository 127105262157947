import React, { useState, useEffect } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CreateEdit from '../components/CreateEditProjectTask';
import SpinnerComponent from '../components/LoadingSpinner';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

/* This is a view for returning details on a project task  */
export async function loadData(id) {
  //call data endpoint for data type to set rows and columns
  var url = `${process.env.REACT_APP_API_URL}/tasks/${id}`;

  const response = axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProjectTask({ id }) {
  const [data, setData] = useState([]);

  const [editOpen, setEditOpen] = useState(false);

  const [isSystemAdmin, setIsSystemAdmin] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("system_role") === "4" || localStorage.getItem("system_role") === "1" || localStorage.getItem("system_role") === "2") {
      setIsSystemAdmin(true)
    }
  }, [])

  useEffect(() => {
  }, [isSystemAdmin])

  //axios error message
  const [errMsg, setErrMsg] = useState("");
  useEffect(() => {
  }, [errMsg])

  const handleClose = () => {
    setEditOpen(false);
  }

  const EditData = () => {
    setEditOpen(true);
  }

  const isOpen = () => {
    handleClose();
  }

  const addControlData = (data) => {
    getData();
  }

  async function getData() {
    const jsonData = await loadData(id);
    if (jsonData !== "Request failed with status code 404" && jsonData !== "Network Error") {
      setData(jsonData.data);

    } else {
      setErrMsg(`${jsonData}`)
    }
  }

  useEffect(() => {
    if (data.length === 0) {
      getData();
    }

  });

  return (
    <>
      {errMsg ? (
        <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
          <Alert severity="error">{errMsg}</Alert>
        </Stack>
      ) : (
        <></>
      )}
      <div className="details-view projectTask">
        {(!data && <SpinnerComponent />)}
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/system-administration">
            System Administration
          </Link>
          <Link underline="hover" color="inherit" href={`/projects/list`}>
            Projects
          </Link>
          <Link underline="hover" color="inherit" href={`/projects/${data?.project?.id}`}>
            {data?.project?.name}
          </Link>
          <Link underline="hover" color="inherit" href={`/projects/${data?.project?.id}/tasks`}>
            Tasks
          </Link>
          <Typography color="text.primary" component={'div'}>{data?.name}</Typography>
        </Breadcrumbs>
        {isSystemAdmin ? <Button variant="contained" onClick={EditData}>Edit</Button> : <></>}
        <>
          <h2>Name:</h2>
          <p>{data?.name ? data.name : <p>N/A</p>}</p>
          <h2>Description:</h2>
          <p>{data?.description ? data.description : <p>N/A</p>}</p>
          <h2>Project:</h2>
          <p>
            <Link href={`/projects/${data?.project?.id}`}>{data?.project?.name}
            </Link>
          </p>
          <h2>Assigned to:</h2>
          <p>
            <Link href={`/users/${data?.user?.id}`}>{data?.user?.email}
            </Link>
          </p>
          <h2>Due date:</h2>
          <p>{data?.due_date ? data.due_date : <p>N/A</p>}</p>
          <h2>Status:</h2>
          <p>{data?.status ? data.status : <p>N/A</p>}</p>
          <h2>Priority:</h2>
          <p>{data?.priority ? data.priority : <p>N/A</p>}</p>
          {data?.risks?.length > 0 &&
            <>
              <h2>Risks:</h2>
              <ul>
                {(data?.risks && (
                  data?.risks?.map((risk) => {
                    return <li>
                      <Link href={`/projects/${data?.project?.id}/risks/${risk?.id}`}>{risk?.name}
                      </Link>
                    </li>
                  })
                )
                )}
              </ul>
            </>}
          {data?.audit_tests?.length > 0 &&
            <>
              <h2>Audit Tests:</h2>
              <ul>
                {(data?.audit_tests && (
                  data?.audit_tests?.map((at) => {
                    return <li>
                      <Link href={`/projects/${data?.project?.id}/audit_tests/${at?.id}`}>{at?.name}
                      </Link>
                    </li>
                  })
                )
                )}
              </ul>
            </>
          }
          {data?.project_controls?.length > 0 &&
            <>
              <h2>Project Controls:</h2>
              <ul>
                {(data?.project_controls && (
                  data?.project_controls?.map((pc) => {
                    return <li>
                      <Link href={`/projects/${data?.project?.id}/controls/${pc?.id}`}>{pc?.control?.name}
                      </Link>
                    </li>
                  })
                )
                )}
              </ul>
            </>
          }
          {data?.keywords?.length > 0 &&
            <>
              <h2>Keywords:</h2>
              <ul>
                {(data?.keywords && (
                  data?.keywords.map((keyword) => {
                    return <li>
                      <Link href={`/keywords/${keyword.keyword.id}`}>{keyword.keyword.name}</Link>
                    </li>
                  })
                )
                )}
              </ul>
            </>
          }

        </>

        <Dialog
          //fullScreen
          open={editOpen}
          TransitionComponent={Transition}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          className={`dialog edit`}
        >
          <DialogActions className="create-edit-dialog">
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
          <DialogTitle>Edit {data?.name}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <CreateEdit dataType='project_controls' editDataObj={data} projId={data?.project?.id} controlData={addControlData} isOpen={isOpen} />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  )
}