import React, { useEffect, useState, useMemo, useCallback } from "react";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import { MenuItem, Select } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

export async function getKeywords() {
  const url = `${process.env.REACT_APP_API_URL}/keyword/`
  const response = axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export async function getRiskCategories() {
  const url = `${process.env.REACT_APP_API_URL}/risk_categories/`
  const response = axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export async function getDropdownData (projectId) {
  var url = `${process.env.REACT_APP_API_URL}/projects/${projectId}`;
  const response = axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export async function getUsers() {
  const url = `${process.env.REACT_APP_API_URL}/users/get_users_tenant`
  try {
    const response = axios(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.access_token}`,
      },
    })
    return response
  } catch (err) {
    if (!err?.response) {
      
      const status = String(err.response?.status)
      if (status === "422") {
        return `Validation error.`
      } else {
       return`There was a ${err.response?.status} error`
      }
    } else {
      return "There was an error"
    }
  }
}

export default function CreateEditProjectRisk({ editDataObj, isOpen, projectId, controlData }) {
  //axios error message
  const [errMsg, setErrMsg] = useState("");
  useEffect(() => {
  }, [errMsg])

  // text field state values
  const [name, setName] = useState(editDataObj?.name);
  const [description, setDescription] = useState(editDataObj?.description);
  const [technology, setTechnology] = useState(editDataObj?.technology);
  const [affectedAssets, setAffectedAssets] = useState(editDataObj?.affected_assets);
  const [newKeywords, setNewKeywords] = useState("")
  const [additionalNotes, setAdditionalNotes] = useState(editDataObj?.additional_notes);
  const [comments, setComments] = useState(editDataObj?.comments)

  //used with users
  const [users, setUsers] = useState([]);
  //default option when no value selected in drop down
  const selectOption = useMemo(() => { return { value: "0", label: "Please select..." } }, [])
  const userSelectOption = useMemo(() => { return { id: 0, email: "Please select..." } }, [])

  //used with select fields in form
  const [projectControls, setProjectControls] = useState([{ value: "0", label: "Please select..." }]);
  const [projectControlId, setProjectControlId] = useState(editDataObj?.project_control?.id);
  const [auditTests, setAuditTests] = useState([{ value: "0", label: "Please select..." }]);
  const [auditTestId, setAuditTestId] = useState(editDataObj?.audit_test?.id);
  const [currentLikelihoodId, setCurrentLikelihoodId] = useState(editDataObj?.current_likelihood?.id);
  const [riskScoreId, setRiskScoreId] = useState(editDataObj?.risk_score?.id);
  const [riskCategoryId, setRiskCategoryId] = useState(editDataObj?.risk_category?.id);
  const [riskCategories, setRiskCategories] = useState([{ value: "0", label: "Please select..." }])
  const [riskImpactId, setRiskImpactId] = useState(editDataObj?.risk_impact?.id);
  const [riskStatusId, setRiskStatusId] = useState(editDataObj?.risk_status?.id);
  const [riskOwnerId, setRiskOwnerId] = useState(editDataObj?.owner?.id.toString());
  const [riskStakeholderId, setRiskStakeholderId] = useState(editDataObj?.riskStakeholderId);
  const [riskStakeholders, setRiskStakeholders] = useState([{ id: 0, email: "Please select..." }]);
  const [keywords, setKeywords] = useState([{ id: "0", name: "Please select..." }]);;

  const [keywordSelections, setKeywordSelections] = useState([]);

  const [keywordId, setKeywordId] = useState();
  const keywordSelectOption = useMemo(() => { return { id: "0", name: "Please select..." } }, [])


  const riskLikelihood = [
    selectOption,
    { value: "1", label: "Very Likely" },
    { value: "2", label: "Likely" },
    { value: "3", label: "Very Unlikely" },
    { value: "4", label: "Possible" },
    { value: "5", label: "Unlikely" },
  ]
  const riskImpacts = [
    selectOption,
    { value: "1", label: "Insignificant" },
    { value: "2", label: "Minor" },
    { value: "3", label: "Moderate" },
    { value: "4", label: "Major" },
    { value: "5", label: "Extreme" },
  ]
  const riskScore = [
    selectOption,
    { value: "3", label: "10" },
    { value: "2", label: "5" },
    { value: "1", label: "1" },
  ]
  const riskStatus = [
    selectOption,
    { value: "1", label: "Active" },
    { value: "2", label: "On Hold" },
    { value: "3", label: "Completed" },
    { value: "4", label: "Cancelled" },
  ]

  const { control, handleSubmit } = useForm({
    defaultValues: {
      keywordSelect: {},
      projectControlSelect: {},
      auditTestSelect: {},
      riskLikelihoodSelect: {},
      riskImpactSelect: {},
      riskScoreSelect: {},
      riskCategorySelect: {},
      riskStatusSelect: {},
      riskOwnerSelect: {},
      riskStakeholdersSelect: {},
    }
  });

  useEffect(() => {
    if (editDataObj?.keywords) {
      let options = []
      for (let i = 0; i < editDataObj?.keywords.length; i++) {
        options.push({ id: editDataObj?.keywords[i].id, name: editDataObj?.keywords[i].keyword.name })
      }
      setKeywordSelections(options)
    }
  }, [editDataObj])

  useEffect(() => {
    if (editDataObj?.additional_stakeholders) {
      let options = []
      for (let i = 0; i < editDataObj?.additional_stakeholders.length; i++) {
        options.push({ id: editDataObj?.additional_stakeholders[i].id, name: editDataObj?.additional_stakeholders[i].email })
      }
      setRiskStakeholders(options)
    }
  }, [editDataObj])

  const removeKeyword = (key) => {
    var tmpfilter = keywordSelections.filter(keyword => keyword.id !== key.id)
    setKeywordSelections(tmpfilter);
    var keyword = keywordSelections.filter(word => word.id === key.id)
    var name = keyword[0].name
    setKeywords(keywords => [...keywords, { name: name, id: key.id }]);
  }

  const handleChangeKeywordSelect = (event) => {
    setKeywordId(event.target.value);
    var keyword = keywords.filter(word => word.id === event.target.value)
    var name = keyword[0].name
    setKeywordSelections(keywordSelections => [...keywordSelections, { name: name, id: event.target.value }]);
    var tmpfilterSelect = keywords.filter(keyword => keyword.id !== event.target.value)
    setKeywords(tmpfilterSelect)
  }

  //handle change events on text fields
  const handleChangeName = (event) => {
    setName(event.target.value)
  }

  const handleChangeDescription = (event) => {
    setDescription(event.target.value)
  }

  const handleChangeTechnology = (event) => {
    setTechnology(event.target.value)
  }

  const handleChangeAffectedAssets = (event) => {
    setAffectedAssets(event.target.value)
  }

  const handleChangeNewKeywords = (event) => {
    setNewKeywords(event.target.value)
  }

  const handleChangeAdditionalNotes = (event) => {
    setAdditionalNotes(event.target.value)
  }

  const handleChangeComments = (event) => {
    setComments(event.target.value)
  }

  // handle change events on dropdowns
  const handleChangeProjectControlSelect = (event) => {
    setProjectControlId(event.target.value);
  }
  const handleChangeAuditTestSelect = (event) => {
    setAuditTestId(event.target.value);
  }
  const handleChangeCurrentLikelihoodSelect = (event) => {
    setCurrentLikelihoodId(event.target.value);
  }
  const handleChangeRiskScoreSelect = (event) => {
    setRiskScoreId(event.target.value);
  }
  const handleChangeRiskCategorySelect = (event) => {
    setRiskCategoryId(event.target.value);
  }
  const handleChangeRiskImpactSelect = (event) => {
    setRiskImpactId(event.target.value);
  }
  const handleChangeRiskStatusSelect = (event) => {
    setRiskStatusId(event.target.value);
  }
  const handleChangeRiskOwnerSelect = (event) => {
    setRiskOwnerId(event.target.value);
  }
  const handleChangeRiskStakeholderSelect = (event) => {
    setRiskStakeholderId(event.target.value);
    var useremail = users.filter(user => user.id === event.target.value)
    var email = useremail[0].email
    setRiskStakeholders(riskStakeholders => [...riskStakeholders, { email: email, id: event.target.value }]);
  }
  const removeStakeholder = (stake) => {
    var tmpfilter = riskStakeholders.filter(stakeholder => stake.id !== stakeholder.id)
    setRiskStakeholders(tmpfilter);
  }

  async function postFormData(data) {
    //look at keywords
    if (!/^[a-z,]*$/.test(newKeywords)) {
      setErrMsg(`Sorry your keywords contain values other than lowercase characters and commas`)
    } else {
      //clean up duplicate vals
      var cleanedKeywords = []
      var enteredKeywords = newKeywords.split(',')
      for (let x = 0; x < enteredKeywords.length; x++) {
        let match = false
        for (let y = 0; y < keywordSelections.length; y++) {
          if (enteredKeywords[x] === keywordSelections[y].name) {
            match = true
          }
        }
        if (match === false) {
          cleanedKeywords.push(enteredKeywords[x])
        }
      }
      for (let p = 0; p < keywordSelections.length; p++) {
        cleanedKeywords.push(keywordSelections[p].name)
      }
      var cleanedWords = cleanedKeywords.join(',')
      var url;
      var body;
      var method = "POST";
      var stakeholderidarr = [];
      for (let i = 0; i < riskStakeholders.length; i++) {
        stakeholderidarr.push(riskStakeholders[i].id)
      }
      var removenullids = []
      for (let p = 0; p < stakeholderidarr.length; p++) {
        if (stakeholderidarr[p] !== null && stakeholderidarr[p] !== 0) {
          removenullids.push(parseInt(stakeholderidarr[p]))
        }
      }
      /* project risks */
      url = `${process.env.REACT_APP_API_URL}/risks?keywords=${cleanedWords}`
      body = {
        project_id: projectId,
        audit_test_id: auditTestId,
        current_likelihood_id: currentLikelihoodId,
        risk_score_id: riskScoreId,
        risk_category_id: riskCategoryId,
        risk_impact_id: riskImpactId,
        risk_status_id: riskStatusId,
        name: name,
        description: description,
        keywords: keywords,
        project_control_id: projectControlId,
        /*external_reference_id: data?.external_reference_id,*/
        current_impact: 0,
        /*risk_assessment: "",
        */
        affected_assets: affectedAssets,
        technology: technology,
        additional_stakeholder_ids: removenullids,
        owner_id: riskOwnerId,
        /*
        owner_supervisor: data.userSelect.value,
        */
        comments: comments,
        additional_notes: additionalNotes,
      }
      //if a data object is being edited
      if (editDataObj) {
        method = "PUT";
        url = `${process.env.REACT_APP_API_URL}/risks/${editDataObj.id}?keywords=${cleanedWords}`;
      }
      const response = axios(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.access_token}`,
        },
        data: body,
      }).then(result => {
        return result;
      }).catch(error => { return error.message; })
      return response;
    }
  }

  /* form submit */
  const onSubmit = async (data) => {
    let response = await postFormData(data);
    controlData(response.data);
    isOpen(false);
  }

  const getDropDownOptions = useCallback(async () => {
    const jsonDataKey = await getKeywords();
    const tmpoptionskeys = jsonDataKey.data.map((word) => ({
      id: word.id,
      name: word.name,
    }));
    var allOptions = [keywordSelectOption, ...tmpoptionskeys];
    setKeywords(allOptions);
    const jsonDataRiskCat = await getRiskCategories();
    const tmpoptionsriskcats = jsonDataRiskCat.data.map((rc) => ({
      id: rc.id,
      name: rc.name,
    }));
    var allOptionsRc = [keywordSelectOption, ...tmpoptionsriskcats];
    setRiskCategories(allOptionsRc);
    const jsonData = await getDropdownData(projectId);
    const projectControlOptions = jsonData.data.project_controls.map((control) => ({
      value: control.id,
      label: control.control.name,
    }));
    var allControlOptions = [selectOption, ...projectControlOptions];
    setProjectControls(allControlOptions);
    const auditTestOptions = jsonData.data.audit_tests.map((test) => ({
      value: test.id,
      label: test.name,
    }));
    var allAuditOptions = [selectOption, ...auditTestOptions];
    setAuditTests(allAuditOptions);
    //get users for owner listing for risks
    const jsonDataUsers = await getUsers();

    const tmpoptions = jsonDataUsers.data.map((user) => ({
      id: user.id,
      email: user.email,
    }));
    var allUserOptions = [userSelectOption, ...tmpoptions];
    setUsers(allUserOptions);

  }, [selectOption, userSelectOption, projectId, keywordSelectOption])

  useEffect(() => {
    getDropDownOptions();
  }, [getDropDownOptions]);

  useEffect(() => {
    //console.log(`editDataObj = ${JSON.stringify(editDataObj)}`)
    if (editDataObj?.additional_stakeholders) {
      setRiskStakeholders(editDataObj?.additional_stakeholders)
    }
  }, [editDataObj])

  useEffect(() => {

  }, [riskStakeholderId, riskStakeholders]);

  const clearFormEntries = () => {
    setName("")
    setDescription("")
    setComments("")
    setTechnology("")
    setAdditionalNotes("")
    setAffectedAssets("")
    setNewKeywords("")
    setRiskOwnerId(0)
    setRiskStakeholders([])
    setRiskCategoryId("0")
    setRiskImpactId("0")
    setRiskScoreId("0")
    setRiskStatusId("0")
    setCurrentLikelihoodId("0")
    setKeywordSelections([])
    setProjectControlId("0")
  }

  return (
    <div className="create edit risk">
      {errMsg ? (
        <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
          <Alert severity="error">{errMsg}</Alert>
        </Stack>
      ) : (
        <></>
      )}
      <form onSubmit={handleSubmit(onSubmit)} id="createEditProjectRisk">
        <div className="form-field name">
          <TextField onChange={handleChangeName} required label="Name" fullWidth value={name} />
        </div>
        <div className="form-field description">
          <TextField
            multiline
            rows={4}
            label="Description"
            fullWidth
            onChange={handleChangeDescription}
            value={description}
          />
        </div>
        <div className="form-field control">
          <label>Project Control</label>
          <Controller
            name="projectControlSelect"
            control={control}
            render={({ field }) =>
              <Select
                {...field}
                onChange={handleChangeProjectControlSelect}
                value={projectControlId ? projectControlId : 0}
                inputProps={{ "data-testid": "project-control-select" }}
              //defaultInputValue={projectControlId}
              >
                {
                  projectControls?.map((control) => {
                    return <MenuItem
                      value={control.value}
                      key={control.value}>
                      {control.label}
                    </MenuItem>
                  })
                }
              </Select>
            }
          />
        </div>
        <div className="form-field control">
          <label>Audit Test</label>
          <Controller
            name="auditTestSelect"
            control={control}
            render={({ field }) =>
              <Select
                {...field}
                onChange={handleChangeAuditTestSelect}
                value={auditTestId ? auditTestId : 0}
                inputProps={{ "data-testid": "audit-test-select" }}
              >
                {
                  auditTests?.map((test) => {
                    return <MenuItem
                      value={test.value}
                      key={test.value}>
                      {test.label}
                    </MenuItem>
                  })
                }
              </Select>
            }
          />
        </div>
        <div className="form-field control">
          <label>Risk Likelihood</label>
          <Controller
            name="riskLikelihoodSelect"
            control={control}
            render={({ field }) =>
              <Select
                {...field}
                onChange={handleChangeCurrentLikelihoodSelect}
                value={currentLikelihoodId ? currentLikelihoodId : 0}
                inputProps={{ "data-testid": "likelihood-select" }}
              >
                {
                  riskLikelihood?.map((risk) => {
                    return <MenuItem
                      value={risk.value}
                      key={risk.value}>
                      {risk.label}
                    </MenuItem>
                  })
                }
              </Select>
            }
          />
        </div>
        <div className="form-field control">
          <label>Risk Impact</label>
          <Controller
            name="riskImpactSelect"
            control={control}
            render={({ field }) =>
              <Select
                {...field}
                onChange={handleChangeRiskImpactSelect}
                value={riskImpactId ? riskImpactId : 0}
                inputProps={{ "data-testid": "impact-select" }}
              >
                {
                  riskImpacts.map((risk) => {
                    return <MenuItem
                      value={risk.value}
                      key={risk.value}>
                      {risk.label}
                    </MenuItem>
                  })
                }
              </Select>
            }
          />
        </div>
        <div className="form-field control">
          <label>Risk Score</label>
          <Controller
            name="riskScoreSelect"
            control={control}
            render={({ field }) =>
              <Select
                {...field}
                onChange={handleChangeRiskScoreSelect}
                value={riskScoreId ? riskScoreId : 0}
                inputProps={{ "data-testid": "score-select" }}
              >
                {
                  riskScore.map((risk) => {
                    return <MenuItem
                      value={risk.value}
                      key={risk.value}>
                      {risk.label}
                    </MenuItem>
                  })
                }
              </Select>
            }
          />
        </div>
        <div className="form-field control">
          <label>Risk Category</label>
          <Controller
            name="riskCategorySelect"
            control={control}
            render={({ field }) =>
              <Select
                {...field}
                onChange={handleChangeRiskCategorySelect}
                value={riskCategoryId ? riskCategoryId : 0}
                inputProps={{ "data-testid": "category-select" }}
              >
                {
                  riskCategories.map((risk) => {
                    return <MenuItem
                      value={risk.id}
                      key={risk.id}>
                      {risk.name}
                    </MenuItem>
                  })
                }
              </Select>
            }
          />
        </div>
        <div className="form-field control">
          <label>Risk Status</label>
          <Controller
            name="riskStatusSelect"
            control={control}
            render={({ field }) =>
              <Select
                {...field}
                onChange={handleChangeRiskStatusSelect}
                value={riskStatusId ? riskStatusId : 0}
                inputProps={{ "data-testid": "status-select" }}
              >
                {
                  riskStatus.map((risk) => {
                    return <MenuItem
                      value={risk.value}
                      key={risk.value}>
                      {risk.label}
                    </MenuItem>
                  })
                }
              </Select>
            }
          />
        </div>
        <div className="form-field control">
          <label>Owner</label>
          <Controller
            name="riskOwnerSelect"
            control={control}
            defaultValue={"0"}
            render={({ field }) =>
              <Select
                {...field}
                onChange={handleChangeRiskOwnerSelect}
                defaultValue={"0"}
                value={riskOwnerId ? riskOwnerId : "0"}
                inputProps={{ "data-testid": "owner-select" }}
              >
                {
                  users.map((user) => {
                    return <MenuItem
                      value={user.id}
                      key={user.id}>
                      {user.email}
                    </MenuItem>
                  })
                }
              </Select>
            }
          />
        </div>
        <div className="form-field control">
          <label>Additional Stakeholders</label>
          <Controller
            name="riskStakeholdersSelect"
            control={control}
            defaultValue={0}
            render={({ field }) =>
              <Select
                {...field}
                onChange={handleChangeRiskStakeholderSelect}
                defaultValue={0}
                value={riskStakeholderId ? riskStakeholderId : 0}
                inputProps={{ "data-testid": "stakeholder-select" }}
              >
                {
                  users.map((user) => {
                    return <MenuItem
                      value={user.id}
                      key={user.id}>
                      {user.email}
                    </MenuItem>
                  })
                }
              </Select>
            }
          />
          <ul>
            {(riskStakeholders && (
              riskStakeholders.map((stakeholder) => {
                return <li>
                  {stakeholder.email}
                  <IconButton aria-label="delete">
                    <DeleteIcon onClick={() => { removeStakeholder(stakeholder); }} />
                  </IconButton>
                </li>
              })
            )
            )}
          </ul>
        </div>
        <div className="form-field technology">
          <TextField onChange={handleChangeTechnology} value={technology} label="Technology" fullWidth />

        </div>
        <div className="form-field affected-assets">
          <TextField onChange={handleChangeAffectedAssets} value={affectedAssets} label="Affected Assets" fullWidth />

        </div>
        <div className="form-field keywords">
          <div className="form-field control">
            <label>Existing Keywords</label>
            <Controller
              name="keywordSelect"
              control={control}
              defaultValue={0}
              render={({ field }) =>
                <Select
                  {...field}
                  value={keywordId ? keywordId : 0}
                  defaultValue={0}
                  onChange={handleChangeKeywordSelect}
                  inputProps={{ "data-testid": "keyword-select" }}
                >
                  {
                    keywords?.map((word) => {
                      return <MenuItem
                        value={word.id}
                        key={word.id}>
                        {word.name}
                      </MenuItem>
                    })
                  }
                </Select>
              }
            />
          </div>
          <ul>
            {(keywordSelections && (
              keywordSelections.map((word) => {
                return <li>
                  {word.name}
                  <IconButton aria-label="delete">
                    <DeleteIcon onClick={() => { removeKeyword(word); }} />
                  </IconButton>
                </li>
              })
            )
            )}
          </ul>
          <div className="form-field newkeywords">
            <TextField
              label="Add new keywords"
              onChange={handleChangeNewKeywords}
              value={newKeywords}
              //fullWidth
              helperText="Enter your keyword in all lowercase with a comma and no spaces between each one e.g. legal,technical"
            />
          </div>
        </div>
        <div className="form-field notes">
          <TextField
            multiline
            rows={4}
            label="Additional notes"
            fullWidth
            onChange={handleChangeAdditionalNotes}
            value={additionalNotes}
          />
        </div>
        <div className="form-field comments">
          <TextField

            multiline
            rows={4}
            label="Comments"
            fullWidth
            onChange={handleChangeComments}
            value={comments}
          />
        </div>
        <div>
          {
            (editDataObj && <Button type="submit" variant="contained" >Submit</Button>) ||
            (!editDataObj && <Button type="submit" variant="contained" >Create</Button>)
          }
          <Button onClick={clearFormEntries}>Clear</Button>
        </div>
      </form>
    </div>
  )
}