/* If a user is on a trial this component displays a message */
import React, { useState, useEffect, useCallback } from 'react';
import axios from "axios";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { intlFormatDistance, fromUnixTime, getTime } from 'date-fns'

export default function TrialMessage({ days }) {
    const [customerId, setCustomerId] = useState('');
    const [daysLeft, setDaysLeft] = useState(null);
    const [subActive, setSubActive] = useState(true);

    const getActiveSubscription = useCallback(async () => {
        var url = `${process.env.REACT_APP_API_URL}/subscriptions/list`;
        var body = JSON.stringify(
            {
                customer: customerId,
                status: "active",
            })
        await axios(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.access_token}`,
            },
            data: body,
        }).then(result => {
            if (!result.data.data.length) {
                setSubActive(false);
            }
            return result;
        }).catch(error => { return error.message; })
        //return response;
    }, [customerId])

    const getTrialSubscription = useCallback(async () => {
        var url = `${process.env.REACT_APP_API_URL}/subscriptions/list`;
        var body = JSON.stringify(
            {
                customer: customerId,
                status: "trialing",
            })
        await axios(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.access_token}`,
            },
            data: body,
        }).then(result => {
            if (result.data.data.length > 0) {
                const trialend = fromUnixTime(result.data.data[0].trial_end)
                const now = getTime(new Date())
                const sumdays = intlFormatDistance(trialend, now, { unit: 'day' })
                setDaysLeft(sumdays)
                days(sumdays)
            }
            return result;
        }).catch(error => { return error.message; })
        //return response;
    }, [customerId, days])

    const getCustomerId = async () => {
        var url = `${process.env.REACT_APP_API_URL}/tenants/customer_id`;
        const response = await axios(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.access_token}`,
            },
        }).then(result => {
            setCustomerId(result.data);
            return result;
        }).catch(error => { return error.message; })
        return response;
    };

    useEffect(() => {
        getCustomerId();
    }, [customerId]);

    useEffect(() => {
        if (customerId) {
            getActiveSubscription()
        }
    }, [customerId, getActiveSubscription])

    useEffect(() => {
        if (subActive === false) {
            getTrialSubscription();
        }

    }, [subActive, getTrialSubscription])

    useEffect(() => {

    }, [daysLeft])

    if (daysLeft) {
        return (
            <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
                <Alert severity="warning">You have {daysLeft} days left of your free trial for 1 user. To add more users please upgrade your <a href='/subscription'>subscription</a>.</Alert>
            </Stack>

        )
    }
    return (
        <></>
    )



}
