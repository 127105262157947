import React, { useEffect, useState, useCallback } from "react";
import { DataGrid } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import SpinnerComponent from '../LoadingSpinner';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
import CreateEdit from '../CreateEdit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

//This class displays lists of frameworks

export async function updateFrameworkTenantMap (id, state) {
    var url = `${process.env.REACT_APP_API_URL}/frameworks/map`
    const newMap = {
        "tenant_id": parseInt(localStorage.getItem("tenant_id")),
        "framework_id": parseInt(id),
        "is_enabled": state,
    }

    const response = await axios(url, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
        data: newMap,
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export async function loadData (type) {
    //call data endpoint for data type to set rows and columns
    var url = `${process.env.REACT_APP_API_URL}/frameworks/?is_global=true&offset=0&limit=100`;
    if (type === "custom") {
        url = `${process.env.REACT_APP_API_URL}/frameworks/?is_global=false&offset=0&limit=100`;
    }
    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export async function deleteData(framework) {
    var url = `${process.env.REACT_APP_API_URL}/frameworks/${framework}`;

    const response = axios(url, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

const columns = [
    { field: 'id', headerName: 'id', width: 120 },
];

const rows = [
    { id: "n/a" },
];

export default function FrameworkList() {

    const [value, setValue] = useState(0);
    //axios error message
    const [errMsg, setErrMsg] = useState("");

    const [rowsGlobalData, setRowsGlobalData] = useState([]);
    const [colsGlobalData, setColsGlobalData] = useState(null);

    const [rowsCustomData, setRowsCustomData] = useState([]);
    const [colsCustomData, setColsCustomData] = useState(null);

    const [loading, setLoading] = useState(false);

    const [frameworkGlobalData, setFrameworkGlobalData] = useState(null);
    const [frameworkCustomData, setFrameworkCustomData] = useState(null);

    const [openDialog, setOpenDialog] = useState(null);
    const [openAddDialog, setOpenAddDialog] = useState(null);

    const [editObj, setEditObj] = useState(null);

    const [selected, setSelected] = useState([]);

    const [isSystemAdmin, setIsSystemAdmin] = useState(null);

    useEffect(() => {
        if (localStorage.getItem("system_role") === "4" ||
            localStorage.getItem("system_role") === "1" ||
            localStorage.getItem("system_role") === "2"
        ) {
            setIsSystemAdmin(true)
        } else {
            setIsSystemAdmin(false)
        }

    }, [setIsSystemAdmin])

    const handleClose = () => {
        setOpenAddDialog(false);
        setOpenDialog(false);
        setEditObj(null);
    }

    const isOpen = () => {
        //setEditObj(null);
        handleClose();
    }

    const onChange = async (e, value) => {
        setValue(value);
    }

    const onRowsSelectionHandler = (ids) => {
        setSelected([...ids]);
    };

    const handleSwitchChange = useCallback(async (id, state) => {
        var changeState;
        if (state === false) {
            changeState = true
        }
        if (state === true) {
            changeState = false
        }
        //await updateFrameworkTenantMap(id, changeState).then(getData())
        await updateFrameworkTenantMap(id, changeState)
    }, [])

    const getData = useCallback(async() => {
        setLoading(true)
        var response = await loadData("global");
        setLoading(false)
        setFrameworkGlobalData(response)
        if (response) {
            var filteredRowDataFrameworks = [];

            response?.data?.items?.forEach((key) => {
                let is_enabled_tenant = false
                key.framework_tenant?.forEach((ten) => {
                    if (ten.tenant_id.toString() === localStorage.getItem("tenant_id") && ten.is_enabled === true) {
                        is_enabled_tenant = true
                    }
                })
                filteredRowDataFrameworks.push({
                    id: key.id,
                    name: key.name,
                    description: key.description,
                    is_enabled: is_enabled_tenant,
                });
            })

            setRowsGlobalData([...filteredRowDataFrameworks]);
            var colsVals = [
                {
                    field: 'name',
                    headerName: 'Name',
                    width: 200,
                    renderCell: (params) => (
                        <Link href={`/frameworks/${params.row.id}`}>{params.value}</Link>
                    )
                },
                {
                    field: 'description',
                    headerName: 'Description',
                    width: 200,
                    cellClass: "normalLineHeight"
                },
                {
                    field: 'is_enabled',
                    headerName: 'Enabled',
                    width: 200,
                    renderCell: (params) => (
                        <>
                            <FormGroup className="watch-switch">
                                <FormControlLabel control={<Switch checked={params.row.is_enabled} onChange={() => { handleSwitchChange(params.row.id, params.row.is_enabled); }} />} label="Enable" />
                            </FormGroup>
                        </>
                    )
                },
                {
                    field: 'action',
                    headerName: 'Action',
                    width: 200,
                    renderCell: (params) => (
                        <>
                            <Button startIcon={<EditIcon />} onClick={() => { setEditObj(params.row); setOpenAddDialog(true) }} />
                            <Button startIcon={<DeleteIcon />} onClick={() => { setEditObj(params.row); setOpenDialog(true) }} />
                        </>
                    )
                },
            ];
            setColsGlobalData([...colsVals]);
        }
        var responseCustom = await loadData("custom");

        setFrameworkCustomData(responseCustom)
        if (responseCustom) {
            var filteredRowDataFrameworksCus = [];
            responseCustom?.data?.items?.forEach((key) => {
                let is_enabled_tenant = false
                key.framework_tenant?.forEach((ten) => {
                    if (ten.tenant_id.toString() === localStorage.getItem("tenant_id") && ten.is_enabled === true) {
                        is_enabled_tenant = true
                    }
                })
                filteredRowDataFrameworksCus.push({
                    id: key.id,
                    name: key.name,
                    description: key.description,
                    is_enabled: is_enabled_tenant,
                });
            })

            setRowsCustomData([...filteredRowDataFrameworksCus]);
            var colsValsCus = [
                {
                    field: 'name',
                    headerName: 'Name',
                    width: 200,
                    renderCell: (params) => (
                        <Link href={`/frameworks/${params.row.id}`}>{params.value}</Link>
                    )
                },
                {
                    field: 'description',
                    headerName: 'Description',
                    width: 200,
                    cellClass: "normalLineHeight"
                },
                {
                    field: 'is_enabled',
                    headerName: 'Enabled',
                    width: 200,
                    renderCell: (params) => (
                        <>
                            <FormGroup className="watch-switch">
                                <FormControlLabel control={<Switch checked={params.row.is_enabled} onChange={() => { handleSwitchChange(params.row.id, params.row.is_enabled); }} />} label="Enable" />
                            </FormGroup>
                        </>
                    )
                },
                {
                    field: 'action',
                    headerName: 'Action',
                    width: 200,
                    renderCell: (params) => (
                        <>
                            <Button startIcon={<EditIcon />} onClick={() => { setEditObj(params.row); setOpenAddDialog(true) }} />
                            <Button startIcon={<DeleteIcon />} onClick={() => { setEditObj(params.row); setOpenDialog(true) }} />
                        </>
                    )
                },
            ];
            setColsCustomData([...colsValsCus]);
        }
    }, [handleSwitchChange])

    const deleteSelectedRow = useCallback(async() => {
        if (editObj) {
            const response = await deleteData(editObj.id);
            if (response?.status !== 200) {
                setErrMsg(`${response}`);
            }
            if (response?.status === 200) {
                //let filteredArray = rowsData?.filter(item => item.id !== editObj.id)
                //setRowsData(filteredArray);
                getData()
            }
        }
        if (selected.length !== 0 && !editObj) {
            for (let x = 0; x < selected.length; x++) {
                const response = await deleteData(selected[x]);
                if (response?.status !== 200) {
                    setErrMsg(`${response}`);
                }
                if (response?.status === 200) {
                    //let filteredArray = rowsData?.filter(item => item.id !== selected[x])
                    //setRowsData(filteredArray);
                    getData()
                }
            }
        }

    }, [editObj, getData, selected]);

    useEffect(() => {
        if (frameworkGlobalData === null || frameworkCustomData === null) {
            getData();
        }
    }, [getData, frameworkGlobalData, frameworkCustomData]);

    useEffect(() => {
        //console.log(rowsGlobalData)
    }, [rowsGlobalData])

    useEffect(() => {
        //console.log(colsGlobalData)
    }, [colsGlobalData])

    return (
        <div style={{ marginTop: '0px' }}>
            <>
                {errMsg ? (
                    <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
                        <Alert severity="error">{errMsg}</Alert>
                    </Stack>
                ) : (
                    <></>
                )}
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/system-administration">
                        System Administration
                    </Link>
                    <Typography color="text.primary">Frameworks</Typography>
                </Breadcrumbs>
                {/*<AppBar position="static">*/}
                <Tabs value={value} onChange={onChange}>
                    <Tab label="Custom / Imported Frameworks" component={Link} to={`/frameworks/list/custom}`} />
                    <Tab label="Global Frameworks" component={Link} to={`/frameworks/list/global`} />
                </Tabs>
                {/*</AppBar>*/}
                {value === 0 && (
                    <>
                        {isSystemAdmin && <div className="btns-top-list">
                            <Button
                                variant="contained"
                                className="delete secondary btn"
                                startIcon={<DeleteIcon />}
                                disabled={!selected.length}
                                onClick={() =>
                                    selected.length ? setOpenDialog("delete") : null
                                }
                            >
                                Delete
                            </Button>
                            <Button
                                variant="outlined"
                                startIcon={<AddCircleIcon />}
                                onClick={() => { setOpenAddDialog(true); }}
                                className="addBtn"
                            >Add</Button>
                        </div>}
                        <div style={{ height: '600px', width: '100%' }} className="framework-list">
                            <DataGrid
                                getRowClassName={(params) =>
                                    params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                                }
                                rows={rowsCustomData ? rowsCustomData : rows}
                                columns={colsCustomData ? colsCustomData : columns}
                                rowsPerPageOptions={[10]}
                                pageSize={10}
                                checkboxSelection
                                loading={loading}
                                components={{
                                    LoadingOverlay: SpinnerComponent,
                                }}
                                onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                                getRowHeight={() => 'auto'}
                            />
                        </div>
                    </>
                )}
                {value === 1 && (
                    <>
                        {localStorage.getItem("is_superuser") === "true" && <div className="btns-top-list">
                            <Button
                                variant="contained"
                                className="delete secondary btn"
                                startIcon={<DeleteIcon />}
                                disabled={!selected.length}
                                onClick={() =>
                                    selected.length ? setOpenDialog("delete") : null
                                }
                            >
                                Delete
                            </Button>
                            <Button
                                variant="outlined"
                                startIcon={<AddCircleIcon />}
                                onClick={() => { setOpenAddDialog(true); }}
                                className="addBtn"
                            >Add</Button>
                        </div>}
                        <div style={{ height: '600px', width: '100%' }} className="framework-list">
                            <DataGrid
                                getRowClassName={(params) =>
                                    params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                                }
                                rows={rowsGlobalData ? rowsGlobalData : rows}
                                columns={colsGlobalData ? colsGlobalData : columns}
                                pageSize={10}
                                checkboxSelection
                                loading={loading}
                                components={{
                                    LoadingOverlay: SpinnerComponent,
                                }}
                                onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                                getRowHeight={() => 'auto'}
                            />
                        </div>
                    </>
                )}
                <Dialog
                    //fullScreen
                    maxWidth={'lg'}
                    open={openAddDialog}
                    onClose={handleClose}
                    aria-labelledby="add-project-data"
                    aria-describedby="alert-dialog-add-project-data"
                >
                    <DialogActions className="create-edit-dialog">
                        <Button onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                    <DialogTitle id="alert-dialog-title">
                        {editObj ? `Edit framework` : `Add framework`}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <CreateEdit dataType={'frameworks'} isOpen={isOpen} editDataObj={editObj} />
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={openDialog}
                    onClose={handleClose}
                    aria-labelledby="delete-confirm"
                    aria-describedby="alert-dialog-delete-confirm"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Delete?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="create-edit-dialog">
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={() => { handleClose(); deleteSelectedRow(); }} autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>

            </>
        </div>
    )

}