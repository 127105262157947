import React, {lazy, Suspense} from 'react';
import SpinnerComponent from '../components/LoadingSpinner';
const TaskList = lazy(() => import('../components/lists/TaskList'));
const TaskCalendar = lazy(() => import('../components/TaskCalendar'));

export default function Tasks() {
  return (
    <>
      <h1>Tasks</h1>
      <p>Alert users to upcoming tasks</p>
      <div className="panel calendar">
        <h2>Task Calendar</h2>
        <Suspense fallback={<SpinnerComponent />}><TaskCalendar /></Suspense>
      </div>
      <div className="panel">
        <Suspense fallback={<SpinnerComponent />}><TaskList /></Suspense>
      </div>
    </>
  );
}