import styled from 'styled-components';

const StyledLoadingSpinner = styled.div`
border: 4px solid #f3f3f3;
margin: auto;
border-radius: 50%;
border-top: 4px solid #acacac;
width: 80px;
height: 80px;
-webkit-animation: spin 2s linear infinite; /* Safari */
animation: spin 2s linear infinite;

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
`;

const SpinnerComponent = (() => (<div style={{height: "100%", display: "flex"}}>
    <StyledLoadingSpinner />
</div>)
);

export default SpinnerComponent;