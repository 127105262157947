import React, { useState, useEffect, useCallback, useMemo, lazy, Suspense } from 'react';
import { MenuItem, Select } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import axios from 'axios';
import SpinnerComponent from '../components/LoadingSpinner';
const RiskCharts = lazy(() => import('../components/graphs/RiskCharts'));
const RiskAnalysis = lazy(() => import('../components/graphs/RiskAnalysis'));

/* 
********START all endpoints called
*/
export async function getProjectData() {
  var url = `${process.env.REACT_APP_API_URL}/projects/get_user_projects/${localStorage.getItem("userid")}`
  if (localStorage.getItem("system_role") === "4") {
    url = `${process.env.REACT_APP_API_URL}/projects/tenant/`
  }
  const response = await axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export async function getChartData(id) {
  var url = `${process.env.REACT_APP_API_URL}/dashboards/risk/metrics/?project_id=${id}`;
  //console.log(`url = ${url}`)
  const response = await axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}
/* 
********END all endpoints called
*/

export default function Risk() {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      project: {},
    }
  });
  //default option when no value selected in drop down
  const selectOption = useMemo(() => { return { value: "0", label: "Please select..." } }, [])
  const [projects, setProjects] = useState([{ value: "0", label: "Please select..." }]);
  const [projectId, setProjectId] = useState("0");
  const [chartData, setChartData] = useState();

  const getDropDownOptions = useCallback(async () => { 
    const jsonData = await getProjectData();
    if (jsonData === 'Request failed with status code 401') {
      localStorage.clear();
      //window.location.reload(false);
    }
    if (jsonData?.data) {
      const projectOptions = jsonData?.data?.map((project) => ({
        value: project.id,
        label: project.name,
      }));
      setProjects([selectOption, ...projectOptions])
    }
  }, [selectOption])

  async function getCharts(id) {
    const jsonData = await getChartData(id);
    setChartData(jsonData.data);
    //console.log('setChartData', jsonData.data)
  }

  const handleChangeProjectSelect = (event) => {
    getCharts(event.target.value)
    setProjectId(event.target.value)
  };

  useEffect(() => {
    getDropDownOptions();
  }, [getDropDownOptions]);

  useEffect(() => {

  }, [projectId])

  return (
    <>
      <h1>Risk Dashboard</h1>
      <form onSubmit={handleSubmit()}>
        <div className="form-field control">
          <label>Select a Project</label>
          <Controller
            name="project"
            control={control}
            defaultValue={"0"}
            render={({ field }) =>
              <Select
                {...field}
                onChange={handleChangeProjectSelect}
                value={projectId ? projectId : "0"}
                inputProps={{ "data-testid": "project-select" }}
                defaultValue={""}
              >
                {
                  projects?.map((project) => {
                    return <MenuItem
                      value={project.value}
                      key={project.value}>
                      {project.label}
                    </MenuItem>
                  })
                }
              </Select>
            }
          />
        </div>
      </form>
      { projectId !== null && projectId !== "0" &&  <Suspense fallback={<SpinnerComponent />}><RiskAnalysis projectId={projectId} /></Suspense>}
     
      <Suspense fallback={<SpinnerComponent />}><RiskCharts chartData={chartData} /></Suspense>
    </>
  );
}