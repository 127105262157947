import React, { useState, Fragment, useEffect, useCallback } from "react";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CreateEdit from './CreateEdit';
import ProjectWizardDocumentList from "./lists/ProjectWizardDocumentList";
import ProjectControlList from "./lists/ProjectControlList";
import AuditTestList from "./lists/AuditTestList";
import RiskList from "./lists/RiskList";

const steps = ['Define Project', 'Define Governance', 'Define Compliance and Monitoring', 'Define Risks', 'Documents'];

export default function ProjectWizard() {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [projectId, setProjectId] = useState();
  const [createdProject, setCreatedProject] = useState();

  const setProject = useCallback(async (project) => {
    if (!projectId) {
      setProjectId(project.id)
      setCreatedProject(project)
    }
  }, [projectId])

  useEffect(() => {
  }, [createdProject, projectId])

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
    if (allStepsCompleted()) {
      setTimeout(() => {
        window.location.replace("/")
      }, [3000]);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  useEffect(() => {

  }, [projectId, setProject]);


  return (
    <Box sx={{ width: '100%' }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished. You are being redirected.
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </Fragment>
        ) : (
          <Fragment>
            <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
              Step {activeStep + 1}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              { activeStep !== 0 && <><Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button></>}

              {(activeStep !== steps.length) && (activeStep !== 0) &&
                (completed[activeStep] ? (
                  <Typography variant="caption" sx={{ display: 'inline-block' }}>
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button onClick={handleComplete}>
                    {completedSteps() === totalSteps() - 1
                      ? 'Finish'
                      : 'Complete Step'}
                  </Button>
                ))}

            </Box>
            {(activeStep === 0) && <CreateEdit dataType={`projects`} setProject={setProject} editDataObj={createdProject} handleComplete={handleComplete} isWizard={true} />}
            {(activeStep === 1 && projectId) && <><h2>Project Controls</h2><ProjectControlList projectId={createdProject?.id} /></>}
            {(activeStep === 2 && projectId) && <><h2>Project Audit Tests</h2><AuditTestList projectId={createdProject?.id} /></>}
            {(activeStep === 3 && projectId) && <><h2>Project Risks</h2><RiskList projectId={createdProject?.id} /></>}
            {(activeStep === 4 && projectId) && <><h2>Project Documents</h2><ProjectWizardDocumentList projectId={createdProject?.id} dataId={createdProject?.id}/></>}
          </Fragment>
        )}
      </div>
    </Box>
  );
}