import React, { useState, useEffect, useRef } from "react";
import { usePaymentInputs } from 'react-payment-inputs';
import { Button } from "@mui/material";
import axios from "axios";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import TextField from "@mui/material/TextField";
import '../css/PaymentMethod.css';
import Loader from './Loader';

//Back up payment method form

export default function PaymentInputs({ isOpen, editObj, registering }) {
    const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps } = usePaymentInputs();
    const [state, setState] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [zip, setZip] = useState("");
    const [cardNumber, setCardNumber] = useState("");
    const [cvc, setCvc] = useState("");
    const [expiryDate, setExpiryDate] = useState("");
    const [country, setCountry] = useState('');
    const [customerId, setCustomerId] = useState('');
    const paymentMethodId = useRef('');
    const [loading, setLoading] = useState(false);

    const handleChangeCardNumber = (e) => {
        setCardNumber(e.target.value)
    }

    const handleChangeExpiryDate = (e) => {
        setExpiryDate(e.target.value)
    }

    const handleChangeCVC = (e) => {
        setCvc(e.target.value)
    }

    const addPaymentMethod = async () => {
        var url = `${process.env.REACT_APP_API_URL}/subscriptions/create_payment_method`;
        var expdate = '0' + expiryDate.slice(0, 2)
        expdate = expdate.slice(-2)
        if (expdate )
        var body = JSON.stringify(
            {
                address_city: city,
                address_country: country,
                address_line_1: address1,
                address_line_2: address2,
                address_postal_code: zip,
                address_state: state,
                customer_id: `${customerId ? customerId : localStorage.customer_id}`,
                type: "card",
                card_number: cardNumber,
                card_exp_month: expdate,
                card_exp_year: expiryDate.slice(-2),
                card_cvc: cvc,
            })
        const response = await axios(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.access_token}`,
            },
            data: body,
        }).then(result => {
            //localStorage.setItem("payment_method", result.id)
            return result;
        }).catch(error => {
            return error.message;
        })
        return response;

    };

    const getBillingInfo = async () => {
        var url = `${process.env.REACT_APP_API_URL}/subscriptions/list_payment_methods`;
        var body = JSON.stringify(
            {
                customer_id: localStorage.getItem("customer_id"),
                type: "card",
            })
        await axios(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.access_token}`,
            },
            data: body,
        }).then(result => {
            paymentMethodId.current = result.data.data[0].id
            return result.data.data[0].id;
        }).catch(error => { return error.message; })
    };

    const createSubscription = async () => {
        var url = `${process.env.REACT_APP_API_URL}/subscriptions/create`;
        var body;
        if (!localStorage.getItem("price_key")) {
            body = JSON.stringify(
                {
                    plan: `month`,
                    payment_method_id: `${paymentMethodId ? paymentMethodId.current : ''}`,
                    price_key: `price_1N1ugkFPUSLwoOweoyhdBBgp`,
                    member_count: 0,
                    status: "trialing",
                    tenant_id: localStorage.getItem("tenant_id"),
                    trial_period_days: 30,
                    is_active: 0,
                })
        }
        if (localStorage.getItem("plan")) {
            body = JSON.stringify(
                {
                    plan: localStorage.getItem("plan"),
                    payment_method_id: `${paymentMethodId ? paymentMethodId.current : ''}`,
                    price_key: localStorage.getItem("price_key"),
                    member_count: localStorage.getItem("heads"),
                    status: "active",
                    tenant_id: localStorage.getItem("tenant_id"),
                    is_active: 1,
                })
        }

        const response = await axios(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.access_token}`,
            },
            data: body,
        }).then(result => {
            return result;
        }).catch(error => { return error.message; })
        return response;
    }

    const setDefaultPaymentMethod = async () => {
        var url = `${process.env.REACT_APP_API_URL}/subscriptions/attach_default_payment_method`;
        var body = JSON.stringify(
            {
                customer: `${customerId ? customerId : localStorage.customer_id}`,
                payment_method_id: `${paymentMethodId ? paymentMethodId.current : ''}`,
            })
        const response = await axios(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.access_token}`,
            },
            data: body,
        }).then(result => {
            if (!registering) {
                isOpen(false);
                window.location.reload(false);
            }
            return result;
        }).catch(error => {
            return error.message;
        })
        return response;

    };

    useEffect(() => {
        if (!registering) {
            getCustomerId();
        }

    }, [registering]);

    useEffect(() => {
        //console.log(`payment method id = ${paymentMethodId.current}`)
    }, [paymentMethodId]);

    const getCustomerId = async () => {
        var url = `${process.env.REACT_APP_API_URL}/tenants/customer_id`;
        const response = await axios(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.access_token}`,
            },
        }).then(result => {
            setCustomerId(result.data);
            return result;
        }).catch(error => { return error.message; })
        return response;
    };

    const handlePayment = async e => {
        e.preventDefault()
        if (!registering) {
            const response = await addPaymentMethod()
            await setDefaultPaymentMethod(response.id)
        }

        if (registering) {
            //pay for plan
            setLoading(true)
            await addPaymentMethod()
            await getBillingInfo()
            await setDefaultPaymentMethod().then(createSubscription())
            localStorage.clear()
            window.history.replaceState(null, '', window.location.pathname)
            window.location.replace('/')
            setLoading(false)
        }
    }

    if (loading) {
        return(
          <Loader />
        )
      }

    return (
        <div>
            <form id="payment-form" onSubmit={handlePayment}>
                <div className="cc-details">
                    <h2>Credit Card Details</h2>
                    <input {...getCardNumberProps({ onChange: handleChangeCardNumber })} value={editObj ? editObj.number : cardNumber} />
                    <input {...getExpiryDateProps({ onChange: handleChangeExpiryDate })} value={editObj ? editObj.expiry : expiryDate} />
                    <input {...getCVCProps({ onChange: handleChangeCVC })} value={cvc} />
                    {meta.isTouched && meta.error && <span>Error: {meta.error}</span>}
                </div>
                <div className="billing-details">
                    <h2>Billing Address</h2>
                    <label>Address line 1:</label>
                    <TextField
                        placeholder="Address"
                        value={editObj ? editObj.billing.line1 : address1}
                        onChange={(e) => setAddress1(e.target.value)}
                        inputProps={{ "data-testid": "addressline1" }}
                    />
                    <label>Address line 2:</label>
                    <TextField
                        placeholder="Address"
                        value={editObj ? editObj.billing.line2 : address2}
                        onChange={(e) => setAddress2(e.target.value)}
                        inputProps={{ "data-testid": "addressline2" }}
                    />
                    <label>City:</label>
                    <TextField
                        placeholder="City"
                        value={editObj ? editObj.billing.city : city}
                        onChange={(e) => setCity(e.target.value)}
                        inputProps={{ "data-testid": "city" }}
                    />
                    <label>Zip:</label>
                    <TextField
                        placeholder="ZIP"
                        value={editObj ? editObj.billing.postal_code : zip}
                        onChange={(e) => setZip(e.target.value)}
                        inputProps={{ "data-testid": "zip" }}
                    />
                    <label>Country:</label>
                    <CountryDropdown
                        value={country}
                        valueType="short"
                        //labelType="full"
                        onChange={(val) => setCountry(val)} 
                        //inputProps={{ "data-testid": "country" }}
                        />
                        
                    <RegionDropdown
                        country={country}
                        value={editObj ? editObj.billing.state : state}
                        countryValueType="short"
                        //valueType="short"
                        //labelType="full"
                        onChange={(val) => setState(val)} 
                        //inputProps={{ "data-testid": "region" }}
                        />
                </div>
                <Button
                    variant="contained"
                    className="primary btn"
                    style={{ width: "100%" }}

                    type="submit"
                >
                    Submit
                </Button>
            </form>
        </div >
    );
}