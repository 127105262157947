import React, { useEffect, useState, useCallback } from "react";
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
//import "../../css/List.css";
import SpinnerComponent from '../LoadingSpinner';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import CreateEditProjectRisk from "../CreateEditProjectRisk";
//import '../../css/RiskList.css'
import AddCircleIcon from '@mui/icons-material/AddCircle';

//This class displays lists of risks

const columns = [
    { field: 'id', headerName: 'id', width: 120 },
];

const rows = [
    { id: "n/a" },
];

export async function deleteData(id) {
    var url = `${process.env.REACT_APP_API_URL}/risks/${id}`;

    const response = axios(url, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export async function loadData(projectId) {
    //call data endpoint for data type to set rows and columns
    var url = `${process.env.REACT_APP_API_URL}/projects/risks/${projectId}`;
    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export default function RiskList({
    projectId,
    riskData,
}) {

    //axios error message
    const [errMsg, setErrMsg] = useState("");

    const [rowsData, setRowsData] = useState([]);
    const [colsData, setColsData] = useState(null);

    const [editObj, setEditObj] = useState(null);

    const [loading, setLoading] = useState(false);

    const [openAddDialog, setOpenAddDialog] = useState(null);

    const [selected, setSelected] = useState([]);

    const [openDialog, setOpenDialog] = useState(null);

    const [riskListData, setRiskListData] = useState(null);

    const [isSystemAdmin, setIsSystemAdmin] = useState(null);

    useEffect(() => {
        if (localStorage.getItem("system_role") === "4" ||
            localStorage.getItem("system_role") === "1" ||
            localStorage.getItem("system_role") === "2"
        ) {
            setIsSystemAdmin(true)
        } else {
            setIsSystemAdmin(false)
        }

    }, [setIsSystemAdmin])

    const onRowsSelectionHandler = (ids) => {
        setSelected([...ids]);
    };

    const handleClose = () => {
        setOpenAddDialog(false);
        setOpenDialog(false);
        setEditObj(null);
    }

    const isOpen = () => {
        //setEditObj(null);
        handleClose();
    }

    const addControlData = (data) => {
        var arrcopy = rowsData.filter(row => row.id !== data.id)
        var tmparray = [...arrcopy, data];
        setRowsData(tmparray);
        getData();
    }
    async function deleteSelectedRow() {

        if (selected.length !== 0) {
            for (let x = 0; x < selected.length; x++) {
                await deleteData(selected[x]);
                let filteredArray = rowsData?.filter(item => item.id !== selected[x])
                setRowsData(filteredArray);
            }
        }
    };



    const getData = useCallback(async () => {
        setLoading(true)
        var response;
        if (!riskData) {
            response = await loadData(projectId);
        } else if (riskData.data) {
            response = riskData
        }
        setRiskListData(response.data)
        if (response?.data) {
            var filteredRowDataRisk = [];
            response?.data.forEach((key) => {
                filteredRowDataRisk.push({
                    id: key.id,
                    name: key.name,
                    description: key.description,
                    //tasks: key.tasks,
                    owner_email: key.owner?.email,
                    owner_id: key.owner?.id,
                    risk_impact: key.risk_impact,
                    risk_score: key.risk_score,
                    risk_status: key.risk_status,
                    risk_category: key.risk_category,
                    technology: key.technology,
                    risk_assessment: key.risk_assessment,
                    owner: key.owner,
                    current_likelihood: key.current_likelihood,
                    affected_assets: key.affected_assets,
                    additional_notes: key.additional_notes,
                    audit_test: key.audit_test,
                    control: key.control,
                    risk_mapping: key.risk_mapping,
                    //action: key.control.id,
                });
            })
            setRowsData([...filteredRowDataRisk]);
            var colsValsPrC = [
                {
                    field: 'name',
                    headerName: 'Name',
                    width: 100,
                    renderCell: (params) => (
                        <Link href={`/projects/${projectId}/risks/${params.row.id}`}>{params.value}</Link>
                    )
                },
                {
                    field: 'description',
                    headerName: 'Description',
                    width: 200,
                    cellClass: "normalLineHeight"
                },
                {
                    field: 'owner',
                    headerName: 'Owner',
                    width: 200,
                    renderCell: (params) => {
                        return params.value === null ?
                            <>N/A</>
                            :
                            <Link href={`/users/${params.row.owner_id}`}>{params.row.owner_email}</Link>
                    }
                },
                {
                    field: 'risk_mapping',
                    headerName: 'Mapping',
                    width: 200,
                    renderCell: (params) => {
                        return params.value === null ?
                            <>N/A</>
                            :
                            <>
                                {params.row.risk_mapping === "low" && <><span className="dot-low"></span><span className="mapping">Low</span></>}
                                {params.row.risk_mapping === "low_medium" && <><span className="dot-med-low"></span><span className="mapping">Med-Low</span></>}
                                {params.row.risk_mapping === "medium" && <><span className="dot-med"></span><span className="mapping">Med</span></>}
                                {params.row.risk_mapping === "medium_high" && <><span className="dot-med-high"></span><span className="mapping">Med-High</span></>}
                                {params.row.risk_mapping === "high" && <><span className="dot-high"></span><span className="mapping">High</span></>}
                            </>
                    }
                },
                {
                    field: 'action',
                    headerName: 'Action',
                    width: 200,
                    renderCell: (params) => (
                        <>
                            <Button startIcon={<EditIcon />} onClick={() => { setEditObj(params.row); setOpenAddDialog(true); }} />
                            <Button startIcon={<DeleteIcon />} onClick={() => { setEditObj(params.row); setOpenDialog(true); }} />
                        </>
                    )

                },
            ];
            setColsData([...colsValsPrC]);
        }
        if (!response?.data) {
            setErrMsg(`There was an error retrieving the data`)
        }
        setLoading(false)
    },[projectId, riskData])

    useEffect(() => {
        if (riskListData === null) {
            getData();
        }
    }, [getData, riskListData]);

    return (
        <div style={{ marginTop: '0px' }}>
            <>
                {errMsg ? (
                    <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
                        <Alert severity="error">{errMsg}</Alert>
                    </Stack>
                ) : (
                    <></>
                )}
                {isSystemAdmin && <div className="btns-top-list"><Button
                    variant="contained"
                    className="secondary btn"
                    startIcon={<DeleteIcon />}
                    disabled={!selected.length}
                    onClick={() =>
                        selected.length ? setOpenDialog("delete") : null
                    }
                >
                    Delete
                </Button>
                    <Button
                        variant="outlined"
                        startIcon={<AddCircleIcon />}
                        onClick={() => { setOpenAddDialog(true); }}
                        className="addBtn"
                    >Add</Button>
                </div>}

                <div className="risk-list" style={{ height: '600px', width: '100%', marginTop: '0px' }}>
                    <DataGrid
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        rows={rowsData ? rowsData : rows}
                        columns={colsData ? colsData : columns}
                        rowsPerPageOptions={[10]}
                        pageSize={10}
                        checkboxSelection
                        loading={loading}
                        components={{
                            LoadingOverlay: SpinnerComponent,
                        }}
                        onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                        getRowHeight={() => 'auto'}
                    />
                </div>
                <Dialog
                    //fullScreen
                    open={openAddDialog}
                    onClose={handleClose}
                    aria-labelledby="add-project-data"
                    aria-describedby="alert-dialog-add-project-data"
                >
                    <DialogActions className="create-edit-dialog">
                        <Button onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                    <DialogTitle id="alert-dialog-title">
                        {editObj ? `Edit Risk` : `Add Risk to Project`}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <CreateEditProjectRisk isOpen={isOpen} controlData={addControlData} editDataObj={editObj} projectId={projectId} />
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={openDialog}
                    onClose={handleClose}
                    aria-labelledby="delete-confirm"
                    aria-describedby="alert-dialog-delete-confirm"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Delete?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this? This will also delete any associated audit tests, tasks, evaluations, assessments and documents.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="create-edit-dialog">
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={() => { handleClose(); deleteSelectedRow(); }} autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        </div>
    )

}