import React, { useState, forwardRef } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CreateEdit from './CreateEdit';
import Import from './Import';
import Invite from './InviteUsers';
import FeaturesManagement from './FeaturesManagement';
import Details from '../views/Details';
import '../css/SystemAdministration.css';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupsIcon from '@mui/icons-material/Groups';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import KeyIcon from '@mui/icons-material/Key';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SystemAdministrationCards() {

  // Close dialog
  const handleClose = () => {
    setCreateFrameworkOpen(false);
    setImportFrameworkOpen(false);
    setCreateProjectOpen(false);
    setCreateProjectGroupOpen(false);
    setCreateControlClassOpen(false);
    setCreateControlFamilyOpen(false);
    setCreateControlPhaseOpen(false);
    setCreateKeywordOpen(false);
    setCreateRiskCategoryOpen(false);
    setFeaturesManagementOpen(false);
  };

  const [createFrameworkOpen, setCreateFrameworkOpen] = useState(false);
  const [importFrameworkOpen, setImportFrameworkOpen] = useState(false);
  const [createProjectOpen, setCreateProjectOpen] = useState(false);
  const [createProjectGroupOpen, setCreateProjectGroupOpen] = useState(false);
  const [createControlClassOpen, setCreateControlClassOpen] = useState(false);
  const [createControlFamilyOpen, setCreateControlFamilyOpen] = useState(false);
  const [createControlPhaseOpen, setCreateControlPhaseOpen] = useState(false);
  const [createKeywordOpen, setCreateKeywordOpen] = useState(false);
  const [createRiskCategoryOpen, setCreateRiskCategoryOpen] = useState(false);
  const [featuresManagementOpen, setFeaturesManagementOpen] = useState(false);

  const createFramework = () => {
    setCreateFrameworkOpen(true);
  }

  const importFrameworks = () => {
    setImportFrameworkOpen(true);
  }

  const createProject = () => {
    setCreateProjectOpen(true);
  }

  const createProjectGroup = () => {
    setCreateProjectGroupOpen(true);
  }

  const createControlClass = () => {
    setCreateControlClassOpen(true);
  }

  const createControlFamily = () => {
    setCreateControlFamilyOpen(true);
  }

  const createControlPhase = () => {
    setCreateControlPhaseOpen(true);
  }

  const createKeyword = () => {
    setCreateKeywordOpen(true);
  }

  const createRiskCategory = () => {
    setCreateRiskCategoryOpen(true);
  }

  const featuresManagement = () => {
    setFeaturesManagementOpen(true);
  }


  // Data for system adminstration cards
  const cardsContent = [
    {
      title: "Frameworks",
      description: "Add, manage and import frameworks to build your risk management system.",
      type: "frameworks",
      displayType: "Framework",
      icon: <HighlightAltIcon />,
      buttons: [
        {
          title: "List",
          link: "/frameworks/list",
          tooltip: "List all frameworks",
        },
        {
          title: "Create",
          action: createFramework,
          open: createFrameworkOpen,
          tooltip: "Create a new framework",
        },
        {
          title: "Import",
          action: importFrameworks,
          open: importFrameworkOpen,
          tooltip: "Import frameworks and controls from a spreadsheet",
        },
      ],
    },
    {
      title: "Projects",
      description: "Add and manage your projects to build your risk management system.",
      type: "projects",
      displayType: "Project",
      icon: <SettingsIcon />,
      buttons: [
        {
          title: "List",
          link: "/projects/list",
          tooltip: "List projects",
        },
        {
          title: "Create",
          action: createProject,
          open: createProjectOpen,
          tooltip: "Create a new project",
        },
      ],
    },
    {
      title: "Project Groups",
      description: "Add and manage your project groups to build your risk management system.",
      type: "project_groups",
      displayType: "Project Group",
      icon: <GroupsIcon />,
      buttons: [
        {
          title: "List",
          link: "/project_groups/list",
          tooltip: "List all project groups",
        },
        {
          title: "Create",
          action: createProjectGroup,
          open: createProjectGroupOpen,
          tooltip: "Create a new project group",
        },
      ],
    },
    {
      title: "Control Classes",
      description: "Add and manage your control classes to build your risk management system.",
      type: "control_classes",
      displayType: "Control Class",
      icon: <ControlCameraIcon />,
      buttons: [
        {
          title: "List",
          link: "/control_classes/list",
          tooltip: "List control classes",
        },
        {
          title: "Create",
          action: createControlClass,
          open: createControlClassOpen,
          tooltip: "Create a new control class",
        },
      ],
    },
    {
      title: "Control Families",
      description: "Add and manage your control families to build your risk management system.",
      type: "control_families",
      displayType: "Control Family",
      icon: <PeopleOutlineIcon />,
      buttons: [
        {
          title: "List",
          link: "/control_families/list",
          tooltip: "List control families",
        },
        {
          title: "Create",
          action: createControlFamily,
          open: createControlFamilyOpen,
          tooltip: "Create a new control family",
        },
      ],
    },
    {
      title: "Control Phases",
      description: "Add and manage your control phases to build your risk management system.",
      type: "control_phases",
      displayType: "Control Phase",
      icon: <NightsStayIcon />,
      buttons: [
        {
          title: "List",
          link: "/control_phases/list",
          tooltip: "List control phases",
        },
        {
          title: "Create",
          action: createControlPhase,
          open: createControlPhaseOpen,
          tooltip: "Create a new control phase",
        },
      ],
    },
    {
      title: "Keywords",
      description: "Add and manage your keywords to build your risk management system.",
      type: "keyword",
      displayType: "Keyword",
      icon: <KeyIcon />,
      buttons: [
        {
          title: "List",
          link: "/keyword/list",
          tooltip: "List keywords",
        },
        {
          title: "Create",
          action: createKeyword,
          open: createKeywordOpen,
          tooltip: "Create a new keyword",
        },
      ],
    },
    {
      title: "Subscription",
      description: "Manage your subscription plan, users and billing details.",
      type: "subscriptions",
      icon: <CardMembershipIcon />,
      buttons: [
        {
          title: "Details",
          link: "/subscription",
          tooltip: "Manage your subscription plan, users and billing details",
        },
      ],
    },
    {
      title: "Risk Categories",
      description: "Add and manage your risk categories to build your risk management system.",
      type: "risk_categories",
      displayType: "Risk Category",
      icon: <ReportGmailerrorredIcon />,
      buttons: [
        {
          title: "List",
          link: "/risk_categories/list",
          tooltip: "List all risk categories",
        },
        {
          title: "Create",
          action: createRiskCategory,
          open: createRiskCategoryOpen,
          tooltip: "Create a new risk category",
        },
      ],
    },
    {
      title: "Features",
      description: "Manage your Fedrisk features to build your risk management system.",
      type: "features",
      displayType: "Features",
      icon: <AddCircleOutline />,
      buttons: [
        {
          title: "Manage",
          action: featuresManagement,
          open: featuresManagementOpen,
          tooltip: "Enable and disable Fedrisk features",
        },
      ],
    }
  ];

  const cards = cardsContent.map(
    (card, i) => {
      return (
        <div className={`cards id-${i}`}>
          <Card sx={{ minWidth: 200 }}>
            <CardContent>
              {card.icon}
              <Typography variant="h5" component="div">
                {card.title}
              </Typography>
              <Typography sx={{ fontSize: 14 }} color="text.secondary">
                {card.description}
              </Typography>
            </CardContent>
            <CardActions>
              {card.buttons.map((button, j) => (
                <Link href={button.link}>
                  <Tooltip title={button.tooltip}>
                    <Button onClick={button.action} variant="contained">{button.title}</Button>
                  </Tooltip>
                </Link>
              ))}
            </CardActions>
          </Card>
        </div>
      )
    }
  )

  const dialogs = cardsContent.map(
    (card, x) => {
      return (
        <div className={`dialogs id-${x}`}>
          {card.buttons.map((button, j) => (
            <Dialog
              //fullScreen
              open={button.open ? button.open : false}
              TransitionComponent={Transition}
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
              className={`dialog-${button.action}-${j}`}
            >
              <DialogActions className="create-edit-dialog">
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
              <DialogTitle>{button.title} {card.displayType}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {
                      (button.title === "Create" && <CreateEdit dataType={card.type} editDataObj={null} />) ||
                      (button.title === "Import" && <Import dataType={card.type} />) ||
                      (button.title === "Invite" && <Invite />) ||
                      (button.title === "Details" && <Details />) ||
                      (button.title === "Manage" && <FeaturesManagement />)
                    }
                </DialogContentText>
              </DialogContent>
            </Dialog>
          ))}
        </div>
      )
    }
  )

  return (
    <div className='system-administration'>
      <div id='cards'>
        {cards}
      </div>
      <div id='dialogs'>
        {dialogs}
      </div>
    </div>
  );

}