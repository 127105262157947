import React, { useState, useMemo, useCallback, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import { MenuItem, Select } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import axios from 'axios';
import DatePicker from "react-multi-date-picker";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

/* This class is specific to managing a control that is added to a framework. */

export default function CreateEditFrameworkVersion({ editDataObj, isOpen }) {
    //default option when no value selected in drop down
    const selectOption = useMemo(() => { return { value: "0", label: "Please select..." } }, [])
    const [frameworkId, setFrameworkId] = useState(editDataObj?.framework_id)
    const [frameworks, setFrameworks] = useState([])
    const { control, handleSubmit } = useForm({
        defaultValues: {
            frameworkSelect: {},
            versionPrefix: editDataObj?.version_prefix,
            versionSuffix: editDataObj?.version_suffix,
            releaseDate: editDataObj?.release_date,
            guidance: editDataObj?.guidance,
            newKeywords: "",
            keywordSelect: {},
        }
    });

    const [errMsg, setErrMsg] = useState("");

    useEffect(() => {
    }, [errMsg])

    const [keywords, setKeywords] = useState([{ id: "0", name: "Please select..." }]);;

    const [keywordSelections, setKeywordSelections] = useState([]);

    const [keywordId, setKeywordId] = useState();
    const keywordSelectOption = useMemo(() => { return { id: "0", name: "Please select..." } }, [])

    useEffect(() => {
        if (editDataObj?.keywords) {
            let options = []
            for (let i = 0; i < editDataObj?.keywords.length; i++) {
                options.push({ id: editDataObj?.keywords[i].id, name: editDataObj?.keywords[i].keyword.name })
            }
            setKeywordSelections(options)
        }
    }, [editDataObj])

    async function getKeywords() {
        const url = `${process.env.REACT_APP_API_URL}/keyword/`
        const response = axios(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.access_token}`,
            },
        }).then(result => {
            return result;
        }).catch(error => { return error.message; })
        return response;
    }

    useEffect(() => {
    }, [errMsg])

    const removeKeyword = (key) => {
        var tmpfilter = keywordSelections.filter(keyword => keyword.id !== key.id)
        setKeywordSelections(tmpfilter);
        var keyword = keywordSelections.filter(word => word.id === key.id)
        var name = keyword[0].name
        setKeywords(keywords => [...keywords, { name: name, id: key.id }]);
    }

    const handleChangeKeywordSelect = (event) => {
        setKeywordId(event.target.value);
        var keyword = keywords.filter(word => word.id === event.target.value)
        var name = keyword[0].name
        setKeywordSelections(keywordSelections => [...keywordSelections, { name: name, id: event.target.value }]);
        var tmpfilterSelect = keywords.filter(keyword => keyword.id !== event.target.value)
        setKeywords(tmpfilterSelect)
    }

    const onSubmit = async (data) => {

        const response = await postData(data);
        isOpen(false);

        //window.location.reload(false);
        if (response.error) {
            alert(`There was an error: ${JSON.stringify(response)}`)
        }

        //window.location.replace(`/frameworks/${frameworkVersionId}/controls`);
    }
    async function postData(data) {
        //look at keywords
        if (!/^[a-z,]*$/.test(data?.newKeywords)) {
            setErrMsg(`Sorry your keywords contain values other than lowercase characters and commas`)
        } else {
            //clean up duplicate vals
            var cleanedKeywords = []
            var enteredKeywords = data.newKeywords.split(',')
            for (let x = 0; x < enteredKeywords.length; x++) {
                let match = false
                for (let y = 0; y < keywordSelections.length; y++) {
                    if (enteredKeywords[x] === keywordSelections[y].name) {
                        match = true
                    }
                }
                if (match === false) {
                    cleanedKeywords.push(enteredKeywords[x])
                }
            }
            for (let p = 0; p < keywordSelections.length; p++) {
                cleanedKeywords.push(keywordSelections[p].name)
            }
            var cleanedWords = cleanedKeywords.join(',')
            var url = `${process.env.REACT_APP_API_URL}/framework_versions/?keywords=${cleanedWords}`;
            var body = JSON.stringify(
                {
                    framework_id: frameworkId,
                    version_prefix: data.versionPrefix,
                    version_suffix: data.versionSuffix,
                    guidance: data.guidance,
                    release_date: data.releaseDate,
                })
            var method = "POST";
            //if a data object is being edited
            if (editDataObj) {
                method = "PUT";
                url = `${process.env.REACT_APP_API_URL}/framework_versions/${editDataObj.id}?keywords=${cleanedWords}`;
            }
            const response = axios(url, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.access_token}`,
                },
                data: body,
            }).then(result => {
                return result;
            }).catch(error => { return error.message; })
            return response;
        }
    }

    async function getDropdownData() {
        var url = `${process.env.REACT_APP_API_URL}/frameworks/?offset=0&limit=100`

        try {
            const response = axios(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.access_token}`,
                },
            })
            return response;
        } catch (err) {
            if (!err?.response) {
                //setErrMsg("No Server Response");
            } else if (err.response?.status) {
                const status = String(err.response?.status)
                if (status === "422") {
                    //setErrMsg(`Validation error.`)
                } else {
                    //setErrMsg(`There was a ${err.response?.status} error`)
                }
            } else {
                //setErrMsg("There was an error")
            }
        }
    }

    const getDropDownOptions = useCallback(async () => {
        const jsonDataKey = await getKeywords();
        if (jsonDataKey?.data != null) {
            const tmpoptions = jsonDataKey.data.map((word) => ({
                id: word.id,
                name: word.name,
            }));
            var allOptions = [keywordSelectOption, ...tmpoptions];
            setKeywords(allOptions);
        }
        const jsonData = await getDropdownData();
        if (jsonData?.data != null) {
            const tmpoptions = jsonData.data.items.map((item) => ({
                value: item.id,
                label: item.name,
            }));
            setFrameworks([selectOption, ...tmpoptions]);
        }

    }, [selectOption, keywordSelectOption])

    // handle change events on dropdowns
    const handleChangeFrameworkSelect = (event) => {
        setFrameworkId(event.target.value);
    }

    useEffect(() => {
        if (frameworks.length === 0) {
            getDropDownOptions();
        }
    }, [getDropDownOptions, frameworks.length]);

    return (
        <div className="create edit frameworkversion">
            {errMsg ? (
                <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
                    <Alert severity="error">{errMsg}</Alert>
                </Stack>
            ) : (
                <></>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-field framework">
                    <label>Framework</label>
                    <Controller
                        name="frameworkSelect"
                        control={control}
                        defaultValue={0}
                        render={({ field }) =>
                            <Select
                                {...field}
                                value={frameworkId ? frameworkId : 0}
                                defaultValue={0}
                                inputProps={{ "data-testid": "framework" }}
                                onChange={handleChangeFrameworkSelect}
                            >
                                {
                                    frameworks?.map((fr) => {
                                        return <MenuItem
                                            value={fr.value}
                                            key={fr.value}>
                                            {fr.label}
                                        </MenuItem>
                                    })
                                }
                            </Select>
                        }
                    />
                </div>
                <div className="form-field prefix">
                    <Controller
                        name="versionPrefix"
                        control={control}
                        render={({ field }) => <TextField label="Version Prefix" {...field} fullWidth />}
                    />
                </div>
                <div className="form-field suffix">
                    <Controller
                        name="versionSuffix"
                        control={control}
                        render={({ field }) => <TextField label="Version Suffix" {...field} fullWidth />}
                    />
                </div>
                <div className="form-field guidance">
                    <Controller
                        name="guidance"
                        control={control}
                        render={({ field }) => <TextField label="Guidance" {...field} fullWidth />}
                    />
                </div>
                <div className="form-field date">
                    <label>Release Date</label>
                    <Controller
                        control={control}
                        name="releaseDate"
                        rules={{ required: false }} //optional
                        render={({
                            field: { onChange, name, value },
                            //fieldState: { invalid, isDirty }, //optional
                            //formState: { errors }, //optional, but necessary if you want to show an error message
                        }) => (
                            <>
                                <DatePicker
                                    value={value || ""}
                                    onChange={(date) => {
                                        onChange(date?.isValid ? date : "");
                                    }}
                                />
                            </>
                        )}
                    />
                </div>
                <div className="form-field keywords">
                    <div className="form-field control">
                        <label>Existing Keywords</label>
                        <Controller
                            name="keywordSelect"
                            control={control}
                            defaultValue={0}
                            render={({ field }) =>
                                <Select
                                    {...field}
                                    value={keywordId ? keywordId : 0}
                                    defaultValue={0}
                                    inputProps={{ "data-testid": "keyword" }}
                                    onChange={handleChangeKeywordSelect}
                                >
                                    {
                                        keywords?.map((word) => {
                                            return <MenuItem
                                                value={word.id}
                                                key={word.id}>
                                                {word.name}
                                            </MenuItem>
                                        })
                                    }
                                </Select>
                            }
                        />
                    </div>
                    <ul>
                        {(keywordSelections && (
                            keywordSelections.map((word) => {
                                return <li>
                                    {word.name}
                                    <IconButton aria-label="delete">
                                        <DeleteIcon onClick={() => { removeKeyword(word); }} />
                                    </IconButton>
                                </li>
                            })
                        )
                        )}
                    </ul>
                    <div className="form-field newkeywords">
                        <Controller
                            name="newKeywords"
                            control={control}
                            render={({ field }) =>
                                <TextField
                                    {...field}
                                    label="Add new keywords"
                                    //fullWidth
                                    helperText="Enter your keyword in all lowercase with a comma and no spaces between each one e.g. legal,technical"
                                />

                            }
                        />
                    </div>
                </div>
                <Button type="submit" variant="contained" >{editDataObj ? 'Update' : 'Add'}</Button>
            </form>
        </div>
    );
}