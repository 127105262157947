import * as auto_scheduling from "./auto_scheduling";
import click_drag from "./click_drag";
import * as critical_path from "./critical_path";
import drag_timeline from "./drag_timeline";
import export_api from "./export_api";
import fullscreen from "./fullscreen";
import * as grouping from "./grouping";
import * as keyboard_navigation from "./keyboard_navigation";
import * as marker from "./marker";
import * as multiselect from "./multiselect";
import * as overlay from "./overlay";
import quick_info from "./quick_info";
import tooltip from "./tooltip";
import undo from "./undo";

export default {
	auto_scheduling,
	click_drag,
	critical_path,
	drag_timeline,
	fullscreen,
	keyboard_navigation,
	quick_info,
	tooltip,
	undo,
	grouping,
	marker,
	multiselect,
	overlay,
	export_api
};