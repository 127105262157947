import React from "react";
import '../css/Register.css';
import Box from '@mui/material/Box';
import Logo from '../assets/fedrisk-full-logo.png';
import PaymentMethod from "./PaymentMethod";
import RegisterPlanInvoice from "./RegisterPlanInvoice";

export default function RegisterPaymentMethod() {

    return (

        <div className="register-wrapper payment method">
            <Box
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '100%' },
                }}
            >
                <img src={Logo} alt="Logo" width="481px" />
                <h1>Invoice Summary</h1>
                <RegisterPlanInvoice />
                <PaymentMethod registering={true} />
                <div className="login-here">Already have an account? <a href="/">Log In</a></div>
            </Box>
        </div>
    )
}