import React, { useEffect, useState, useCallback, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import { MenuItem, Select } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import axios from 'axios';
import DatePicker from "react-multi-date-picker";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

/* This class is for creating and editing a task on a project */

export async function getKeywords() {
  const url = `${process.env.REACT_APP_API_URL}/keyword/`
  const response = axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export async function getUsers() {
  const url = `${process.env.REACT_APP_API_URL}/users/get_users_tenant`
  const response = axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export async function getDropdownData(type, id) {
  var url;
  if (type === "audit_tests" || type === "risks") {
    url = `${process.env.REACT_APP_API_URL}/${type}/?project_id=${id}`;
  }
  if (type === "project_controls") {
    url = `${process.env.REACT_APP_API_URL}/projects/${type}/${id}`;
  }
  if (type === "projects") {
    url = `${process.env.REACT_APP_API_URL}/projects/tenant/`;
  }
  if (type === "wbs") {
    url = `${process.env.REACT_APP_API_URL}/wbs/project/${id}`;
  }
  const response = axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error; })
  return response;
}

export default function CreateEditProjectTask({ isWBSTaskList, editDataObj, projId, isOpen, controlData, parentTask, wbs_id }) {
  //console.log(editDataObj)

  //used with users
  const [users, setUsers] = useState([]);

  //used with select fields in form
  const [projects, setProjects] = useState([]);

  //used with select fields in form
  const [wbs, setWbs] = useState([]);

  const [projectId, setProjectId] = useState(projId);
  const [wbsId, setWbsId] = useState(`${editDataObj?.wbs ? editDataObj?.wbs?.id : 0}`);
  const [assignedTo, setAssignedTo] = useState(editDataObj?.assigned?.id);
  const [taskCategoryId, setTaskCategoryId] = useState(editDataObj?.category);
  const [taskStatusId, setTaskStatusId] = useState(editDataObj?.status);
  const [taskPriorityId, setTaskPriorityId] = useState(editDataObj?.priority);
  const [projectControlId, setProjectControlId] = useState(null);
  const [projectControls, setProjectControls] = useState(null);
  const [projectControlSelections, setProjectControlSelections] = useState([]);
  const [auditTestId, setAuditTestId] = useState(null);
  const [auditTests, setAuditTests] = useState(null);
  const [auditTestSelections, setAuditTestSelections] = useState([]);
  const [riskId, setRiskId] = useState(null);
  const [risks, setRisks] = useState(null);
  const [riskSelections, setRiskSelections] = useState([]);
  const [newKeywords, setNewKeywords] = useState("")
  const [errMsg, setErrMsg] = useState("");
  const [isMilestone, setIsMilestone] = useState(editDataObj?.milestone);

  const handleChange = (event) => {
    setIsMilestone(event.target.value);
  };

  const [keywords, setKeywords] = useState([{ id: "0", name: "Please select..." }]);;

  const [keywordSelections, setKeywordSelections] = useState([]);

  const [keywordId, setKeywordId] = useState();
  const keywordSelectOption = useMemo(() => { return { id: "0", name: "Please select..." } }, [])

  useEffect(() => {
    if (editDataObj?.keywords) {
      let options = []
      for (let i = 0; i < editDataObj?.keywords.length; i++) {
        options.push({ id: editDataObj?.keywords[i].id, name: editDataObj?.keywords[i].keyword.name })
      }
      setKeywordSelections(options)
    }
  }, [editDataObj])

  useEffect(() => {
    if (editDataObj?.risks) {
      let options = []
      for (let i = 0; i < editDataObj?.risks.length; i++) {
        options.push({ value: editDataObj?.risks[i].id, label: editDataObj?.risks[i].name })
      }
      setRiskSelections(options)
    }
  }, [editDataObj])

  useEffect(() => {
    if (editDataObj?.project_controls) {
      let options = []
      for (let i = 0; i < editDataObj?.project_controls.length; i++) {
        options.push({ value: editDataObj?.project_controls[i].id, label: editDataObj?.project_controls[i].control.name })
      }
      setProjectControlSelections(options)
    }
  }, [editDataObj])

  useEffect(() => {
    if (editDataObj?.audit_tests) {
      let options = []
      for (let i = 0; i < editDataObj?.audit_tests.length; i++) {
        options.push({ value: editDataObj?.audit_tests[i].id, label: editDataObj?.audit_tests[i].name })
      }
      setAuditTestSelections(options)
    }
  }, [editDataObj])

  useEffect(() => {
    if (wbs_id !== null) {
      setWbsId(wbs_id)
    }
  }, [wbsId, wbs_id])

  const removeKeyword = (key) => {
    var tmpfilter = keywordSelections.filter(keyword => keyword.id !== key.id)
    setKeywordSelections(tmpfilter);
    var keyword = keywordSelections.filter(word => word.id === key.id)
    var name = keyword[0].name
    setKeywords(keywords => [...keywords, { name: name, id: key.id }]);
  }

  const removeAuditTest = (test) => {
    var tmpfilter = auditTestSelections.filter(atest => atest.value !== test.value)
    setAuditTestSelections(tmpfilter);
    var testval = auditTestSelections.filter(atest => atest.value === test.value)
    var label = testval[0].label
    setAuditTests(auditTests => [...auditTests, { label: label, value: test.value }]);
  }

  const removeProjectControl = (control) => {
    var tmpfilter = projectControlSelections.filter(pc => pc.value !== control.value)
    setProjectControlSelections(tmpfilter);
    var val = projectControlSelections.filter(pro => pro.value === control.value)
    var label = val[0].label
    setProjectControls(projectControls => [...projectControls, { label: label, value: control.value }]);
  }

  const removeRisk = (risk) => {
    var tmpfilter = riskSelections.filter(r => r.value !== risk.value)
    setRiskSelections(tmpfilter);
    var val = riskSelections.filter(p => p.value === risk.value)
    var label = val[0].label
    setRisks(risks => [...risks, { label: label, value: risk.value }]);
  }

  const handleChangeKeywordSelect = (event) => {
    setKeywordId(event.target.value);
    var keyword = keywords.filter(word => word.id === event.target.value)
    var name = keyword[0].name
    setKeywordSelections(keywordSelections => [...keywordSelections, { name: name, id: event.target.value }]);
    var tmpfilterSelect = keywords.filter(keyword => keyword.id !== event.target.value)
    setKeywords(tmpfilterSelect)
  }

  //default option when no value selected in drop down
  const selectOption = useMemo(() => { return { value: "0", label: "Please select..." } }, [])
  const userSelectOption = useMemo(() => { return { id: 0, email: "Please select..." } }, [])

  const taskCategory = [
    selectOption,
    { value: "Frameworks", label: "Frameworks" },
    { value: "Projects", label: "Projects" },
    { value: "Controls", label: "Controls" },
    { value: "Assessments", label: "Assessments" },
    { value: "Ad-hoc Assessments", label: "Ad-hoc Assessments" },
    { value: "Project Evaluations", label: "Project Evaluations" },
    { value: "Risks", label: "Risks" },
  ]

  const taskStatus = [
    selectOption,
    { value: "Not Started", label: "Not Started" },
    { value: "In Progress", label: "In Progress" },
    { value: "Complete", label: "Complete" },
  ]

  const taskPriority = [
    selectOption,
    { value: "Low", label: "Low" },
    { value: "Medium", label: "Medium" },
    { value: "High", label: "High" },
    { value: "Immediate", label: "Immediate" },
  ]

  const { control, handleSubmit } = useForm({
    defaultValues: {
      title: editDataObj?.name,
      name: editDataObj?.name,
      actual_start_date: editDataObj?.actual_start_date,
      actual_end_date: editDataObj?.actual_end_date,
      description: editDataObj?.description,
      duration: editDataObj?.duration,
      estimated_loe: editDataObj?.estimated_loe,
      actual_loe: editDataObj?.actual_loe,
      estimated_cost: editDataObj?.estimated_cost,
      actual_cost: editDataObj?.actual_cost,
      percent_complete: editDataObj?.percent_complete,
      milestone: editDataObj?.milestone,
      //child_task_order: editDataObj?.child_task_order,
      projectSelect: {},
      wbsSelect: {},
      userSelect: {},
      taskCategorySelect: {},
      taskStatusSelect: {},
      taskPrioritySelect: {},
      projectControlSelect: {},
      auditTestSelect: {},
      riskSelect: {},
      dueDate: editDataObj?.due_date,
      comments: editDataObj?.comments,
      keywordSelect: {},
    }
  });

  // handle change events on dropdowns
  const handleChangeProjectSelect = async (event) => {
    setProjectId(event.target.value);
    const jsonData = await getDropdownData("audit_tests", event.target.value);
    const auditTestOptions = jsonData.data.items.map((at) => ({
      value: at.id,
      label: at.name,
    }));
    setAuditTests([selectOption, ...auditTestOptions]);
    const jsonDatarisks = await getDropdownData("risks", event.target.value);
    const riskOptions = jsonDatarisks.data.items.map((risk) => ({
      value: risk.id,
      label: risk.name,
    }));
    setRisks([selectOption, ...riskOptions]);
    const jsonDatapcs = await getDropdownData("project_controls", event.target.value);
    const pcOptions = jsonDatapcs.data.items.map((pc) => ({
      value: pc.id,
      label: pc.control.name,
    }));
    setProjectControls([selectOption, ...pcOptions]);
  }

  const handleChangeWBSSelect = (event) => {
    setWbsId(event.target.value);
  }

  const handleChangeUserSelect = (event) => {
    setAssignedTo(event.target.value);
  }

  const handleChangeTaskCategorySelect = (event) => {
    setTaskCategoryId(event.target.value);
  }

  const handleChangeTaskStatusSelect = (event) => {
    setTaskStatusId(event.target.value);
  }

  const handleChangeTaskPrioritySelect = (event) => {
    setTaskPriorityId(event.target.value);
  }
  const handleChangeProjectControlSelect = (event) => {
    setProjectControlId(event.target.value);
    var test = projectControls.filter(pc => pc.value === event.target.value)
    var label = test[0].label
    setProjectControlSelections(projectControlSelections => [...projectControlSelections, { label: label, value: event.target.value }]);
    var tmpfilterSelect = projectControls.filter(at => at.value !== event.target.value)
    setProjectControls(tmpfilterSelect)
  }
  const handleChangeAuditTestSelect = (event) => {
    setAuditTestId(event.target.value);
    var test = auditTests.filter(at => at.value === event.target.value)
    var label = test[0].label
    setAuditTestSelections(auditTestSelections => [...auditTestSelections, { label: label, value: event.target.value }]);
    var tmpfilterSelect = auditTests.filter(at => at.value !== event.target.value)
    setAuditTests(tmpfilterSelect)
  }
  const handleChangeRiskSelect = (event) => {
    setRiskId(event.target.value);
    var test = risks.filter(pc => pc.value === event.target.value)
    var label = test[0].label
    setRiskSelections(riskSelections => [...riskSelections, { label: label, value: event.target.value }]);
    var tmpfilterSelect = risks.filter(at => at.value !== event.target.value)
    setRisks(tmpfilterSelect)
  }
  const handleChangeNewKeywords = (event) => {
    setNewKeywords(event.target.value)
  }

  const convertToSendDate = (date) => {
    return `${new Date(date).toISOString().split("T")[0]}`;
  };

  /* form submit */
  const onSubmit = async (data) => {
    //var datastore = data;
    const response = await postFormData(data);
    var datastore = response.data;
    controlData(datastore);
    if (response.data) {
      isOpen(false);
      //window.location.reload(false);
    }
  }

  async function postFormData(data) {
    if (!/^[a-z,]*$/.test(newKeywords)) {
      setErrMsg(`Sorry your keywords contain values other than lowercase characters and commas`)
    } else {
      //clean up duplicate vals
      var cleanedKeywords = []
      var enteredKeywords = newKeywords.split(',')
      for (let x = 0; x < enteredKeywords.length; x++) {
        let match = false
        for (let y = 0; y < keywordSelections.length; y++) {
          if (enteredKeywords[x] === keywordSelections[y].name) {
            match = true
          }
        }
        if (match === false) {
          cleanedKeywords.push(enteredKeywords[x])
        }
      }
      for (let p = 0; p < keywordSelections.length; p++) {
        cleanedKeywords.push(keywordSelections[p].name)
      }

      var cleanedWords = cleanedKeywords.join(',')
      var url;
      var body;
      var method = "POST";
      var parentTasksArr = []
      if (parentTask && parentTask.length !== 0) {
        parentTasksArr.push(parseInt(parentTask))
      }
      var risksArr = []
      var projControlsArr = []
      var auditArr = []
      for (var x = 0; x < riskSelections.length; x++) {
        risksArr.push(riskSelections[x].value)
      }
      for (var l = 0; l < projectControlSelections.length; l++) {
        projControlsArr.push(projectControlSelections[l].value)
      }
      for (var y = 0; y < auditTestSelections.length; y++) {
        auditArr.push(auditTestSelections[y].value)
      }
      //format timestamps to date string
      let due_date = convertToSendDate(data.dueDate)
      /* project audit_tests */
      if (cleanedWords !== "") {
        url = `${process.env.REACT_APP_API_URL}/tasks/task?keywords=${cleanedWords}`
      } else {
        url = `${process.env.REACT_APP_API_URL}/tasks/task`
      }

      body = {
        title: data.name,
        name: data.name,
        description: data.description,
        project_id: projectId,
        wbs_id: wbsId,
        user_id: localStorage.getItem("userid"),
        status: taskStatusId,
        priority: taskPriorityId,
        due_date: due_date,
        actual_start_date: data.actual_start_date,
        actual_end_date: data.actual_end_date,
        duration: data.duration,
        percent_complete: data.percent_complete,
        milestone: isMilestone,
        assigned_to: assignedTo,
        estimated_loe: data.estimated_loe,
        actual_loe: data.actual_loe,
        estimated_cost: data.estimated_cost,
        actual_cost: data.actual_cost,
        category: taskCategoryId,
        //child_task_order: data.child_task_order,
        attachments: [],
        risks: risksArr,
        audit_tests: auditArr,
        project_controls: projControlsArr,
        children: [],
        parents: parentTasksArr,
      }
      //if a data object is being edited
      if (editDataObj) {
        method = "PUT";
        if (cleanedWords !== "") {
          url = `${process.env.REACT_APP_API_URL}/tasks/${editDataObj.id}?keywords=${cleanedWords}`;
        } else {
          url = `${process.env.REACT_APP_API_URL}/tasks/${editDataObj.id}`;
        }
        body = {
          title: data.name,
          name: data.name,
          description: data.description,
          project_id: projectId,
          wbs_id: wbsId,
          user_id: localStorage.getItem("userid"),
          status: taskStatusId,
          priority: taskPriorityId,
          due_date: due_date,
          actual_start_date: data.actual_start_date,
          actual_end_date: data.actual_end_date,
          duration: data.duration,
          percent_complete: data.percent_complete,
          milestone: isMilestone,
          assigned_to: assignedTo,
          estimated_loe: data.estimated_loe,
          actual_loe: data.actual_loe,
          estimated_cost: data.estimated_cost,
          actual_cost: data.actual_cost,
          category: taskCategoryId,
          //child_task_order: data.child_task_order,
          attachments: [],
          risks: risksArr,
          audit_tests: auditArr,
          project_controls: projControlsArr,
          children: [],
          parents: parentTasksArr,
          comments: data.comments,
        }

      }

      const response = axios(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.access_token}`,
        },
        data: JSON.stringify(body),
      }).then(result => {
        return result;
      }).catch(error => { return error.message; })
      return response;
    }
  }

  const getDropDownOptions = useCallback(async () => {
    const jsonDataKey = await getKeywords();
    if (jsonDataKey.data) {
      const tmpoptionskeys = jsonDataKey?.data?.map((word) => ({
        id: word.id,
        name: word.name,
      }));
      var allOptions = [keywordSelectOption, ...tmpoptionskeys];
      setKeywords(allOptions);
    }

    const jsonData = await getDropdownData("projects");
    if (jsonData.data) {
      const projectOptions = jsonData.data.map((project) => ({
        value: project.id,
        label: project.name,
      }));
      setProjects([selectOption, ...projectOptions]);
    }

    const jsonDataUsers = await getUsers();
    if (jsonDataUsers.data) {
      const tmpoptions = jsonDataUsers.data.map((user) => ({
        id: user.id,
        email: user.email,
      }));
      setUsers([userSelectOption, ...tmpoptions]);
    }

  }, [selectOption, userSelectOption, keywordSelectOption])

  const getWbsDropDownOptions = useCallback(async (id) => {
    const jsondataWBS = await getDropdownData("wbs", id);
    if (jsondataWBS.message !== "Request failed with status code 404") {
      const wbsoptions = jsondataWBS?.data?.map((wbs) => ({
        value: wbs.id,
        label: wbs.name,
      }));
      setWbs([selectOption, ...wbsoptions]);
    } else {
      setWbs([{ value: "0", label: "Please select..." }])
      setWbsId(0)
    }

    if (editDataObj) {
      const jsonData = await getDropdownData("audit_tests", projectId);
      const auditTestOptions = jsonData.data.items.map((at) => ({
        value: at.id,
        label: at.name,
      }));
      setAuditTests([selectOption, ...auditTestOptions]);
      const jsonDatarisks = await getDropdownData("risks", projectId);
      const riskOptions = jsonDatarisks.data.items.map((risk) => ({
        value: risk.id,
        label: risk.name,
      }));
      setRisks([selectOption, ...riskOptions]);
      const jsonDatapcs = await getDropdownData("project_controls", projectId);
      const pcOptions = jsonDatapcs.data.items.map((pc) => ({
        value: pc.id,
        label: pc.control.name,
      }));
      setProjectControls([selectOption, ...pcOptions]);
    }

  }, [selectOption, editDataObj, projectId])

  useEffect(() => {
    //if (projects.length === 0) {
    getDropDownOptions();
    //}

  }, [getDropDownOptions]);

  useEffect(() => {

  }, [editDataObj])

  useEffect(() => {
    if (projectId) {
      getWbsDropDownOptions(projectId);
    }
  }, [projectId, getWbsDropDownOptions])

  return (
    <div className="create edit task">
      {errMsg ? (
        <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
          <Alert severity="error">{errMsg}</Alert>
        </Stack>
      ) : (
        <></>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-field name">
          <Controller
            name="name"
            control={control}
            render={({ field }) => <TextField required label="Name" {...field} fullWidth />}
          />
        </div>

        <div className="form-field description">
          <Controller
            name="description"
            control={control}
            render={({ field }) =>
              <TextField
                {...field}
                multiline
                rows={4}
                label="Description"
                fullWidth
              />
            }
          />
        </div>

        <div className="form-field percent_complete">
          <Controller
            name="percent_complete"
            control={control}
            render={({ field }) => <TextField inputProps={{ type: 'number' }} label="Percent complete" {...field} fullWidth />}
          />
        </div>

        {editDataObj ? <div className="form-field comments">
          <Controller
            name="comments"
            control={control}
            render={({ field }) =>
              <TextField
                {...field}
                multiline
                rows={4}
                label="Comments"
                fullWidth
                required
              />
            }
          />
        </div> : <></>}

        <div className="form-field date">
          <label>Due Date</label>
          <Controller
            control={control}
            name="dueDate"
            rules={{ required: true }} //optional
            render={({
              field: { onChange, name, value },
              //fieldState: { invalid, isDirty }, //optional
              formState: { errors }, //optional, but necessary if you want to show an error message
            }) => (
              <>
                <DatePicker
                  value={value || ""}
                  onChange={(date) => {
                    onChange(date?.isValid ? date : "");
                  }}
                />
                {errors && errors[name] && errors[name].type === "required" && (
                  //if you want to show an error message
                  <span>The due date is required!</span>
                )}
              </>
            )}
          />
        </div>

        <FormControl>
          <FormLabel id="demo-controlled-radio-buttons-group">Milestone</FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={isMilestone}
            onChange={handleChange}
          >
            <FormControlLabel value="true" control={<Radio />} label="Yes" />
            <FormControlLabel value="false" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>

        {wbs_id && !isWBSTaskList && <><div className="form-field actual-start-date">
          <label>Actual Start Date</label>
          <Controller
            name="actual_start_date"
            control={control}
            render={({ field }) => <DatePicker label="Actual start date" {...field} fullWidth />}
          />
        </div>

          <div className="form-field actual-end-date">
            <label>Actual End Date</label>
            <Controller
              name="actual_end_date"
              control={control}
              render={({ field }) => <DatePicker label="Actual end date" {...field} fullWidth />}
            />
          </div>

          <div className="form-field duration">
            <Controller
              name="duration"
              control={control}
              render={({ field }) => <TextField type="number" label="Duration" {...field} fullWidth />}
            />
          </div>

          <div className="form-field estimated_loe">
            <Controller
              name="estimated_loe"
              control={control}
              render={({ field }) => <TextField label="Estimated level of effort" {...field} fullWidth />}
            />
          </div>

          <div className="form-field actual_loe">
            <Controller
              name="actual_loe"
              control={control}
              render={({ field }) => <TextField label="Actual level of effort" {...field} fullWidth />}
            />
          </div>

          <div className="form-field estimated_cost">
            <InputLabel htmlFor="outlined-adornment-amount">Estimated Cost</InputLabel>
            <Controller
              name="estimated_cost"
              control={control}
              render={({ field }) => <OutlinedInput
                type="number"
                id="outlined-adornment-amount"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                inputProps={{
                  step: 0.50,
                }}
                {...field}
              />}
            />
          </div>

          <div className="form-field actual_cost">
            <InputLabel htmlFor="outlined-adornment-amount">Actual Cost</InputLabel>
            <Controller
              name="actual_cost"
              control={control}
              render={({ field }) => <OutlinedInput
                type="number"
                id="outlined-adornment-amount"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                inputProps={{
                  step: 0.50,
                }}
                {...field}
              />}
            />
          </div>
        </>}

        {/*<div className="form-field child_task_order">
          <Controller
            name="child_task_order"
            control={control}
            render={({ field }) => <TextField inputProps={{ type: 'number' }} label="Child task order" {...field} fullWidth />}
          />
            </div> */}
        {!isWBSTaskList &&
          <div className="form-field project">
            <label>Project</label>
            <Controller
              name="projectSelect"
              control={control}
              render={({ field }) =>
                <Select
                  {...field}
                  onChange={handleChangeProjectSelect}
                  value={projectId ? projectId : 0}
                  inputProps={{ "data-testid": "project" }}
                >
                  {
                    projects?.map((project) => {
                      return <MenuItem
                        value={project.value}
                        key={project.value}>
                        {project.label}
                      </MenuItem>
                    })
                  }
                </Select>
              }
            />
          </div>}

        {wbs_id && !isWBSTaskList && <>
          <div className="form-field wbs">
            <label>WBS</label>
            <Controller
              name="wbsSelect"
              control={control}
              defaultValue={0}
              render={({ field }) =>
                <Select
                  {...field}
                  defaultValue={0}
                  onChange={handleChangeWBSSelect}
                  value={wbsId ? wbsId : 0}
                  inputProps={{ "data-testid": "wbs" }}
                >
                  {
                    wbs?.map((w) => {
                      return <MenuItem
                        value={w.value}
                        key={w.value}>
                        {w.label}
                      </MenuItem>
                    })
                  }
                </Select>
              }
            />
          </div></>}
        {!isWBSTaskList && <>
          <div className="form-field category">
            <label>Task Category</label>
            <Controller
              name="taskCategorySelect"
              control={control}
              defaultValue={0}
              render={({ field }) =>
                <Select
                  {...field}
                  defaultValue={0}
                  value={taskCategoryId ? taskCategoryId : 0}
                  onChange={handleChangeTaskCategorySelect}
                  inputProps={{ "data-testid": "category" }}
                >
                  {
                    taskCategory?.map((task) => {
                      return <MenuItem
                        value={task.value}
                        key={task.value}>
                        {task.label}
                      </MenuItem>
                    })
                  }
                </Select>
              }
            />
          </div>
          <div className="form-field status">
            <label>Task Status</label>
            <Controller
              name="taskStatusSelect"
              control={control}
              defaultValue={0}
              render={({ field }) =>
                <Select
                  {...field}
                  defaultValue={0}
                  value={taskStatusId ? taskStatusId : 0}
                  onChange={handleChangeTaskStatusSelect}
                  inputProps={{ "data-testid": "status" }}
                >
                  {
                    taskStatus?.map((task) => {
                      return <MenuItem
                        value={task.value}
                        key={task.value}>
                        {task.label}
                      </MenuItem>
                    })
                  }
                </Select>
              }
            />
          </div>
          <div className="form-field assigned">
            <label>Assigned to</label>
            <Controller
              name="userSelect"
              control={control}
              defaultValue={0}
              render={({ field }) =>
                <Select
                  {...field}
                  onChange={handleChangeUserSelect}
                  defaultValue={0}
                  value={assignedTo ? assignedTo : 0}
                  inputProps={{ "data-testid": "user" }}
                >
                  {
                    users?.map((user) => {
                      return <MenuItem
                        value={user.id}
                        key={user.id}>
                        {user.email}
                      </MenuItem>
                    })
                  }
                </Select>
              }
            />
          </div>
        </>}

        <div className="form-field priority">
          <label>Task Priority</label>
          <Controller
            name="taskPrioritySelect"
            control={control}
            defaultValue={0}
            render={({ field }) =>
              <Select
                {...field}
                value={taskPriorityId ? taskPriorityId : 0}
                defaultValue={0}
                onChange={handleChangeTaskPrioritySelect}
                inputProps={{ "data-testid": "priority" }}
              >
                {
                  taskPriority?.map((task) => {
                    return <MenuItem
                      value={task.value}
                      key={task.value}>
                      {task.label}
                    </MenuItem>
                  })
                }
              </Select>
            }
          />
        </div>
        {(projectId && !isWBSTaskList && <>
          <div className="form-field project control">
            <label>Project Control</label>
            <Controller
              name="projectControlSelect"
              control={control}
              defaultValue={0}
              render={({ field }) =>
                <Select
                  {...field}
                  value={projectControlId ? projectControlId : 0}
                  defaultValue={0}
                  onChange={handleChangeProjectControlSelect}
                  inputProps={{ "data-testid": "project-control" }}
                >
                  {
                    projectControls?.map((pc) => {
                      return <MenuItem
                        value={pc.value}
                        key={pc.value}>
                        {pc.label}
                      </MenuItem>
                    })
                  }
                </Select>
              }
            />
            <ul>
              {(projectControlSelections && (
                projectControlSelections.map((at) => {
                  return <li>
                    {at.label}
                    <IconButton aria-label="delete">
                      <DeleteIcon onClick={() => { removeProjectControl(at); }} />
                    </IconButton>
                  </li>
                })
              )
              )}
            </ul>
          </div>
          <div className="form-field audit test">
            <label>Audit Test</label>
            <Controller
              name="auditTestSelect"
              control={control}
              defaultValue={0}
              render={({ field }) =>
                <Select
                  {...field}
                  value={auditTestId ? auditTestId : 0}
                  defaultValue={0}
                  onChange={handleChangeAuditTestSelect}
                  inputProps={{ "data-testid": "audit" }}
                >
                  {
                    auditTests?.map((at) => {
                      return <MenuItem
                        value={at.value}
                        key={at.value}>
                        {at.label}
                      </MenuItem>
                    })
                  }
                </Select>
              }
            />
            <ul>
              {(auditTestSelections && (
                auditTestSelections.map((at) => {
                  return <li>
                    {at.label}
                    <IconButton aria-label="delete">
                      <DeleteIcon onClick={() => { removeAuditTest(at); }} />
                    </IconButton>
                  </li>
                })
              )
              )}
            </ul>
          </div>
          <div className="form-field risk">
            <label>Risk</label>
            <Controller
              name="riskSelect"
              control={control}
              defaultValue={0}
              render={({ field }) =>
                <Select
                  {...field}
                  value={riskId ? riskId : 0}
                  defaultValue={0}
                  onChange={handleChangeRiskSelect}
                  inputProps={{ "data-testid": "risk" }}
                >
                  {
                    risks?.map((risk) => {
                      return <MenuItem
                        value={risk.value}
                        key={risk.value}>
                        {risk.label}
                      </MenuItem>
                    })
                  }
                </Select>
              }
            />
            <ul>
              {(riskSelections && (
                riskSelections.map((at) => {
                  return <li>
                    {at.label}
                    <IconButton aria-label="delete">
                      <DeleteIcon onClick={() => { removeRisk(at); }} />
                    </IconButton>
                  </li>
                })
              )
              )}
            </ul>
          </div>
        </>
        )}
        {!isWBSTaskList &&
          <div className="form-field keywords">
            <div className="form-field control">
              <label>Existing Keywords</label>
              <Controller
                name="keywordSelect"
                control={control}
                render={({ field }) =>
                  <Select
                    {...field}
                    value={keywordId ? keywordId : 0}
                    onChange={handleChangeKeywordSelect}
                    inputProps={{ "data-testid": "keyword" }}
                  >
                    {
                      keywords?.map((word) => {
                        return <MenuItem
                          value={word.id}
                          key={word.id}>
                          {word.name}
                        </MenuItem>
                      })
                    }
                  </Select>
                }
              />
            </div>
            <ul>
              {(keywordSelections && (
                keywordSelections.map((word) => {
                  return <li>
                    {word.name}
                    <IconButton aria-label="delete">
                      <DeleteIcon onClick={() => { removeKeyword(word); }} />
                    </IconButton>
                  </li>
                })
              )
              )}
            </ul>
            <div className="form-field newkeywords">
              <TextField
                label="Add new keywords"
                onChange={handleChangeNewKeywords}
                value={newKeywords}
                //fullWidth
                helperText="Enter your keyword in all lowercase with a comma and no spaces between each one e.g. legal,technical"
              />
            </div>
          </div>
        }

        <div>
          {
            (editDataObj && <Button type="submit" variant="contained" >Submit</Button>) ||
            (!editDataObj && <Button type="submit" variant="contained" >Create</Button>)
          }
        </div>
      </form>
    </div>
  )
}