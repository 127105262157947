// in MessageParser.js
import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';

const MessageParser = ({ children, actions }) => {

  const [chatBotPrompts, setChatBotPrompts] = useState(null)

  const loadPrompts = useCallback(async () => {
    //call data endpoint for data type to set rows and columns
    var url = `${process.env.REACT_APP_API_URL}/chat_bot_prompts/`;
    const response = axios(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.access_token}`,
      },
    }).then(result => {
      return result;
    }).catch(error => { return error.message; })
    return response;
  }, [])

  const getPrompts = useCallback(async () => {
    const response = await loadPrompts();
    setChatBotPrompts(response?.data)
  }, [loadPrompts])

  useEffect(() => {
    if (chatBotPrompts === null) {
      getPrompts()
    }

  })

  var parse = (message) => {
    if (message.includes('hello') || message.includes('hi')) {
      actions.handleHello();
    }
    chatBotPrompts?.map(
      (chat, i) => {
        if (message.toLowerCase().includes(chat.prompt.toLowerCase()) || chat.prompt.toLowerCase().includes(message.toLowerCase())) {
          actions.handleChatPrompt(i);
        }
        return actions
      }

    )
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;