import React, { useState, useRef } from "react";
import Button from '@mui/material/Button';
import axios from 'axios';
import SpinnerComponent from './LoadingSpinner';
//import FileDownloadIcon from "@mui/icons-material/FileDownload";

export default function ImportAWSControls({projectId, isOpen}) {
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef();

  const uploadImportFile = async (e) => {
    var url = `${process.env.REACT_APP_API_URL}/aws_controls/import-aws-controls/${projectId}`
    setLoading(true);
    const formData = new FormData();
    formData.append("fileobject", e.target.files[0]);
    const options = {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${localStorage.access_token}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: formData,
      url
    }
    await axios(options).then(function (response) {
      //console.log(response.data);
      setLoading(false);
      window.location.reload(false);
    }).catch(function (error) {
      alert(error);
      setLoading(false);
    })
  };

//   async function downloadFileBlob() {
//     //call data endpoint for data type to set rows and columns
//     var url = `https://fedrisk.com/Framework_Import_Template.xlsx`;
//     const response = axios(url, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         "Authorization": `Bearer ${localStorage.access_token}`,
//       },
//       responseType: 'blob',
//     }).then(response => {
//       return response.data;
//     }).then(blob => {
//       var url = window.URL.createObjectURL(blob);
//       var a = document.createElement('a');
//       a.href = url;
//       a.download = response.data;
//       document.body.appendChild(a);
//       a.click();
//       a.remove();
//     }).catch(error => { return error.message; })
//     return response;
//   }

  return (
    <form className="import-aws-controls">

      {loading ? <div style={{ height: "250px" }}><SpinnerComponent /></div> : <div className="form-box">
        {/*<Button
          variant="outlined"
          startIcon={<FileDownloadIcon />}
          onClick={() => downloadFileBlob()} 
        >Download template
  </Button>*/}
        <div className="ins">
          <div className="form-group">
            <label className="label-control">File:</label>
            <div className="form-controls">
              <div className="file-uploader">
                <Button
                  variant="contained"
                  className="primary btn"
                  style={{ marginBottom: "14px" }}
                  onClick={() => fileInputRef.current.click()}
                >
                  Upload Import File
                </Button>
                <input
                  data-testid="upInput"
                  onChange={(e) => uploadImportFile(e)}
                  multiple={false}
                  ref={fileInputRef}
                  type="file"
                  hidden
                />

              </div>
            </div>
          </div>
        </div>
      </div>}
    </form>
  )
}