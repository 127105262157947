import React, { useEffect, useState, useCallback } from "react";
import { DataGrid } from '@mui/x-data-grid';
import SpinnerComponent from '../LoadingSpinner';
import axios from 'axios';
import '../../css/AWSReportCardList.css'

export async function loadData (projectId) {
    //call data endpoint for data type to set rows and columns
    var url = `${process.env.REACT_APP_API_URL}/aws_controls/project_id/${projectId}`;
    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

//This class displays lists of aws controls

const columns = [
    { field: 'id', headerName: 'id', width: 120 },
];

const rows = [
    { id: "n/a" },
];

export default function AWSControlListProject({
    projectId,
    //awsControlData,
}) {
    //axios error message

    const [rowsData, setRowsData] = useState(null);
    const [colsData, setColsData] = useState(null);
    const [securityScore, setSecurityScore] = useState("0%");
    const [secScoreClass, setSecScoreClass] = useState("");
    const [passedTotalChecks, setPassedTotalChecks] = useState("");
    const [reportDate, setReportDate] = useState("");

    const getData = useCallback(async () => {
        var response = await loadData(projectId);
        let failed_checks = 0
        let passed_checks = 0
        let unknown_checks = 0
        //let not_available_checks = 0
        let total_checks = 0
        if (response.data) {
            var filteredRowDataAwsControls = [];
            response?.data?.forEach((key) => {
                failed_checks += parseInt(key.aws_failed_checks)
                passed_checks += parseInt(key.aws_passed_checks)
                unknown_checks += parseInt(key.aws_unknown_checks)
                setReportDate(key.date);
                //not_available_checks += parseInt(key.AWSControl.aws_not_available_checks)
                filteredRowDataAwsControls.push({
                    id: key.id,
                    aws_id: key.aws_id,
                    aws_title: key.aws_title,
                    aws_control_status: key.aws_control_status,
                    aws_severity: key.aws_severity,
                    aws_failed_checks: key.aws_failed_checks,
                    aws_unknown_checks: key.aws_unknown_checks,
                    aws_not_available_checks: key.aws_not_available_checks,
                    aws_passed_checks: key.aws_passed_checks,
                    aws_related_requirements: key.aws_related_requirements,
                    aws_custom_parameters: key.aws_custom_parameters,
                    created: key.created,
                    project_control_id: key.project_control_id,
                    control_name: key.control_name,
                });
            })
            setRowsData([...filteredRowDataAwsControls]);
            var colsVals = [
                {
                    field: 'project_control_id',
                    headerName: 'Project Control',
                    width: 100,
                    renderCell: (params) => (
                        <a href={`/projects/${projectId}/controls/${params.value}`}>{params.row.control_name}</a>
                    )
                },
                {
                    field: 'aws_id',
                    headerName: 'ID',
                    width: 100,
                    cellClass: "normalLineHeight"
                },
                {
                    field: 'aws_title',
                    headerName: 'Title',
                    width: 200,
                    cellClass: "normalLineHeight"
                },
                {
                    field: 'aws_control_status',
                    headerName: 'Status',
                    width: 100,
                    renderCell: (params) => (
                        <div className={params.value}>{params.value}</div>
                    )
                },
                {
                    field: 'aws_severity',
                    headerName: 'Severity',
                    width: 100,
                    renderCell: (params) => (
                        <><div className={params.value}>&#9632;</div><div className={params.value}>{params.value}</div></>
                    )
                },
                {
                    field: 'aws_failed_checks',
                    headerName: 'Failed Checks',
                    width: 100,
                    renderCell: (params) => (
                        <div className="failed">{params.value}</div>
                    )
                },
                {
                    field: 'aws_unknown_checks',
                    headerName: 'Unknown Checks',
                    width: 100,
                    renderCell: (params) => (
                        <div className="unknown">{params.value}</div>
                    )
                },
                {
                    field: 'aws_not_available_checks',
                    headerName: 'N/A Checks',
                    width: 100,
                    renderCell: (params) => (
                        <div className="notavailable">{params.value}</div>
                    )
                },
                {
                    field: 'aws_passed_checks',
                    headerName: 'Passed Checks',
                    width: 100,
                    renderCell: (params) => (
                        <div className="passed">{params.value}</div>
                    )
                },
                {
                    field: 'aws_related_requirements',
                    headerName: 'Requirements',
                    width: 200,
                    cellClass: "normalLineHeight"
                },
                {
                    field: 'aws_custom_parameters',
                    headerName: 'Custom Params',
                    width: 200,
                    cellClass: "normalLineHeight"
                },
            ];
            setColsData([...colsVals]);
        }
        total_checks += failed_checks + passed_checks + unknown_checks /*+ not_available_checks*/
        let score = Math.round(passed_checks / total_checks * 100)
        setSecurityScore(`${score} %`)
        setPassedTotalChecks(`${passed_checks} of ${total_checks} controls passed`)
        if (score < 50) {
            setSecScoreClass("fail")
        }
        if (score >= 50 && score < 80) {
            setSecScoreClass("average")
        }
        if (score >= 80) {
            setSecScoreClass("pass")
        }
    }, [projectId])

    useEffect(() => {
        if (!rowsData) {
            getData();
        }
    }, [getData, rowsData]);

    useEffect(() => {

    }, [securityScore])

    return (
        <div style={{ marginTop: '0px' }} className="awsControlListProject">
            <>
                <h2>AWS Report Card</h2>
                <p>Report Date: {reportDate}</p>
                <p>Security score</p>
                <div className={`security-score ${secScoreClass}`}>{securityScore}</div>
                <div className="security-controls"><p>{passedTotalChecks}</p></div>
                <div className="aws_control list project" style={{}}>
                    <DataGrid
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        rows={rowsData ? rowsData : rows}
                        columns={colsData ? colsData : columns}
                        rowsPerPageOptions={[10]}
                        pageSize={10}
                        //checkboxSelection
                        //loading={loading}
                        components={{
                            LoadingOverlay: SpinnerComponent,
                        }}
                        //onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                        getRowHeight={() => 'auto'}
                    />
                </div>
            </>
        </div>
    )

}