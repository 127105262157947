import React, { useState, useEffect } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
//import Button from '@mui/material/Button';
import SpinnerComponent from '../components/LoadingSpinner';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

/* This is a generic view for returning details on a data object */

export async function loadData() {
    //call data endpoint for data type to set rows and columns
    var url = `${process.env.REACT_APP_API_URL}/help_sections/`;

    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export default function HelpSupport() {
    const [data, setData] = useState([]);

    const [isSystemAdmin, setIsSystemAdmin] = useState(false);
    useEffect(() => {
        if (localStorage.getItem("system_role") === "4" || localStorage.getItem("system_role") === "1" || localStorage.getItem("system_role") === "2") {
            setIsSystemAdmin(true)
        }
    }, [])

    useEffect(() => {
    }, [isSystemAdmin])

    //axios error message
    const [errMsg, setErrMsg] = useState("");
    useEffect(() => {
    }, [errMsg])

    async function getData() {
        const jsonData = await loadData();
        if (jsonData !== "Request failed with status code 404" && jsonData !== "Network Error") {
            setData(jsonData.data);

        } else {
            setErrMsg(`${jsonData}`)
        }
    }

    useEffect(() => {
        if (data.length === 0) {
            getData();
        }

    });

    const setMarkup = (htmlstr) => {
        return { __html: htmlstr }
    }

    return (
        <>
            {errMsg ? (
                <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
                    <Alert severity="error">{errMsg}</Alert>
                </Stack>
            ) : (
                <></>
            )}
            <div className="details-view help-support">
                {(!data && <SpinnerComponent />)}
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Home
                    </Link>
                    <Typography color="text.primary">Help and Support</Typography>
                </Breadcrumbs>
                <>
                    <h1>Help and Support</h1>
                    <ul>
                        {(data && (
                            data.map((li) => {
                                return <li><a href={`#${li.divId}`}>{li.title}</a></li>
                            })
                        )
                        )}
                    </ul>
                    
                    {(data && (
                        data.map((section) => {
                            return <div id={section.divId}>
                                <h2>{section.title}</h2>
                                <span dangerouslySetInnerHTML={setMarkup(section.body)} />
                            </div>
                        })
                    )
                    )}
                   
                </>
            </div>
        </>
    )
}