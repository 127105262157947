import React, { useEffect, useState } from "react";
import '../css/Register.css';
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField"
import Box from '@mui/material/Box';
import Loader from './Loader';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Logo from '../assets/fedrisk-full-logo.png';
import RegisterOTP from "./RegisterOTP";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'



export default function RegisterUserTenant() {

    const [errMsg, setErrMsg] = useState("");

    //const [organization, setOrganization] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [phoneNo, setPhoneNo] = useState();
    const [loading, setLoading] = useState(false);
    const [displayOtp, setDisplayOtp] = useState(false);
    const [regData, setRegData] = useState({});
    const [organization, setOrganization] = useState("");
    const [tenantId, setTenantId] = useState();

    useEffect(() => {
        let emailstr = "testemail@test.com"
        let emailval = emailstr
        if (window.location.pathname) {
            emailstr = window.location.pathname.split("=")[1]
            emailval = emailstr.split("&")[0]
        }
        setEmail(emailval);
        let orgstr = "Test Organization"
        let orgval = orgstr
        if (window.location.pathname) {
            orgstr = window.location.pathname.split("=")[2]
            orgval = orgstr.split("&")[0]
        }
        setOrganization(orgval);
        let tenidstr = "1"
        let tenidval = tenidstr
        if (window.location.pathname) {
            tenidstr = window.location.pathname.split("=")[3]
            tenidval = tenidstr.split("&")[0]
        }
        setTenantId(tenidval);
    }, [email, organization, tenantId]);


    useEffect(() => {
        //setValidPwd(PWD_REGEX.test(password));
        //setValidMatch(password === matchPwd);
    }, [password]);

    //useEffect(() => {
    //setErrMsg("");
    //}, [email, password, matchPwd]);

    useEffect(() => {
    }, [errMsg])

    const isOpen = () => {
        handleClose();
    }

    const handleClose = () => {
        setDisplayOtp(false);
    }

    const handleSubmit = async e => {
        setLoading(true);
        e.preventDefault();
        if (password !== confirmPassword) {
            alert(`Your passwords do not match`);
            setPassword("");
            setConfirmPassword("");
            setLoading(false);
            return
        }
        else {
            setUpAccount();
        }

    }
    const registerUser = async () => {
        //var body = registration;
        //body["otp"] = otp;
        var method = "POST";
        var url = `${process.env.REACT_APP_API_URL}/tenants/user/register`
        const response = axios(url, {
            method: method,
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                //"otp": otp,
                //"organization": organization,
                "first_name": firstName,
                "last_name": lastName,
                "email": email,
                "password": password,
                "confirm_password": confirmPassword,
                "phone_no": phoneNo,
            },
        }).then(result => {
            return result;
        }).catch(error => { return error.message; })
        return response;
    }

    const setUpAccount = async () => {
        //const checkEmail = await checkUniqueEmail();
        //console.log(`check email response = ${JSON.stringify(checkEmail)}`)
        //if (checkEmail !== `Request failed with status code 409`) {
        const resp = await registerUser();
        if (!resp.data) {
            alert(`There was an error registering your account.`)
            setLoading(false);
            setErrMsg('There was an error registering your account.')
            return
        }
        setRegData(resp.data);
        // const resp = await sendOtpCode();
        // if (!resp.data) {
        //   alert(`There was an error getting the OTP code.`)
        //   setLoading(false);
        //   return
        // }
        setDisplayOtp(true);
        setLoading(false);
        //}
        //if (checkEmail === `Request failed with status code 409`) {
        //setErrMsg(`The email ${email} is already in use. Please use a unique email address.`)
        //setLoading(false);
        //}
    }

    // async function sendOtpCode() {
    //   var emailparse = email.replace('@', '%40')
    //   var method = "GET";
    //   var url = `${process.env.REACT_APP_API_URL}/tenants/get-tenant-registration-otp?email=${emailparse}`
    //   const response = axios(url, {
    //     method: method,
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   }).then(result => {
    //     return result;
    //   }).catch(error => { return error.message; })
    //   return response;
    // }

    if (loading) {
        return (
            <Loader />
        )
    }
    if (displayOtp) {
        return (
            <RegisterOTP isOpen={isOpen} registration={regData} is_user={true}/>
        )
    }

    return (
        <div className="register-wrapper">
            <img src={Logo} alt="Logo" width="481px" />
            <h2>Organization: {organization}</h2>
            <h1>Please Enter Your Details</h1>
            <div className="login-here">Already have an account? <a href="/">Log In</a></div>
            {errMsg ? (
                <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
                    <Alert severity="error">{errMsg}</Alert>
                </Stack>
            ) : (
                <></>
            )}
            <Box
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '100%' },
                }}
            >
                <form onSubmit={handleSubmit}>

                    <h2>Your Details</h2>
                    <div><TextField required id="firstName" value={firstName} label="First name" onChange={e => setFirstName(e.target.value)}
                        style={{
                            width: 400
                        }} />
                        <TextField required id="lastName" value={lastName} label="Last name" onChange={e => setLastName(e.target.value)}
                            style={{
                                width: 400
                            }} /></div>

                    <div><TextField type="email" required id="email" value={email} label="Email" disabled
                        style={{
                            width: 500
                        }} /></div>
                    <div>
                        <label>Cell phone for sms notifications</label>
                        <PhoneInput
                            country={'us'}
                            regions={'north-america'}
                            value={phoneNo}
                            onChange={phone => setPhoneNo(phone)}
                        />
                    </div>
                    <h2>Create a Password</h2> 
                    <div><TextField type="password" required label="Password" id="password" value={password} onChange={e => setPassword(e.target.value)}
                        style={{
                            width: 500
                        }}
                    /></div>
                    <div><TextField type="password" required label="Confirm Password" id="confirmPassword" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)}
                        style={{
                            width: 500
                        }}
                    /></div>
                    <div>
                        <Button className="sign-in" type="submit" variant="contained">Continue</Button>
                    </div>
                </form>
            </Box>
        </div>
    )
}