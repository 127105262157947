// in ActionProvider.jsx
import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const [chatBotPrompts, setChatBotPrompts] = useState(null)

  const loadPrompts = useCallback(async () => {
    //call data endpoint for data type to set rows and columns
    var url = `${process.env.REACT_APP_API_URL}/chat_bot_prompts/`;
    const response = axios(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.access_token}`,
      },
    }).then(result => {
      return result;
    }).catch(error => { return error.message; })
    return response;
  }, [])

  const getPrompts = useCallback(async () => {
    const response = await loadPrompts();
    setChatBotPrompts(response?.data)
  }, [loadPrompts])

  useEffect(() => {
    if (chatBotPrompts === null) {
      getPrompts()
    }

  })
  const handleHello = () => {
    const botMessage = createChatBotMessage('Hello. Nice to meet you.');

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };
  const handleChatPrompt = (i) => {
    const botMessage = createChatBotMessage(chatBotPrompts[i].message);

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  // Put the handleHello function in the actions object to pass to the MessageParser
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleHello,
            handleChatPrompt,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;