import React, { useState, useEffect, useCallback, lazy, Suspense } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from "@mui/material";
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { useForm, Controller } from "react-hook-form";
import { MenuItem, Select } from '@mui/material';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import SpinnerComponent from '../components/LoadingSpinner';
const NotificationsList = lazy(() => import('../components/lists/NotificationsList'));

export async function getUserSettings() {
    var url = `${process.env.REACT_APP_API_URL}/user_notifications/settings/${localStorage.getItem("userid")}`;
    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export async function createUserSettings(body) {
    var url = `${process.env.REACT_APP_API_URL}/user_notifications/settings/`;
    const response = axios(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
        data: body,
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

// Update existing user notification settings
export async function updateUserSettings (body) {
    var url = `${process.env.REACT_APP_API_URL}/user_notifications/settings/update/${localStorage.getItem("userid")}`;
    const response = axios(url, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
        data: body,
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export default function Notifications() {
    const location = useLocation();
    //console.log(location)
    const [userSettings, setUserSettings] = useState([])
    const [timingVal, setTimingVal] = useState();
    const [watchEmailVal, setWatchEmailVal] = useState();
    const [watchSMSVal, setWatchSMSVal] = useState();
    const [assignedEmailVal, setAssignedEmailVal] = useState();
    const [assignedSMSVal, setAssignedSMSVal] = useState();
    const [scheduledEmailVal, setScheduledEmailVal] = useState();
    const [scheduledSMSVal, setScheduledSMSVal] = useState();

    const [value, setValue] = useState(0);

    const onChange = (e, value) => {
        setValue(value);
    };

    const timingVals = [
        { value: "one_day_prior", label: "1 day prior to due date" },
        { value: "three_days_prior", label: "3 days prior to due date" },
        { value: "five_days_prior", label: "5 days prior to due date" },
        { value: "seven_days_prior", label: "7 days prior to due date" },
        { value: "fifteen_days_prior", label: "15 days prior to due date" },
        { value: "thirty_days_prior", label: "30 days prior to due date" },
        { value: "sixty_days_prior", label: "60 days prior to due date" },
        { value: "ninety_days_prior", label: "90 days prior to due date" },
    ]

    const { control, handleSubmit } = useForm({
        defaultValues: {
            timing: {},
        }
    });

    const handleChangeTimingSelect = async (event) => {
        if (userSettings?.length != null && userSettings?.length === 0) {
            await createUserSettings(
                {
                    "user_id": localStorage.getItem("userid"),
                    "watch_email": false,
                    "watch_sms": false,
                    "assigned_email": false,
                    "assigned_sms": false,
                    "scheduled_email": false,
                    "scheduled_sms": false,
                    "upcoming_event_deadline": event.target.value
                }
            )
            await loadSettings();
            setWatchEmailVal(event.target.value)
        } else {
            if (timingVal) {
                await updateUserSettings({ "upcoming_event_deadline": event.target.value })
                setTimingVal(event.target.value)
            }
        }

    };

    const setWatchEmail = async () => {
        if (userSettings?.length != null && userSettings?.length === 0) {
            await createUserSettings(
                {
                    "user_id": localStorage.getItem("userid"),
                    "watch_email": true,
                    "watch_sms": false,
                    "assigned_email": false,
                    "assigned_sms": false,
                    "scheduled_email": false,
                    "scheduled_sms": false,
                    "upcoming_event_deadline": "three_days_prior"
                }
            )
            setWatchEmailVal(true)
            await loadSettings();
        } else {
            if (watchEmailVal === true) {
                await updateUserSettings({ "watch_email": false })
                setWatchEmailVal(false)
            }
            if (watchEmailVal === false) {
                await updateUserSettings({ "watch_email": true })
                setWatchEmailVal(true)
            }
        }
    }


    const setWatchSMS = async () => {
        if (userSettings?.length != null && userSettings?.length === 0) {
            await createUserSettings(
                {
                    "user_id": localStorage.getItem("userid"),
                    "watch_email": false,
                    "watch_sms": true,
                    "assigned_email": false,
                    "assigned_sms": false,
                    "scheduled_email": false,
                    "scheduled_sms": false,
                    "upcoming_event_deadline": "three_days_prior"
                }
            )
            setWatchSMSVal(true)
            await loadSettings();
        } else {
            if (watchSMSVal === true) {
                await updateUserSettings({ "watch_sms": false })
                setWatchSMSVal(false)
            }
            if (watchSMSVal === false) {
                await updateUserSettings({ "watch_sms": true })
                setWatchSMSVal(true)
            }
        }

    }

    const setAssignEmail = async () => {
        if (userSettings?.length != null && userSettings?.length === 0) {
            await createUserSettings(
                {
                    "user_id": localStorage.getItem("userid"),
                    "watch_email": false,
                    "watch_sms": false,
                    "assigned_email": true,
                    "assigned_sms": false,
                    "scheduled_email": false,
                    "scheduled_sms": false,
                    "upcoming_event_deadline": "three_days_prior"
                }
            )
            setAssignedEmailVal(true)
            await loadSettings();
        }
        else {
            if (assignedEmailVal === true) {
                await updateUserSettings({ "assigned_email": false })
                setAssignedEmailVal(false)
            }
            if (assignedEmailVal === false) {
                await updateUserSettings({ "assigned_email": true })
                setAssignedEmailVal(true)
            }
        }
    }

    const setAssignSMS = async () => {
        if (userSettings?.length != null && userSettings?.length === 0) {
            await createUserSettings(
                {
                    "user_id": localStorage.getItem("userid"),
                    "watch_email": false,
                    "watch_sms": false,
                    "assigned_email": false,
                    "assigned_sms": true,
                    "scheduled_email": false,
                    "scheduled_sms": false,
                    "upcoming_event_deadline": "three_days_prior"
                }
            )
            setAssignedSMSVal(true)
            await loadSettings();
        } else {
            if (assignedSMSVal === true) {
                await updateUserSettings({ "assigned_sms": false })
                setAssignedSMSVal(false)
            }
            if (assignedSMSVal === false) {
                await updateUserSettings({ "assigned_sms": true })
                setAssignedSMSVal(true)
            }
        }
    }

    const setScheduleEmail = async () => {
        if (userSettings?.length != null && userSettings?.length === 0) {
            await createUserSettings(
                {
                    "user_id": localStorage.getItem("userid"),
                    "watch_email": false,
                    "watch_sms": false,
                    "assigned_email": false,
                    "assigned_sms": false,
                    "scheduled_email": true,
                    "scheduled_sms": false,
                    "upcoming_event_deadline": "three_days_prior"
                }
            )
            setScheduledEmailVal(true)
            await loadSettings();
        } else {
            if (scheduledEmailVal === true) {
                await updateUserSettings({ "scheduled_email": false })
                setScheduledEmailVal(false)
            }
            if (scheduledEmailVal === false) {
                await updateUserSettings({ "scheduled_email": true })
                setScheduledEmailVal(true)
            }
        }

    }

    const setScheduleSMS = async () => {
        if (userSettings?.length != null && userSettings?.length === 0) {
            await createUserSettings(
                {
                    "user_id": localStorage.getItem("userid"),
                    "watch_email": false,
                    "watch_sms": false,
                    "assigned_email": false,
                    "assigned_sms": false,
                    "scheduled_email": false,
                    "scheduled_sms": true,
                    "upcoming_event_deadline": "three_days_prior"
                }
            )
            setScheduledSMSVal(true)
            await loadSettings();
        } else {
            if (scheduledSMSVal === true) {
                await updateUserSettings({ "scheduled_sms": false })
                setScheduledSMSVal(false)
            }
            if (scheduledSMSVal === false) {
                await updateUserSettings({ "scheduled_sms": true })
                setScheduledSMSVal(true)
            }
        }
    }

    const loadSettings = useCallback(async () => {
        const response = await getUserSettings()
        if (response?.data) {
            setUserSettings(response?.data)
            setTimingVal(response?.data?.upcoming_event_deadline)
            setWatchEmailVal(response?.data?.watch_email)
            setWatchSMSVal(response?.data?.watch_sms)
            setAssignedEmailVal(response?.data?.assigned_email)
            setAssignedSMSVal(response?.data?.assigned_sms)
            setScheduledEmailVal(response?.data?.scheduled_email)
            setScheduledSMSVal(response?.data?.scheduled_sms)
        } else {
            setUserSettings([])
        }
    }, [])

    useEffect(() => {
        if (!userSettings) {
            loadSettings()
        }
    }, [loadSettings, userSettings])

    useEffect(() => {
        if (location?.pathname === "/notifications/settings") {
            setValue(0)
        }
        if (location?.pathname === "/notifications/all") {
            setValue(1)
        }

    }, [setValue, location]);


    return (
        <>
            <h1>Notifications</h1>
            <p>View and manage your notifications</p>

            <Tabs value={value} onChange={onChange}>
                <Tab label="Notification Settings" component={Link} to={`/notifications/settings`} />
                <Tab label="All Notifications" component={Link} to={`/notifications/all`} />
            </Tabs>

            {/* TAB 1 Contents */}
            {value === 0 && (
                <Box sx={{ p: 3 }}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableCell>Notification Type</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>SMS</TableCell>
                            <TableCell>Timing</TableCell>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>You're watching and item or issue</TableCell>
                                <TableCell><Switch inputProps={{ "data-testid": "watch-email" }} onChange={setWatchEmail} checked={watchEmailVal} /></TableCell>
                                <TableCell><Switch inputProps={{ "data-testid": "watch-sms" }} onChange={setWatchSMS} checked={watchSMSVal} /></TableCell>
                                <TableCell>In real time</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>You're assigned to an item or issue</TableCell>
                                <TableCell><Switch inputProps={{ "data-testid": "assign-email" }} onChange={setAssignEmail} checked={assignedEmailVal} /></TableCell>
                                <TableCell><Switch inputProps={{ "data-testid": "assign-sms" }} onChange={setAssignSMS} checked={assignedSMSVal} /></TableCell>
                                <TableCell>In real time</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Upcoming event or deadline</TableCell>
                                <TableCell><Switch inputProps={{ "data-testid": "upcoming-email" }} onChange={setScheduleEmail} checked={scheduledEmailVal} /></TableCell>
                                <TableCell><Switch inputProps={{ "data-testid": "upcoming-sms" }} onChange={setScheduleSMS} checked={scheduledSMSVal} /></TableCell>
                                <TableCell>
                                    <form onSubmit={handleSubmit()}>
                                        <div className="form-field control scheduling dropdown">
                                            <Controller
                                                name="timing"
                                                control={control}
                                                render={({ field }) =>
                                                    <Select
                                                        {...field}
                                                        onChange={handleChangeTimingSelect}
                                                        inputProps={{ "data-testid": "timing-select" }}
                                                        value={timingVal ? timingVal : "three_days_prior"}
                                                        className="select-schedule"
                                                    >
                                                        {
                                                            timingVals?.map((time) => {
                                                                return <MenuItem
                                                                    value={time.value}
                                                                    key={time.value}>
                                                                    {time.label}
                                                                </MenuItem>
                                                            })
                                                        }
                                                    </Select>
                                                }
                                            />
                                        </div>
                                    </form>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
            )}
            {/* TAB 2 Contents */}
            {value === 1 && (
                <Box sx={{ p: 3 }}>
                    <Suspense fallback={<SpinnerComponent />}><NotificationsList /></Suspense>
                </Box>
            )}
        </>
    );
}